import React, { useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import RadioButton from "@Atom/RadioButton";
import Button from "@Atom/Button";

export default function SelectNotifTemplateModal({
  list,
  handleSubmit,
  onClose,
}) {
  const [selected, setSelected] = useState(-1);
  const [show, setShow] = useState(-1);
  return (
    <div className={Styles.container}>
      <div className={Styles.modalHeader}>
        <div>
          <p>Template Pesan</p>
          <p>Pilih template pesan yang ingin Anda kirim</p>
        </div>
        <Icon icon={"cross"} size={24} color="#B22A12" onClick={onClose} />
      </div>
      <div className={Styles.modalBody}>
        <div className={Styles.contentWrapper}>
          {list.map((el, idx) => (
            <div className={Styles.content} key={idx}>
              <div className={Styles.leftSection}>
                <p>{el.name}</p>
                <p
                  className={Styles.contentBody}
                  is-show={show !== idx ? "true" : "false"}
                >
                  {el.body}
                </p>
                <Button
                  variant="text"
                  text={show !== idx ? "Selengkapnya" : "Kecilkan"}
                  onClick={() => (show === idx ? setShow(-1) : setShow(idx))}
                />
              </div>
              <RadioButton
                isSelected={el.name === selected}
                onClick={() => setSelected(el.name)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={Styles.modalAction}>
        <Button text={"Kirim Pesan"} onClick={() => handleSubmit(selected)} />
      </div>
    </div>
  );
}
