import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import Avatar from "@Atom/Avatar";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import ChevronGradient from "@Assets/Icon/gradientArrowRight.svg";
import useWindowSize from "@Hooks/useWindowSize";
import Icon from "@Atom/Icon";

export default function StudentClassDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  let data = location.state;

  console.log(data);

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <div className={Styles.backButton} onClick={() => navigate(-1)}>
          <img src={ChevronGradient} alt="" className={Styles.icon} />
          <span>Kembali</span>
        </div>
        <div className={Styles.wrapHeader}>
          <DoodleGradientCard className={Styles.header} />
          <div className={Styles.wrapperProfile}>
            {/* <img
              src="https://placehold.co/160x160"
              alt=""
              className={Styles.images}
            /> */}
            <Avatar
              iconName="user"
              iconSize={width > 768 ? "100px" : "50px"}
              size={width > 768 ? "160px" : "80px"}
              className={Styles.images}
            />
            <div className={Styles.containProfile}>
              <h1>Detail Peserta</h1>
              <div className={Styles.descProfile}>
                <div className={Styles.wrap}>
                  <span>Nama Peserta</span>
                  <h2>{data?.name}</h2>
                </div>
                <div className={Styles.divider}></div>
                <div className={Styles.wrap}>
                  <span>Email</span>
                  <h2>{data?.email}</h2>
                </div>
                <div className={Styles.divider}></div>
                {/* <div className={Styles.wrap}>
                <span>Nomor Handphone</span>
                <h2>{data?.phoneNumber}</h2>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.assignmentWrapper}>
          <div className={Styles.section}>
            <div className={Styles.sectionHeader}>
              <div className={Styles.iconWrapper} type="tpm">
                <Icon icon={"assignment"} size={20} />
              </div>
              <p>TPM (Tugas Praktek Mandiri)</p>
            </div>
            <div className={Styles.sectionBody}>
              {data?.tpm.map((el, idx) => (
                <React.Fragment key={idx}>
                  <div className={Styles.assignment}>
                    <p>TPM {idx + 1}</p>
                    <p>
                      {el?.score ? (el.score === -1 ? "-" : el?.score) : "-"}
                    </p>
                  </div>
                  {idx + 1 !== data.tpm.length && (
                    <div className={Styles.divider} />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className={Styles.section}>
            <div className={Styles.sectionHeader}>
              <div className={Styles.iconWrapper} type="test">
                <Icon icon={"assignment"} size={20} />
              </div>
              <p>Nilai Tes</p>
            </div>
            <div className={Styles.sectionBody}>
              <div className={Styles.assignment}>
                <p>Pre Test</p>
                <p>{data.preTest === -1 ? "-" : data.preTest}</p>
              </div>
              <div className={Styles.divider} />
              <div className={Styles.assignment}>
                <p>Post Test</p>
                <p>{data.postTest === -1 ? "-" : data.postTest}</p>
              </div>
              <div className={Styles.divider} />
              <div className={Styles.assignment}>
                <p>Uji Keterampilan</p>
                <p>{data.uk === -1 ? "-" : data.uk}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.assignmentWrapper}>
          <div className={Styles.section}>
            <div className={Styles.sectionHeader}>
              <div className={Styles.iconWrapper} type="quiz">
                <Icon icon={"assignment"} size={20} />
              </div>
              <p>Kuis</p>
            </div>
            <div className={Styles.sectionBody}>
              {data?.quiz?.map((el, idx) => (
                <React.Fragment key={idx}>
                  <div className={Styles.assignment}>
                    <p>{el?.title}</p>
                    <p>{el?.score === -1 ? "-" : el.score}</p>
                  </div>
                  {idx + 1 !== data.tpm.length && (
                    <div className={Styles.divider} />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
