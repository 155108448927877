import axios from "axios";

export const API = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL + "/v2",
  baseURL: "https://api-live.skills.id/v2",
  // baseURL : "https://qa-api.skills.id/v2"
  // baseURL : "https://nmmrksgt-3000.asse.devtunnels.ms/v2"
  // baseURL : "https://4dz0v26z-3000.asse.devtunnels.ms/v2"
});

export const fileBaseUrl = process.env.REACT_APP_BASE_URL + "/file/";
