import { API } from "@Config/index";

export function getCourseForLearn(code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/course/${code}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getClassDashboardStudent() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/user-course`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClassDashboardInstructor() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/instructor/dashboard`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "ngrok-skip-browser-warning": "true"

        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}


