import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import irsyad from "@Assets/Images/irsyad.png";
import restinan from "@Assets/Images/restinan.png";
import rizki from "@Assets/Images/rizki.png";
import nuridho from "@Assets/Images/nuridho.png";
import putri from "@Assets/Images/putri.png";

export default function Testimonials() {
  const dataTestimonials = useMemo(() => {
    return [
      {
        name: "Irsyad Fajri",
        image: irsyad,
        review:
          "Saya sangat puas dengan pelatihan membuat desain infografik. Karena penjelasannya informatif dan mudah dipahami. Dari pelatihan ini, saya banyak mendapatkan ilmu baru yang berkaitan dengan pekerjaan saya. Sekarang saya lebih percaya diri dalam membuat infografik yang menarik dan efektif untuk project2 saya. Terima kasih kepada instruktur yang berpengalaman & tentunya kepada Skills.id",
        job: "Video Editor",
      },
      {
        name: "Rizki Karimulloh",
        image: rizki,
        review:
          "Materi yang disampaikan saat bermafaat. Tutor sangat inspiratif menyampaikan materi yang ada seacra fun dan tidak membuat tegang. Mantap untuk skills.id dalam memberikan pelatihan manajemen  SDM. Saya berharap ada pelatihan lanjutan dari yang saya ikuti ini.",
        job: "Recruitment Officer",
      },
      {
        name: "Restinan Nur Anissa ",
        image: restinan,
        review:
          "Pelatihannya seru banget. Menambah ilmu baru, jadi saat kita nanti terjun ke dunia kerja sudah siap dengan ilmu-ilmu yang kita miliki. Kakak-kakaknya ramah banget dan pelatihannya menyenangkan. The best bintang 5!!",
        job: "-",
      },
      {
        name: "Putri Amrita Sari ",
        image: putri,
        review:
          "Saya sudah mengikuti 2 pelatihan di skills.id yang dilaksanakaan secara online dan offline. Walaupun berbeda keduanya sama-sama menarik, trainernya sangat memahami materi sehingga sangat membantu orang-orang yang baru pertama kali belajar di bidang tersebut.",
        job: "-",
      },
      {
        name: "Nuridho Galih Wardani ",
        image: nuridho,
        review:
          "Saya sangat senang dengan trainernya karena berkompeten dibidang ini, sehingga ketika saya mengikuti kelas saya tidak kebingungan dan langsung paham dengan apa yang beliau sampaikan. Disini saya juga merasa seperti keluarga. Terimakasih skills.id",
        job: "-",
      },
    ];
  }, []);

  const [isActive, setIsActive] = useState(2);
  const [isTextVisible, setIsTextVisible] = useState(true);

  useEffect(() => {
    setIsTextVisible(false);
    setTimeout(() => {
      setIsTextVisible(true);
    }, 200);
  }, [isActive]);

  return (
    <div className={Styles.background} id="testimonials">
      <section className={Styles.wrapperTestimonials}>
        <h1>
          Kata Mereka Tentang <span>{" Skills.id "}</span>
        </h1>
        <div className={Styles.wrapperProfile}>
          <div className={Styles.profile}>
            <div isctive={isActive ? "true" : "false"} className={Styles.left}>
              <div style={{ display: "flex", gap: "20px" }}>
                {dataTestimonials.map((item, idx) => (
                  <img
                    key={idx}
                    src={item.image}
                    alt=""
                    onClick={() => setIsActive(idx)}
                    isactive={isActive === idx ? "true" : "false"}
                  />
                ))}
              </div>

              <div className={Styles.profileReview}>
                {dataTestimonials.map((item, idx) => (
                  <div
                    key={idx}
                    className={Styles.nameAndJobs}
                    style={
                      isTextVisible
                        ? { opacity: "1", transition: "1s" }
                        : { opacity: "0" }
                    }
                  >
                    <p>{isActive === idx && item.name}</p>
                    <p>{isActive === idx && item.job}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={Styles.indicatorActive}>
            {dataTestimonials.map((_, idx) => (
              <div key={idx} active={isActive === idx ? "true" : "false"}></div>
            ))}
          </div>
        </div>
        <div className={Styles.lineSeperator}></div>
        <div className={Styles.description}>
          {dataTestimonials.map((item, idx) => (
            <p
              className={Styles.review}
              style={
                isTextVisible
                  ? { opacity: "1", transition: "1s" }
                  : { opacity: "0" }
              }
              key={idx}
              isactive={isTextVisible?.toString()}
            >
              {isActive === idx ? item.review : ""}
            </p>
          ))}
        </div>
      </section>
    </div>
  );
}
