import React from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import Icon from "@Atom/Icon";
import iconInstagram from "@Assets/Icon/instagramGradient.svg";
import iconYoutube from "@Assets/Icon/youtubeGradient.svg";
import iconTiktok from "@Assets/Icon/tiktokGradient.svg";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

export default function FooterLandingPage() {
  const navigate = useNavigate();
  return (
    <footer className={Styles.footer}>
      <section className={Styles.wrapper}>
        <div className={Styles.mainFooter}>
          <div className={Styles.skillsInformation}>
            <div className={Styles.logo}>
              <img src={Images.LOGO} alt="skills.id" />
              <p>Skills.id</p>
            </div>
            <h1>Contact</h1>
            <p>PT. Global Retail Bersama</p>
            <p>Jl. Cidodol Raya no 40 Kebayoran Lama, Jakarta Selatan 12220</p>
            <div className={Styles.contact}>
              <Icon
                icon={"phone"}
                color={"#616161"}
                className={Styles.iconContact}
              />
              <p>+62 811-828-6996</p>
            </div>
            <div className={Styles.contact}>
              <Icon
                icon={"email"}
                color={"#616161"}
                className={Styles.iconContact}
              />
              <p>info@skills.id</p>
            </div>
          </div>

          <div className={Styles.tabs}>
            <div className={Styles.otherMenu}>
              <ul>
                <h1>Navigasi</h1>
                {navigation.map((item, idx) => (
                  <a href={item.link} key={idx}>
                    <li>{item.title}</li>
                  </a>
                ))}
              </ul>
            </div>
            <div className={Styles.otherMenu}>
              <ul>
                <h1>Menu Lainya</h1>
                {otherMenu.map((item, idx) => (
                  <li key={idx} onClick={() => navigate("/" + item.link)}>
                    {item.title}
                  </li>
                ))}
              </ul>
            </div>

            <div className={Styles.socialMedia}>
              <h1>Follow Us</h1>
              {socialMedia.map((item, idx) => (
                <div key={idx} className={Styles.contact}>
                  <img
                    src={item.icon}
                    className={Styles.iconContact}
                    alt="link-social-media"
                  />
                  <p>
                    <Link target="_blank" to={item.link}>
                      {item.text}
                    </Link>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={Styles.lineSeperator}></div>
        <div className={Styles.copyright}>
          <p>Copyright © {moment().format("YYYY")}. Skills.id, All rights reserved.</p>
        </div>
      </section>
    </footer>
  );
}
const socialMedia = [
  {
    icon: iconInstagram,
    text: "@skillsid_official",
    link: "https://www.instagram.com/skillsid_official",
  },
  {
    icon: iconYoutube,
    text: "@skillsidofficial",
    link: "https://www.youtube.com/@skillsidofficial",
  },
  {
    icon: iconTiktok,
    text: "@skillsidofficial",
    link: "https://tiktok.com/@skillsidofficial",
  },
];
const navigation = [
  {
    link: "prakerja",
    title: "Prakerja",
  },
  {
    link: "#categories",
    title: "Kategori",
  },
  {
    link: "#benefit",
    title: "Benefit",
  },
  {
    link: "#testimonials",
    title: "Testimoni",
  },
  {
    link: "#faq",
    title: "FAQ",
  },
];
const otherMenu = [
  { title: "Privacy", link: "privacy" },
  { title: "Term", link: "" },
  { title: "About Us", link: "about" },
];
