import ClassesDesc from "@Molecule/ClassesDesc";
import PreTestWarningInformation from "@Molecule/PreTestWarningInformation";
import React, { useEffect, useState } from "react";
import Styles from "./styles.module.scss";
import PreTestHeader from "@Molecule/PreTestHeader";
import Modal from "@Atom/Modal";
import { postAnswerPostPreTest } from "@Services/userSheet";
import { useLocation, useNavigate } from "react-router-dom";
import PrePostTestQuizModal from "../PrePostTestQuiz";
import NextPrevButton from "@Molecule/NextPrevButton";
import useQuery from "@Hooks/useQuery";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import { checkPrakerjaStatus } from "@Services/prakerja";
import ReedemCodeModal from "@Molecule/ReedemCodeModal";
import ModalResultScore from "@Molecule/ModalScore";

export default function Posttest({ data, userSheet }) {
  const [quizModal, setQuizModal] = useState(false);
  const navigate = useNavigate();
  const query = useQuery();
  const title = query.get("t");
  const sessionsLength = data.sessions.length;
  const lastSessionLength = data.sessions.slice(-1)[0].subjects.length;
  const [openModal, setOpenModal] = useState(false);
  const { pathname } = useLocation();
  const [redeemCode, setRedeemCode] = useState("");
  const user = decryptStorageData("user", localStorage);
  const [errorRedeem, setErrorRedeem] = useState("");
  const [prakerjaLink, setPrakerjaLink] = useState("");
  const [isOpenModalScore, setIsOpenModalScore] = useState(false);
  const [getScore, setGetScore] = useState(0);
  const handleCloseScore = () => {
    setQuizModal(false);
    setIsOpenModalScore(false);
  };
  const [loadingRedeem, setloadingRedeem] = useState(false);
  const withNoSkillTest =
    data.configurations.find((el) => el.label === "skillTest")?.value ===
    "false";

  const handleSubmitQuiz = async (answer) => {
    const dataToSend = {
      userSheetID: userSheet._id,
      type: "postTest",
      PreOrPostTest: Object.values(answer).map((el, idx) => ({
        questionID: userSheet.postTest[idx].questionID,
        answer: el,
      })),
    };
    try {
      const res = await postAnswerPostPreTest(dataToSend);
      if (res.status) {
        // setQuizModal(false);
        setGetScore(res?.score);
        setIsOpenModalScore(true);
        navigate("", { replace: true });
      }
    } catch (error) {}
  };

  const redeem = () => {
    // token, sequence,redeemCode,email,class_id
    setloadingRedeem(true);
    checkPrakerjaStatus({
      sequence: "999",
      redeem_code: redeemCode,
      email: user.email,
      courseID: data._id,
    })
      .then((res) => {
        // dispatch({
        //   type: SET_ALERT,
        //   payload: { status: res.status, message: res.message },
        // });
        // setOpenModal(false);
        setloadingRedeem(false);
        if (res) {
          localStorage.setItem(
            "prakerjaPayload",
            JSON.stringify({
              sequence: "999",
              redeem_code: redeemCode,
              email: user.email,
              courseID: data._id,
            })
          );
          localStorage.setItem("callbackTo", pathname);
          // setSuccessModal(true);
          if (res?.data?.attendance_status === 1) {
            setOpenModal(false);
          }
          if (res.data.url) {
            setPrakerjaLink(res.data.url);
            window.open(res.data.url);
          }
        } else {
          setOpenModal(false);
        }
      })
      .catch((e) => {
        setloadingRedeem(false);
        setErrorRedeem(
          e.response.data?.message || "Kode Redeem Tidak Ditemukan"
        );
      });
  };

  useEffect(() => {
    if (
      data.platform !== "OFFLINE" &&
      userSheet.score.postTest < 1 &&
      data.type === "PRAKERJA"
    ) {
      const data = JSON.parse(localStorage.getItem("prakerjaPayload"));
      if (data) {
        const payload = {
          sequence: "999",
          redeem_code: data.redeem_code,
          email: user.email,
          courseID: data._id,
        };
        setRedeemCode(data.redeem_code);
        checkPrakerjaStatus(payload)
          .then((res) => {
            if (res?.data?.attendance_status === 1) {
              setOpenModal(false);
            } else {
              setOpenModal(true);
            }
          })
          .catch((e) => {
            setOpenModal(true);
          });
      } else {
        setOpenModal(true);
      }
    }
  }, []);

  return (
    <div className={Styles.container}>
      <PreTestWarningInformation description="PENTING! SELESAIKAN POST TEST! Syarat nilai minimal 60 untuk dapat mengikuti sesi berikutnya." />
      <div className={Styles.bodyWrapper}>
        <PreTestHeader
          headerPreTest="Post Test"
          isScore={!!userSheet.score.postTest}
          score={
            userSheet.score.postTest === -1 ? 0 : `${userSheet.score.postTest}`
          }
          type={"postTest"}
          listDescription={[
            "Minimal skor saat mengerjakan Post-Test adalah 60",
            "Anda dapat mengerjakan Post-Test berulang kali",
            "Nilai yang ditampilkan adalah nilai tertinggi yang Anda dapatkan",
          ]}
          textButton="Mulai Post Test"
          modalTitle="Mulai Post Test?"
          // modalDescription="Silakan kerjakan soal Post-Test secara teliti dan benar untuk dapat mengerjakan uji keterampilan."
          modalDescription={
            userSheet.score.postTest !== -1
              ? `Apakah Anda yakin ingin mengulang Post-Test? skor Post-Test Anda sekarang adalah
                      <span style="color: #9360A8;font-size:16px;font-weight:600;">
                        ${userSheet.score.postTest}
                      </span>
                        nilai selanjutnya yang akan ditampilkan adalah nilai terbesar yang Anda dapatkan`
              : "Apakah Anda yakin ingin mengerjakan Post-Test sekarang? Anda harus menyelesaikan Post-Test hingga selesai dan tidak dapat kembali sebelum Post-Test yang Anda kerjakan selesai."
          }
          onClickButtonStart={() => setQuizModal(true)}
          textScore="Skor Akhir Post Test"
          isLockedQuiz={false}
          headerDecription="Silakan kerjakan soal Post-Test secara teliti dan benar untuk dapat mengerjakan uji keterampilan."
        />
        <NextPrevButton
          prevText={"Sebelumnya"}
          nextText={
            !withNoSkillTest
              ? "Lanjut Kerjakan Uji Keterampilan"
              : "Lihat Sertifikat"
          }
          disabledNext={userSheet.score.postTest < 60 && !user.isAssesor}
          handleNext={() =>
            !withNoSkillTest
              ? navigate(`/classes/learn/${data._id}/uk?t=${title}`)
              : navigate(`/classes/learn/${data._id}/certificate?t=${title}`)
          }
          handlePrev={() =>
            navigate(
              `/classes/learn/${data._id}/chapter${sessionsLength}-material${lastSessionLength}?t=${title}`
            )
          }
        />
      </div>
      <ClassesDesc data={data} />
      <Modal isOpen={quizModal}>
        <PrePostTestQuizModal
          data={userSheet.postTest}
          handleSubmit={(answer) => handleSubmitQuiz(answer)}
        />
      </Modal>
      <ModalResultScore
        isOpen={isOpenModalScore}
        handleBack={handleCloseScore}
        currentScore={userSheet.score.postTest}
        getScore={getScore}
        type={"post-test"}
      />
      {!user.isAssesor && (
        <ReedemCodeModal
          disabledClose
          isOpen={openModal}
          onClose={() => setOpenModal("")}
          value={redeemCode}
          onChange={(e) => setRedeemCode(e.target.value)}
          onSubmit={redeem}
          isError={errorRedeem}
          setIsError={setErrorRedeem}
          prakerjaLink={prakerjaLink}
          loading={loadingRedeem}
        />
      )}
    </div>
  );
}
