import React, { useMemo } from "react";
import Styles from "./style.module.scss";
export default function TermConditionPrakerja() {
  const text = useMemo(() => {
    return (
      <ol>
        <li>WNI (Warga Negara Indonesia) berusia 18 tahun ke atas</li>
        <li>Tidak sedang menempuh pendidikan formal</li>
        <li>
          Sedang mencari kerja, pekerja/buruh yang terkena PHK, atau
          pekerja/buruh yang membutuhkan peningkatan kompetensi kerja, seperti
          pekerja/buruh yang dirumahkan dan pekerja bukan penerima upah,
          termasuk pelaku usaha mikro dan kecil.
        </li>
        <li>Bukan penerima bantuan sosial lainnya selama pandemi Covid-19</li>
        <li>
          Bukan Pejabat Negara, Pimpinan dan Anggota DPRD, ASN, Prajurit TNI,
          Anggota Polri, Kepala Desa, dan perangkat desa dan
          Direksi/Komisaris/Dewan Pengawas pada BUMN atau BUMD.
        </li>
        <li>
          Maksimal 2 NIK (Nomor Induk Kependudukan) dalam 1 KK (Kartu Keluarga)
          yang menjadi Penerima Kartu Prakerja.
        </li>
        <li>
          Jika kamu lolos menjadi Penerima Kartu Prakerja, kamu akan mendapat
          bantuan pelatihan sebesar Rp3.500.000 (tiga juta lima ratus ribu
          Rupiah)
        </li>
        <li>
          Setelah menyelesaikan pelatihan, penerima Kartu Prakerja juga akan
          memperoleh insentif.
        </li>
      </ol>
    );
  }, []);
  return (
    <div className={Styles.wrapperConditionPrakerja}>
      <div className={Styles.wrapperText}>
        <h1>
          Ketahui <span>Syarat</span> Mengikuti Program Prakerja
        </h1>
        <p>
          {text}
          <br />
          <span>Insentif terdiri dari 2 (dua) jenis :</span>
          <ul>
            <li>
              Insentif biaya mencari kerja sebanyak 1 (satu) kali sebesar
              Rp600.000 (enam ratus ribu Rupiah)
            </li>
            <li>
              Insentif pengisian survei evaluasi sebesar Rp50.000 (lima puluh
              ribu Rupiah) yang diberikan paling banyak 2 (dua) kali.
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
}
