import AdminClassDetail from "@Organism/Admin/ClassDetail";
import { getClassDetailAdmin } from "@Services/admin";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader(route) {
  const { id } = route.params;
  // const params = new URLSearchParams(route?.request?.url);
  // const page = params?.get("page");
  // const limit = params?.get("limit");
  const getDataClass = await getClassDetailAdmin(id, "detail");
  const getDataStudent = await getClassDetailAdmin(id, "students");
  const getDataAttendance = await getClassDetailAdmin(id, "attendance");
  return defer({
    classData: getDataClass,
    studentData: getDataStudent,
    AttendanceData: getDataAttendance,
  });
}

export default function AdminClassDetailPage() {
  const { classData, studentData, AttendanceData } = useLoaderData();
  return (
    <Suspense fallback={<p>loading....</p>}>
      <Await
        resolve={classData}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(classValue) => (
          <Await
            resolve={studentData}
            errorElement={
              <div style={{ height: "100vh" }}>
                <p>error...</p>
              </div>
            }
          >
            {(studentValue) => (
              <Await
                resolve={AttendanceData}
                errorElement={
                  <div style={{ height: "100vh" }}>
                    <p>error...</p>
                  </div>
                }
              >
                {(attendanceValue) => (
                  <AdminClassDetail
                    data={{ classValue, studentValue, attendanceValue }}
                  />
                )}
              </Await>
            )}
          </Await>
        )}
      </Await>
    </Suspense>
  );
}
