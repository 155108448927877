import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import { useNavigate } from "react-router-dom";
import InputTextArea from "@Atom/InputTextArea";
import InputText from "@Atom/InputText";
import Images from "@Theme/Images";
import { useMemo } from "react";
import { fileBaseUrl } from "@Config/index";
import { _feedbackTpm, _prakerjaSync } from "@Services/instructor";
import useQuery from "@Hooks/useQuery";
import Modal from "@Atom/Modal";
import PrakerjaSyncModal from "@Molecule/PrakerjaSyncModal";
import Icon from "@Atom/Icon";
import { translateError } from "@Helpers/translateError";

export default function TpmDetail({ data }) {
  const navigate = useNavigate();
  const query = useQuery();
  const name = query.get("name") || "-";
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState(0);
  const [message, setMessage] = useState("");

  const tpmData = useMemo(() => {
    const tpm = [];
    for (let i = 0; i < data?.sessions?.length; i++) {
      const session = data?.sessions[i];
      tpm.push(session.subjects.find((el) => el.type === "TASK"));
    }
    return tpm;
  }, [data]);

  console.log(tpmData);

  const [tpmState, setTpmState] = useState([]);

  useEffect(() => {
    setTpmState(
      tpmData.map((el) => ({
        score: el?.score?.overall || "",
        feedback: el?.score?.feedback || "",
        isSync: data.courseType !== "PRAKERJA" || !!el?.score?.feedback,
      }))
    );
  }, [data.courseType, tpmData]);

  const handleChange = (idx, value, name) => {
    let temp = [...tpmState];
    temp[idx][name] = value;
    setTpmState(temp);
  };

  const handleSubmit = async (idx) => {
    const payload = {
      userSheetID: data._id,
      sessionID: data.sessions[idx].sessionID,
      subjectNum: String(tpmData[idx]?.num),
      ...tpmState[idx],
    };
    try {
      await _feedbackTpm(payload);
    } catch (error) {
      console.log(translateError(error.response.data.error));
    }
  };

  const handleSyncPrakerja = async (idx) => {
    const payload = {
      userSheetID: data._id,
      sessionID: data.sessions[idx].sessionID,
      subjectNum: String(idx + 1),
      type: "tpm",
    };
    try {
      setOpenModal(true);
      setStatus(2);
      const res = await _prakerjaSync(payload);
      setStatus(0);
      if (res.status) {
        const temp = [...tpmState];
        temp[idx].isSync = true;
        setTpmState(temp);
      }
    } catch (error) {
      if (
        error.response.data.error ===
        "File yang sama sebelumnya sudah di upload"
      ) {
        setStatus(0);
        const temp = [...tpmState];
        temp[idx].isSync = true;
        setTpmState(temp);
      } else {
        setStatus(1);
        setMessage(error.response.data.error);
      }
    }
  };

  return (
    <div className={Styles.container}>
      <Button
        variant="text"
        startIcon={"chevron-left"}
        text="Kembali"
        onClick={() => navigate(-1)}
      />
      <div className={Styles.titleSection}>
        <p>Penilaian Tugas Praktek Mandiri</p>
      </div>
      <div className={Styles.profileSection}>
        <p>Nama Murid</p>
        <p>{name}</p>
      </div>
      <div className={Styles.bodyWrapper}>
        {tpmData?.map((el, idx) =>
          el?.content?.answer ? (
            <div className={Styles.card} key={idx}>
              <div className={Styles.cardTitle}>
                <p>TPM Sesi {idx + 1}</p>
                <Icon
                  icon={
                    tpmState[idx]?.isSync ? "cloud-checkmark" : "cloud-sync"
                  }
                  size={24}
                  onClick={() => handleSyncPrakerja(idx)}
                />
              </div>
              <div className={Styles.cardBody}>
                <div className={Styles.leftSection}>
                  <div className={Styles.contentWrapper}>
                    <div className={Styles.file}>
                      <img
                        src={Images.PDF_ICON}
                        alt="file-icon"
                        type={"pdf"}
                        className={Styles.icon}
                      />
                      <p>{el.content.answer}</p>
                    </div>
                    <Button
                      variant="outlined"
                      text="Lihat File"
                      className={Styles.buttonFile}
                      onClick={() =>
                        window.open(fileBaseUrl + `${el.content.answer}`)
                      }
                    />
                  </div>
                </div>
                <div className={Styles.rightSection}>
                  <InputTextArea
                    labelValue="Feedback"
                    required
                    placeholder="Feedback"
                    value={tpmState[idx]?.feedback}
                    onChange={(e) =>
                      handleChange(idx, e.target.value, "feedback")
                    }
                    disabled={!tpmState[idx]?.isSync}
                  />
                  <div className={Styles.cardAction}>
                    <div className={Styles.score}>
                      <p>Skor</p>
                      <InputText
                        labelValue=""
                        placeholder="0-100"
                        value={tpmState[idx]?.score}
                        onChange={(e) => {
                          if (
                            +e.target.value &&
                            +e.target.value < 101 &&
                            !String(e.target.value).includes(".")
                          ) {
                            handleChange(idx, e.target.value, "score");
                          } else if (e.target.value === "") {
                            handleChange(idx, "", "score");
                          }
                        }}
                        disabled={!tpmState[idx]?.isSync}
                      />
                    </div>
                    <Button
                      variant="contained"
                      text="Simpan"
                      style={{ height: "42px" }}
                      onClick={() => handleSubmit(idx)}
                      disabled={!tpmState[idx]?.isSync}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={Styles.card} key={idx}>
              <div className={Styles.cardTitle} type="empty">
                <p>TPM Sesi {idx + 1}</p>
              </div>
              <div className={Styles.cardBodyEmpty}>
                <img src={Images.DOC_ICON} alt="empty-icon" />
                <p>Belum ada tugas yang dikumpulkan peserta</p>
              </div>
            </div>
          )
        )}
      </div>
      <Modal isOpen={openModal}>
        <PrakerjaSyncModal
          status={status}
          handleClose={() => setOpenModal(false)}
          message={message}
        />
      </Modal>
    </div>
  );
}
