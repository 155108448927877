import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { Link } from "react-router-dom";
export default function ContactLandingPage() {
  return (
    <Link to={"https://wa.me/+628118286996"}>
      <div className={Styles.wrapperContactMe}>
        <Icon icon={"whatsapp"} size={"24"} color={"white"} />
        <span>Kontak Kami</span>
      </div>
    </Link>
  );
}
