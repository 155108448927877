import QuestionGenerator from "@Molecule/QuestionGenerator";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import { makeRandomString } from "@Helpers/makeRandomString";
import Switch from "@Atom/Switch";

export default function QuestionsSection({
  questions,
  setQuestions = () => {},
  config,
  setConfig = () => {},
  participantType,
}) {
  const onChangeConfig = (name, value) => {
    let temp = { ...config };
    temp[name] = value;
    setConfig(temp);
  };
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Bank Soal</h3>
        <span>
          Buat dan tambahkan soal sebagai test akhir pada pembelajaran kelas
          yang telah dilaksanakan.
        </span>
      </div>
      {participantType !== "PRAKERJA" && (
        <div className={Styles.configWrapper}>
          <div className={Styles.config} is-active={String(config.questions)}>
            <p>Aktifkan bank soal untuk kelas ini</p>
            <Switch
              isChecked={config.questions}
              setIsChecked={() =>
                setConfig((prev) => ({
                  ...prev,
                  preTest: false,
                  postTest: false,
                  questions: !prev.questions,
                }))
              }
            />
          </div>
          <div className={Styles.config} is-shown={String(config.questions)}>
            <p>Gunakan bank soal untuk pretest</p>
            <Switch
              isChecked={config.preTest}
              setIsChecked={() => onChangeConfig("preTest", !config.preTest)}
            />
          </div>
          <div className={Styles.config} is-shown={String(config.questions)}>
            <p>Gunakan bank soal untuk posttest</p>
            <Switch
              isChecked={config.postTest}
              setIsChecked={() => onChangeConfig("postTest", !config.postTest)}
            />
          </div>
        </div>
      )}
      <div className={Styles.content} is-shown={String(config.questions)}>
        <div className={Styles.questions}>
          {questions?.map((q, qI) => (
            <QuestionGenerator
              key={qI}
              placeholderRadio={"Masukkan opsi jawaban"}
              question={`Soal ${qI + 1}`}
              valueTextEditor={q?.question}
              setValueTextEditor={(newVal) => {
                const newQuestions = questions?.map((obj) => {
                  if (obj?.id === q?.id) {
                    return {
                      ...obj,
                      question: newVal,
                    };
                  }
                  return obj;
                });
                setQuestions(newQuestions);
              }}
              withDeleteOption={qI !== 0}
              onDelete={() =>
                setQuestions((prev) => prev?.filter((obj) => obj?.id !== q?.id))
              }
              options={q?.options}
              setOptions={(newOptions) => {
                const newQuestions = questions?.map((obj) => {
                  if (q?.id === obj?.id) {
                    return {
                      ...obj,
                      options: newOptions,
                    };
                  }
                  return obj;
                });
                setQuestions(newQuestions);
              }}
            />
          ))}
        </div>
        <Button
          text={"Tambah Soal"}
          variant="outlined"
          startIcon={"plus"}
          iconSize={"11px"}
          onClick={() => {
            const newQuestions = [
              ...questions,
              {
                id: makeRandomString(5),
                type: "PILIHAN GANDA",
                question: "",
                options: [
                  {
                    id: 1,
                    option: "",
                    isCorrect: true,
                  },
                  {
                    id: 2,
                    option: "",
                    isCorrect: false,
                  },
                  {
                    id: 3,
                    option: "",
                    isCorrect: false,
                  },
                  {
                    id: 4,
                    option: "",
                    isCorrect: false,
                  },
                ],
              },
            ];
            setQuestions(newQuestions);
          }}
        />
      </div>
    </div>
  );
}
