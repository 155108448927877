import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import { useEffect, useRef, useState } from "react";
import Styles from "./style.module.scss";

export default function DropdownTextArea({
  inputValue,
  setInputValue,
  selectValue,
  setSelectValue,
  handleDelete = () => {},
  label,
  option,
  inputPlaceholder = "Isi aspek kompetensi",
  withDeleteOption = false,
  required,
}) {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const dropdownRef = useRef();

  const handleCheckedItem = (item) => {
    setIsOpenDropdown(false);
    setSelectValue(item);
  };
  const handleClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  return (
    <div className={Styles.container} ref={dropdownRef}>
      {label && (
        <div className={Styles.labelWrapper}>
          <Text width={"medium"} color={"#616161"}>
            {label}
            <Text color={"#d1421a"}>{required && "*"}</Text>
          </Text>
        </div>
      )}
      <div className={Styles.inputContainer}>
        <div className={Styles.selectContainer}>
          <div
            name=""
            onClick={() => setIsOpenDropdown((e) => !e)}
            className={Styles.select}
          >
            <p>{selectValue || "Pilih aspek"}</p>
            <Icon
              icon={"keyboard_arrow_down"}
              size={24}
              is-rotate={isOpenDropdown ? "true" : "false"}
              className={Styles.dropdownIcon}
            />
          </div>
          {isOpenDropdown && (
            <div className={Styles.selectItem}>
              {option?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleCheckedItem(item)}
                  className={Styles.item}
                >
                  <p size={"m"} width={"medium"} className={Styles.itemText}>
                    {item}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={Styles.inputContainer}>
          <textarea
            type="text"
            className={Styles.input}
            placeholder={inputPlaceholder}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          ></textarea>
        </div>
        {withDeleteOption && (
          <div className={Styles.deleteIcon} onClick={handleDelete}>
            <Icon icon={"indeterminate-circle"} size={20} color={"#D1421A"} />
          </div>
        )}
      </div>
    </div>
  );
}
