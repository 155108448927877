import MasterDataCertificate from "@Organism/Admin/Certificate";
import { fetchMasterDataCertificate } from "@Services/admin";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = () => {
  const dataCertificate = fetchMasterDataCertificate();
  return defer({ dataCertificate });
};

export default function MasterDataCertificatePage() {
  const { dataCertificate } = useLoaderData();
  return (
    <Suspense fallback={<MasterDataCertificate isLoading data={null} />}>
      <Await
        resolve={dataCertificate}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <MasterDataCertificate data={value} />}
      </Await>
    </Suspense>
  );
}
