import React from "react";
import Styles from "./style.module.scss";
export default function HeaderLandingPage() {
  return (
    <section className={Styles.container}>
      <div className={Styles.wrapperHeaderTitle}>
        <h1>Asah Kompetensimu Bersama Skills.id</h1>
        <p>Pilih pelatihanmu sekarang!</p>
        <span className={Styles.lineSeperator}></span>
      </div>
    </section>
  );
}
