import Sidebar from "@Molecule/Sidebar";
import LandingPageLayout from "@Organism/Landing";
import { getLandingPageData } from "@Services/landingPage";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
export async function loader() {
  const getData = await getLandingPageData();
  return defer({
    getLandingPageData: getData,
  });
}
const LandingPage = () => {
  const { getLandingPageData } = useLoaderData();
  return (
    <Suspense fallback={<p>loading....</p>}>
      <Await
        resolve={getLandingPageData}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => (
          <Sidebar type={"landing"}>
            <LandingPageLayout data={value} />
          </Sidebar>
        )}
      </Await>
    </Suspense>
  );
};

export default LandingPage;
