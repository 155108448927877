import React from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
export default function FaqLandingPage() {
  return (
    <div className={Styles.container}>
      <section className={Styles.wrapperFaq} id="faq">
        <div className={Styles.images}>
          <img src={Images.FAQ_LOGO_LANDING_PAGE_L} alt="left" />
        </div>
        <div className={Styles.textFaq}>
          <h1>
            <span>{"F"}</span>
            {"requently "}
            <span>{"A"}</span>
            {"sked "}
            <span>{"Q"}</span>
            {"uestion"}
          </h1>
          <p>
            Cari pertanyaan seputar <span>{" Skills.id "}</span> di rangkuman
            ini
          </p>
        </div>
        <div className={Styles.images}>
          <img src={Images.FAQ_LOGO_LANDING_PAGE_R} alt="" />
        </div>

        <div className={Styles.textFaqMobile}>
          <h1>
            <span>{"F"}</span>
            {"requently "}
            <span>{"A"}</span>
            {"sked "}
            <span>{"Q"}</span>
            {"uestion"}
          </h1>
          <p>
            Cari pertanyaan seputar <span>{" Skills.id "}</span> di rangkuman
            ini
          </p>
        </div>
      </section>
    </div>
  );
}
