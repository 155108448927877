import React, { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@Atom/Button";
import AdminDetailClass from "./ClassDetail";
import StudentListAdmin from "./StudentList";
import AbsenceClassAdmin from "./Absence";
import JournalListAdmin from "./Journal";
import useQuery from "@Hooks/useQuery";

export default function AdminClassDetail({ data }) {
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const query = useQuery();
  const tabQuery = query.get("t") || 1;
  const { pathname } = useLocation();

  useEffect(() => {
    setTab(+tabQuery);
  }, [tabQuery]);

  console.log(data.classValue);

  const renderComponent = useCallback(() => {
    switch (tab) {
      case 1:
        return <AdminDetailClass data={data.classValue} />;
      case 2:
        return <StudentListAdmin />;
      case 3:
        return (
          <AbsenceClassAdmin
            data={data.attendanceValue}
            dataClass={data.classValue}
          />
        );
      case 4:
        return <JournalListAdmin data={data.studentValue} />;
      default:
        break;
    }
  }, [data, tab]);

  const buttonArr = useMemo(() => {
    if (
      data.classValue.type === "GENERAL" &&
      data.classValue.platform === "VIDEO"
    ) {
      return [
        { type: "Detail Kelas", value: 1 },
        { type: "Daftar Peserta", value: 2 },
      ];
    } else if (data.classValue.type === "GENERAL") {
      return [
        { type: "Detail Kelas", value: 1 },
        { type: "Daftar Peserta", value: 2 },
        { type: "Absensi Peserta", value: 3 },
      ];
    } else {
      return [
        { type: "Detail Kelas", value: 1 },
        { type: "Daftar Peserta", value: 2 },
        { type: "Absensi Peserta", value: 3 },
        { type: "Reflective Journal", value: 4 },
      ];
    }
  }, [data]);

  return (
    <div className={Styles.container}>
      <div className={Styles.breadcrumbsWrapper}>
        <div
          className={Styles.backIcon}
          onClick={() =>
            navigate(`${pathname.split("/").slice(0, 3).join("/")}`)
          }
        >
          <Icon icon={"chevron-left"} size={16} />
        </div>
        <div className={Styles.breadcrumb}>
          <p>Daftar Kelas</p>
          <Icon icon={"chevron-right"} size={16} color={"#3E4856"} />
          <p>{data?.classValue?.title}</p>
        </div>
      </div>
      <div className={Styles.tabHeaderWrapper}>
        {buttonArr.map((el, idx) => (
          <React.Fragment key={idx}>
            <Button
              variant="tab"
              text={el.type}
              isActive={tab === el.value}
              onClick={() => setTab(el.value)}
            />
          </React.Fragment>
        ))}
      </div>
      {renderComponent()}
    </div>
  );
}
