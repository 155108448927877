// eslint-disable-next-line import/no-anonymous-default-export
export default {
  BNI: require("@Assets/logo/bni.png"),
  BCA: require("@Assets/logo/bca.png"),
  BRI: require("@Assets/logo/bri.png"),
  CIMB: require("@Assets/logo/cimb.png"),
  DANA: require("@Assets/logo/dana.png"),
  DANAMON: require("@Assets/logo/danamon.png"),
  OVO: require("@Assets/logo/ovo.png"),
  PERMATA: require("@Assets/logo/permata.png"),
  MANDIRI: require("@Assets/logo/mandiri.png"),
  // QRIS: require("@Assets/logo/QRIS.png"),
};