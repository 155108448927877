import React, { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import ModalGenerateVocuherAdmin from "@Molecule/ModalGenerateVocuherAdmin";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import InputField from "@Molecule/InputField";
import Table from "@Molecule/Table";
import useWindowSize from "@Hooks/useWindowSize";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";
import { generateVoucher } from "@Services/voucher";
import Spinner from "@Atom/Spinner";
import Modal from "@Atom/Modal";
import rejectedImage from "@Assets/Images/Rejected.png";
import Footer from "@Molecule/Footer";
import { useDebounce } from "@Hooks/useDebounce";
export default function VoucherAdminLayout({ data }) {
  const user = decryptStorageData("user", localStorage);
  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const query = useQuery();
  const pageQuery = query?.get("page");
  const limitQuery = query?.get("limit");
  const search = query?.get("search");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [inputVoucher, setInputVoucher] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  useEffect(() => {
    if (debouncedSearchQuery?.length > 1) {
      setLoading(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [debouncedSearchQuery]);
  useEffect(() => {
    setPage(+pageQuery || 1);
    setLimit(+limitQuery || 10);
    setSearchQuery(search || "");
  }, [limitQuery, pageQuery, search]);
  useEffect(() => {
    navigate(
      `${pathname}?s=1&limit=${limit}&page=${page}&search=${debouncedSearchQuery}`,
      { replace: true }
    );
  }, [page, limit, navigate, pathname, debouncedSearchQuery]);

  const handleOpenModal = useCallback((id) => {
    setIsOpen(true);
    setSelectedItemId(id);
  }, []);
  const [isErrorGenerate, setIsErrorGenerate] = useState(false);

  const template = useMemo(() => {
    return {
      data: data?.data?.map((el) => {
        return {
          ...el,
          id: el?._id,
          title: el?.title || "-",
          totalVoucher: el?.totalVouchers || "0",
          price: el?.price?.total.toLocaleString("id") || "-",
        };
      }),
      columns: [
        {
          name: "title",
          width: width > 768 ? "455px" : "200px",
          title: "Judul Kelas",
        },
        {
          name: "platform",
          title: "Jenis Kelas",
          hideOnMobile: true,
        },
        {
          name: "totalVoucher",
          title: "Total Voucher",
          renderData: (row) => (
            <div className={Styles.instructor}>
              <span>{row?.totalVoucher}</span>
            </div>
          ),
          hideOnMobile: true,
        },
        {
          name: "price",
          title: "Harga Kelas",
          hideOnMobile: true,
        },
        {
          name: "button",
          title: "",
          renderData: (row) => (
            <div
              className={Styles.buttonGenerate}
              onClick={() => handleOpenModal(row.id)}
            >
              Generate
            </div>
          ),
          hideOnMobile: true,
        },
      ],
    };
  }, [data?.data, handleOpenModal, width]);

  // const searchFilterData = useMemo(() => {
  //   return template?.data?.filter((item) =>
  //     item?.title?.toLowerCase().includes(searchQuery.toLowerCase())
  //   );
  // }, [searchQuery, template.data]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setInputVoucher((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);
  const [isInputNull, setIsInputNull] = useState({
    partner: false,
    discount: false,
    quantity: false,
  });

  const handleGenerateVoucher = async () => {
    try {
      if (!inputVoucher.partner) {
        setIsInputNull((prevState) => ({
          ...prevState,
          partner: true,
        }));
      } else if (!inputVoucher.quantity) {
        setIsInputNull((prevState) => ({
          ...prevState,
          quantity: true,
        }));
      } else if (!inputVoucher.discount) {
        setIsInputNull((prevState) => ({
          ...prevState,
          discount: true,
        }));
      } else {
        setIsLoading(true);
        setIsOpen(false);

        const response = await generateVoucher(payload);
        setIsErrorGenerate(false);
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = "generate_voucher.xlsx";
        downloadLink.click();
        setIsLoading(false);
        setInputVoucher({});
        setIsInputNull({});
      }
    } catch (error) {
      console.error("Error generating voucher:", error);
      setIsErrorGenerate(true);
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  const payload = {
    type: "PRAKERJA",
    courseID: selectedItemId,
    partner: inputVoucher.partner?.toUpperCase(),
    discount: inputVoucher.discount?.toString(),
    quantity: +inputVoucher?.quantity,
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseGenerate = () => {
    setIsOpen(false);
    setIsInputNull({});
    setInputVoucher({});
    setIsErrorGenerate(false);
  };
  return (
    <div className={Styles.main}>
      <div className={Styles.wrapperVoucher}>
        <div className={Styles.wrapper}>
          <DoodleGradientCard>
            <div className={Styles.doodleCard}>
              <h2>
                Selamat Datang{" "}
                <span className={Styles.span}> {user?.name}</span>
              </h2>
              <p>
                Silahkan <span className={Styles.span}>generate</span> kode
                voucher untuk setiap kelas di halaman ini
              </p>
            </div>
          </DoodleGradientCard>
          <div className={Styles.searchBar}>
            <InputField
              startIcon="search-normal"
              placeholder="Cari Kelas"
              iconSize="20"
              setValue={setSearchQuery}
              value={searchQuery}
            />
          </div>
          <div className={Styles.wrapperTable}>
            <Table
              className={Styles.tableRadius}
              data={template.data}
              columns={template.columns}
              page={page}
              limit={limit}
              totalData={data?.totalData}
              totalPage={Math.ceil(data?.totalData / limit)}
              setLimit={setLimit}
              setPage={setPage}
              withPagination
              isLoading={loading}
              expandComponent={(row) => (
                <div className={Styles.wrapperExpand}>
                  <div className={Styles.listTableMobile}>
                    <div className={Styles.totalVoucherM}>
                      <h2>Jenis Kelas</h2>
                      <p>{row?.platform}</p>
                    </div>
                    <div className={Styles.totalVoucherM}>
                      <h2>Total Voucher</h2>
                      <p>{row?.totalVoucher}</p>
                    </div>
                    <div className={Styles.priceM}>
                      <h2>Harga Kelas</h2>
                      <p>{row?.price}</p>
                    </div>
                  </div>
                  <div
                    className={Styles.buttonGenerate}
                    onClick={() => handleOpenModal(row.id)}
                  >
                    Generate
                  </div>
                </div>
              )}
            />
          </div>
          <Modal isOpen={isLoading}>
            <Spinner />
          </Modal>
          <Modal isOpen={isErrorGenerate}>
            <div className={Styles.isErorrModal}>
              <h2>Generate Voucher Gagal</h2>
              <div>
                <img src={rejectedImage} alt="error-badge" />
              </div>
              <p>
                Voucher gagal untuk di generate silahkan coba generate voucher
                kembali.
              </p>
              <div className={Styles.buttonModal}>
                <div onClick={handleCloseGenerate}>
                  <span>Tutup</span>
                </div>
                <div
                  onClick={() => {
                    setIsErrorGenerate(false);
                    setIsOpen(true);
                  }}
                >
                  <span>Coba Lagi</span>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        {selectedItemId && (
          <ModalGenerateVocuherAdmin
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
              setInputVoucher({});
              setIsInputNull({});
            }}
            handleChange={handleChange}
            valueDiscount={+inputVoucher?.discount || ""}
            valueQuantity={+inputVoucher?.quantity || ""}
            valueVendor={inputVoucher?.partner || ""}
            onClick={() => {
              handleGenerateVoucher(payload);
            }}
            isErrorVendor={isInputNull.partner && !inputVoucher.partner}
            isErrorDiscount={isInputNull.discount && !inputVoucher.discount}
            isErrorQuantity={isInputNull.quantity && !inputVoucher.quantity}
          />
        )}
      </div>
      <Footer />
    </div>
  );
}
