import InputField from '@Molecule/InputField'
import Styles from './style.module.scss'
import TextEditor from '@Atom/TextEditor'

export default function RulesSection({
  rule,
  setRule,
  // rules
}) {

 

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Aturan Kelas</h3>
        <span>Isi dan lengkapi aturan kelas pada kelas yang akan diselengarakan.</span>
      </div>
      <div className={Styles.content}>
        {/* <InputField
          title={'Template Aturan Kelas'}
          value={rule}
          setValue={setRule}
          placeholder='Masukkan template aturan'
          // placeholder='Tidak ada template dipilih'
          // isDropdown
          // dropdownOptions={rules?.map(obj => obj?.name)}
          isTextArea
        /> */}
        <TextEditor
          value={rule}
          setValue={setRule}
        />
      </div>
    </div>
  )
}