import AdminClassLayout from "@Organism/Admin/Class";
import { getClassesAdmin } from "@Services/admin";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const type = params.get("classType") || "PRAKERJA";
  const search = params.get("search") || "";
  const classesDraft = getClassesAdmin("draft", type, limit, page, search);
  const classesPublished = getClassesAdmin(
    "published",
    type,
    limit,
    page,
    search
  );

  return defer({
    dataClassesDraft: classesDraft,
    dataClassesPublished: classesPublished,
  });
}

export default function AdminClassPage() {
  const { dataClassesDraft, dataClassesPublished } = useLoaderData();

  return (
    <Suspense fallback={<p>Loading....</p>}>
      <Await resolve={dataClassesPublished} errorElement={<div>error</div>}>
        {(valueClassPublished) => (
          <Suspense fallback={<p>Loading....</p>}>
            <Await resolve={dataClassesDraft} errorElement={<div>error</div>}>
              {(valueClassDraft) => (
                <AdminClassLayout
                  dataPublished={valueClassPublished}
                  dataDraft={valueClassDraft}
                />
              )}
            </Await>
          </Suspense>
        )}
      </Await>
    </Suspense>
  );
}
