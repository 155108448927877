import React, { useRef, useState } from "react";
import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import Images from "@Theme/Images";

export default function InputFileModal({
  handleSubmit,
  onClose,
  text = "Redeem",
}) {
  const [file, setFile] = useState();
  const inputRef = useRef();
  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };
  return (
    <div className={Styles.container}>
      <div className={Styles.modalHead}>
        <p>Import Data</p>
        <Icon icon={"cross"} size={16} onClick={onClose} />
      </div>
      <div className={Styles.modalBody}>
        <p>Unggah Data {text}</p>
        {!file ? (
          <div>
            <div className={Styles.inputArea}>
              <Icon icon={"document-upload"} size={48} color={"#9360A8"} />
              <p className={Styles.textInput}>Upload Data {text}</p>
              <Button
                text={"Pilih File"}
                variant="outlined"
                onClick={() => {
                  inputRef?.current?.click();
                }}
              />
            </div>
          </div>
        ) : (
          <div className={Styles.fileWrapper}>
            <div className={Styles.leftSection}>
              <img alt="" src={Images.EXCEL_ICON} />
              <div>
                <p>{file.name}</p>
                <p>{(file.size / 1000000).toFixed(1)} mb</p>
              </div>
            </div>
            <div className={Styles.rightSection}>
              <Icon
                icon={"delete-outline"}
                size={22}
                color={"#D1421A"}
                onClick={() => setFile()}
              />
              <Icon
                icon={"edit"}
                size={22}
                color={"#A87EB9"}
                onClick={() => {
                  inputRef?.current?.click();
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className={Styles.modalAction}>
        <Button
          text={"Simpan"}
          onClick={() => {
            handleSubmit(file);
          }}
        />
      </div>
      <input
        onChange={(e) => handleChangeFile(e)}
        type="file"
        ref={inputRef}
        hidden
      />
    </div>
  );
}
