import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { getCoursebyVoucher } from "@Services/course";
import { translateError } from "@Helpers/translateError";

export default function VoucherPrakerjaLandingPage() {
  const navigate = useNavigate();
  const [voucherCode, setVoucherCode] = useState();
  const [errorRedeem, setErrorRedeem] = useState("");
  const handleExchangeVoucher = async () => {
    try {
      const res = await getCoursebyVoucher(voucherCode);
      if (res.status) {
        navigate(
          `/course/${res?.data?.title
            ?.split(" ")
            .join("-")
            .split("/")
            .join("-")}/${res.data._id}?voucher=${voucherCode}`
        );
      }
    } catch (error) {
      setErrorRedeem(translateError(error.response.data.error));
    }
  };

  useEffect(() => {
    if (voucherCode) {
      setErrorRedeem(false);
    }
  }, [voucherCode]);
  
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleExchangeVoucher();
    }
  };
  return (
    <div className={Styles.voucher}>
      <h1>Punya Voucher Prakerja?</h1>
      <p>Tukarkan voucher Prakerja kamu di sini</p>
      <div className={Styles.enterVoucherPrakerja}>
        <div className={Styles.inputAndButton}>
          <input
            type="text"
            placeholder="Masukan voucher prakerja"
            onChange={(e) => setVoucherCode(e.target.value)}
            value={voucherCode}
            is-error={errorRedeem ? "true" : "false"}
            onKeyDown={handleEnterPress}
          />
          <button
            className={Styles.buttonVoucherPrakerja}
            onClick={handleExchangeVoucher}
          >
            Tukar
          </button>
        </div>
        {!!errorRedeem && (
          <span className={Styles.errorMessage}>
            {errorRedeem.toLowerCase() !== "voucher already exchanged"
              ? "Voucher Tidak Di Temukan"
              : errorRedeem}
          </span>
        )}
      </div>
      {/* <Modal isOpen={successExchangeModal}>
        <div className={Styles.modalSuccessContainer}>
          <p className={Styles.modalTitle}>Kelas Anda berhasil di aktifkan</p>
          <img alt="success" src={Images.SUCCESS_COIN} />
          <p className={Styles.modalDesc}>
            Ayo tingkatkan skillsmu dan mulai belajar bersama Skills.id
          </p>
          <Button
            text={"Mulai Belajar"}
            onClick={() => navigate("/student/")}
          />
        </div>
      </Modal> */}
    </div>
  );
}
