import PrakerjaStatusDetail from "@Organism/Admin/PrakerjaStatusDetail";
import { getClassPrakerjaStatus } from "@Services/admin";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader({ params, request }) {
  const { id } = params;
  const url = new URL(request.url);
  const sessionNum = url.searchParams.get("s") || 1;
  const courseBatch = url.searchParams.get("b") || 1;
  const studentList = getClassPrakerjaStatus(id, sessionNum, courseBatch);

  return defer({
    studentList,
  });
}

export default function PrakerjaStatusDetailPage() {
  const { studentList } = useLoaderData();

  return (
    <Suspense fallback={<PrakerjaStatusDetail data={[]} loading/>}>
      <Await resolve={studentList} errorElement={<div>error</div>}>
        {(data) => <PrakerjaStatusDetail data={data}/>}
      </Await>
    </Suspense>
  );
}
