import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Button from "@Atom/Button";
import Input from "@Atom/Input";
import Avatar from "@Atom/Avatar";
import moment from "moment";
import useQuery from "@Hooks/useQuery";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "@Molecule/Table";
import Modal from "@Atom/Modal";
import InputFileModal from "@Molecule/InputFileModal";
import {
  downloadDataCompletion,
  downloadDataRedeem,
  updateDataRedeem,
  updateDataReport,
} from "@Services/admin";
import FileUploadSuccess from "@Molecule/FileUploadSuccessModal";
import Footer from "@Molecule/Footer";
import { useDebounce } from "@Hooks/useDebounce";
import Searchbar from "@Atom/Searchbar";

const tab = ["Data Redeem", "Data Completion", "Data Report"];

export default function AdminPrakerjaLayout({ data }) {
  const user = decryptStorageData("user", localStorage);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const query = useQuery();
  const pageQuery = query.get("page");
  const limitQuery = query.get("limit");
  const startDateQuery = query.get("startDate");
  const endDateQuery = query.get("endDate");
  const searchQuery = query.get("search");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [search, setSearch] = useState("");
  const debounce = useDebounce(search, 500);


  useEffect(() => {
    setPage(+pageQuery || 1);
    setLimit(+limitQuery || 10);
    setStartDate(startDateQuery || "");
    setEndDate(endDateQuery || "");
    setSearch(searchQuery || "");
  }, [limitQuery, pageQuery, startDateQuery, endDateQuery, searchQuery]);

  useEffect(() => {
    navigate(
      `${pathname}?s=1&limit=${limit}&page=${page}&startDate=${startDate}&endDate=${endDate}&search=${search}`,
      { replace: true }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, navigate, pathname, startDate, endDate, debounce]);

  useEffect(() => {
    setPage(1);
    setSearch("")
  }, [selectedTab]);

  const template = useMemo(() => {
    return {
      data: data.data,
      columns: [
        {
          name: "",
          title: "Peserta",
          renderData: (row) => (
            <div className={Styles.userProfile}>
              <Avatar iconName="user" iconSize="12px" size="24px" />
              <div className={Styles.labelWrapper}>
                <p>{row?.user?.name}</p>
                <p>{row?.user?.email}</p>
              </div>
            </div>
          ),
        },
        {
          name: "",
          title: "Kode Voucher & invoice",
          renderData: (row) => (
            <div>
              <div className={Styles.labelWrapper}>
                <p>
                  {row?.voucher.find((el) => el.courseID === row.courseID)
                    ?.code || "-"}
                </p>
                <p>{row?.transaction[0]?.invoiceCode || "-"}</p>
              </div>
            </div>
          ),
        },
        {
          name: "",
          title: "Nama Sesuai Prakerja",
          renderData: (row) => (
            <span>
              {row?.certificate.find((el) => el.courseID === row.courseID)
                ?.name || "-"}
            </span>
          ),
        },
        {
          name: "",
          title: "Tanggal & Waktu Redeem",
          renderData: (row) => (
            <span>
              {moment(new Date(row?.createdAt)).format("Do MMMM YYYY, HH:MM")}
            </span>
          ),
        },
        {
          name: "",
          title: "No Handphone",
          renderData: (row) => <span>{row?.user?.phoneNumber}</span>,
        },
        {
          name: "",
          title: "Judul Kelas",
          renderData: (row) => <span>{row?.course[0].title}</span>,
        },
      ],
      columns1: [
        {
          name: "",
          title: "Peserta",
          renderData: (row) => (
            <div className={Styles.userProfile}>
              <Avatar iconName="user" iconSize="12px" size="24px" />
              <div className={Styles.labelWrapper}>
                <p>{row?.user?.name}</p>
                <p>{row?.user?.email}</p>
              </div>
            </div>
          ),
        },
        {
          name: "",
          title: "Kode Voucher",
          renderData: (row) => (
            <span>
              {row?.voucher.find((el) => el.courseID === row.courseID)?.code ||
                "-"}
            </span>
          ),
        },
        {
          name: "",
          title: "Judul Kelas",
          renderData: (row) => <span>{row?.course[0].title}</span>,
        },
        {
          name: "",
          title: "status",
          width: "180px",
          renderData: (row) => (
            <div>
              <div
                className={Styles.statusLabel}
                status={
                  row?.voucher.find((el) => el.courseID === row.courseID)
                    ?.isReported
                    ? "true"
                    : "false"
                }
              >
                <span
                  className={Styles.statusLabel}
                  status={
                    row?.voucher.find((el) => el.courseID === row.courseID)
                      ?.isReported
                      ? "true"
                      : "false"
                  }
                >
                  {row?.voucher.find((el) => el.courseID === row.courseID)
                    ?.isReported
                    ? "Sudah di Report"
                    : "Belum di Report"}
                </span>
              </div>
            </div>
          ),
        },
      ],
    };
  }, [data]);

  const handleDownloadDataRedeem = async () => {
    try {
      const response = await downloadDataRedeem();
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `data redeem ${moment().format("LL")}`;
      downloadLink.click();
    } catch (error) {}
  };
  const handleDownloadDataCompletion = async () => {
    try {
      const response = await downloadDataCompletion();
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `data completion ${moment().format("LL")}`;
      downloadLink.click();
    } catch (error) {}
  };

  const buttonDisplay = useMemo(() => {
    switch (selectedTab) {
      case 0:
        return (
          <div className={Styles.buttonWrapper}>
            <Button
              variant="outlined"
              text="Import Data"
              onClick={() => setOpenUploadModal(true)}
            />
            <Button
              text="Download Redemption"
              onClick={() => handleDownloadDataRedeem()}
            />
            <div className={Styles.search}>
              <Searchbar
                value={search}
                setValue={setSearch}
                placeholder="Cari Nama"
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div className={Styles.buttonWrapper}>
            <Button
              text="Download Completion"
              onClick={() => handleDownloadDataCompletion()}
            />
            <div className={Styles.search}>
              <Searchbar
                value={search}
                setValue={setSearch}
                placeholder="Cari Nama"
              />
            </div>
          </div>
        );

      default:
        return (
          <div className={Styles.buttonWrapper}>
            <Button text="Tandai Report" />
            <Button
              variant="outlined"
              text="Import Data"
              onClick={() => setOpenUploadModal(true)}
            />
            <div className={Styles.search}>
              <Searchbar
                value={search}
                setValue={setSearch}
                placeholder="Cari Nama"
              />
            </div>
          </div>
        );
    }
  }, [search, selectedTab]);

  const handleUploadData = async (file) => {
    if (selectedTab === 0) {
      try {
        const res = await updateDataRedeem(file);
        if (res.status) {
          setOpenUploadModal(false);
          setSuccessModal(true);
          navigate("", { replace: true });
        }
      } catch (error) {}
    } else {
      try {
        const res = await updateDataReport(file);
        if (res.status) {
          setOpenUploadModal(false);
          setSuccessModal(true);
          navigate("", { replace: true });
        }
      } catch (error) {}
    }
  };

  return (
    <div className={Styles.main}>
      <div className={Styles.container}>
        <div className={Styles.headSection}>
          <DoodleGradientCard>
            <div className={Styles.doodleCard}>
              <h2>
                Selamat Datang <span className={Styles.span}>{user?.name}</span>
              </h2>
              <p>
                Silahkan <span className={Styles.span}>generate</span> kode
                voucher untuk setiap kelas di halaman ini
              </p>
            </div>
          </DoodleGradientCard>
        </div>
        <div className={Styles.controlSection}>
          <div className={Styles.tabWrapper}>
            {tab.map((el, idx) => (
              <Button
                variant="tab"
                text={el}
                isActive={selectedTab === idx}
                onClick={() => setSelectedTab(idx)}
                key={idx}
              />
            ))}
          </div>
          <div className={Styles.dateWrapper}>
            <Input
              isDate
              value={startDate}
              setValue={(val) => setStartDate(val)}
            />
            <p>To</p>
            <Input isDate value={endDate} setValue={(val) => setEndDate(val)} />
          </div>
        </div>
        <div className={Styles.bodySection}>
          {buttonDisplay}
          <div>
            <Table
              className={Styles.tableRadius}
              data={template.data}
              columns={selectedTab === 2 ? template.columns1 : template.columns}
              page={page}
              limit={limit}
              totalData={data.totalData}
              totalPage={Math.ceil(data.totalData / limit)}
              setLimit={setLimit}
              setPage={setPage}
              withPagination
            />
          </div>
        </div>
        <Modal isOpen={openUploadModal}>
          <InputFileModal
            onClose={() => setOpenUploadModal(false)}
            handleSubmit={(file) => handleUploadData(file)}
          />
        </Modal>
        <Modal isOpen={successModal}>
          <FileUploadSuccess
            headText="Tandai Report Berhasil"
            onClose={() => setSuccessModal(false)}
            text={"Status telah berhasil diubah"}
          />
        </Modal>
      </div>
      <Footer />
    </div>
  );
}
