import React, { useCallback, useEffect, useMemo } from "react";
import Styles from "./style.module.scss";
import Searchbar from "@Atom/Searchbar";
import { useState } from "react";
import Table from "@Molecule/Table";
// import useWindowSize from "@Hooks/useWindowSize";
import { getClassAdminParticipants } from "@Services/admin";
import { useNavigate, useParams } from "react-router-dom";
import useWindowSize from "@Hooks/useWindowSize";

export default function StudentListAdmin() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { id: courseID } = useParams();
  const { width } = useWindowSize();
  const fetchDataParticipants = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getClassAdminParticipants(courseID, limit, page);
      setData(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [courseID, limit, page]);
  useEffect(() => {
    fetchDataParticipants();
  }, [fetchDataParticipants]);
  useEffect(() => {}, []);
  const navigate = useNavigate();
  const template = useMemo(() => {
    return {
      data: data?.data?.map((el) => ({
        ...el,
        id: el?.userID,
        name: el?.name,
        email: el?.email,
        progress: el?.progress,
        uk: el?.uk,
        postTest: el?.postTest,
        preTest: el?.preTest,
        voucher: el?.voucherCode,
        tpm1: el?.tpm[0],
        tpm2: el?.tpm[1],
        tpm3: el?.tpm[2],
        tpm4: el?.tpm[3],
        tpm5: el?.tpm[4],
      })),
      columns: [
        {
          name: "",
          title: "No",
          isNumber: true,
        },
        {
          name: "",
          title: "Nama Peserta",
          renderData: (row) => (
            <div
              className={Styles.nameAndEmail}
              style={width > 768 ? { width: "257px" } : { undefined }}
              onClick={() => navigate(`student/${row?.userID}`, { state: row })}
            >
              <span>{row?.name}</span>
              <span>{row?.email}</span>
            </div>
          ),
        },
        {
          name: "voucher",
          title: "Kode Voucher",
          hideOnMobile: true,
        },
        {
          name: "",
          title: "Progress",
          hideOnMobile: true,
          renderData: (row) => <span>{Math.trunc(row.progress)}%</span>,
        },
        {
          name: "preTest",
          hideOnMobile: true,
          title: "Pretest",
          renderData: (row) => (
            <span
              className={Styles.withBadge}
              // is-done={row?.preTest !== -1  ? "true" : "false"}
              style={{
                background:
                  row?.preTest < 0
                    ? "#9E9E9E"
                    : row?.preTest === 0
                    ? "#D42801"
                    : "#60AA15",
              }}
            >
              {row.preTest <= 0 ? "" : row.preTest}
            </span>
          ),
        },
        {
          name: "tpm1",
          title: "TPM 1",
          hideOnMobile: true,
          renderData: (row) => (
            <span
              className={Styles.withBadge}
              style={{
                background:
                  row?.tpm1?.score === -1 || row?.tpm1 === null
                    ? "#9E9E9E" //grey
                    : row?.tpm1?.score === 0
                    ? "#D42801" // red
                    : "#60AA15", // green
              }}
            >
              {row.tpm1?.score === -1 ||
              row?.tpm1 === null ||
              row?.tpm1?.score === 0
                ? ""
                : row.tpm1?.score}
            </span>
          ),
        },
        {
          name: "",
          title: "TPM 2",
          hideOnMobile: true,
          renderData: (row) => (
            <span
              className={Styles.withBadge}
              style={{
                background:
                  row?.tpm2?.score === -1 || row?.tpm2 === null
                    ? "#9E9E9E"
                    : row?.tpm2?.score === 0
                    ? "#D42801"
                    : "#60AA15",
              }}
            >
              {row.tpm2?.score === -1 ||
              row?.tpm2 === null ||
              row?.tpm2?.score === 0
                ? ""
                : row.tpm2?.score}
            </span>
          ),
        },
        {
          name: "",
          title: "TPM 3",
          hideOnMobile: true,
          renderData: (row) => (
            <span
              className={Styles.withBadge}
              style={{
                background:
                  row?.tpm3?.score === -1 || row?.tpm3 === null
                    ? "#9E9E9E"
                    : row?.tpm3?.score === 0
                    ? "#D42801"
                    : "#60AA15",
              }}
            >
              {row.tpm3?.score === -1 ||
              row?.tpm3 === null ||
              row?.tpm3?.score === 0
                ? ""
                : row.tpm3?.score}
            </span>
          ),
        },
        {
          name: "",
          title: "TPM 4",
          hideOnMobile: true,
          renderData: (row) => (
            <span
              className={Styles.withBadge}
              style={{
                background:
                  row?.tpm4?.score === -1 || row?.tpm4 === null
                    ? "#9E9E9E"
                    : row?.tpm4?.score === 0
                    ? "#D42801"
                    : "#60AA15",
              }}
            >
              {row.tpm4?.score === -1 ||
              row?.tpm4 === null ||
              row?.tpm4?.score === 0
                ? ""
                : row.tpm4?.score}
            </span>
          ),
        },
        {
          name: "",
          title: "TPM 5",
          hideOnMobile: true,
          renderData: (row) => (
            <span
              className={Styles.withBadge}
              style={{
                background:
                  row?.tpm5?.score === -1 || row?.tpm5 === null
                    ? "#9E9E9E"
                    : row?.tpm5?.score === 0
                    ? "#D42801"
                    : "#60AA15",
              }}
            >
              {row.tpm5?.score === -1 ||
              row?.tpm5 === null ||
              row?.tpm1?.score === 0
                ? ""
                : row.tpm5?.score}
            </span>
          ),
        },
        {
          name: "postTest",
          title: "Post Test",
          renderData: (row) => (
            <span
              className={Styles.withBadge}
              // is-done={row?.postTest !== -1  ? "true" : "false"}
              style={{
                background:
                  row?.postTest < 0 || row?.postTest === null
                    ? "#9E9E9E"
                    : row?.postTest === 0
                    ? "#D42801"
                    : "#60AA15",
              }}
            >
              {row.postTest <= 0 ? "" : row.postTest}
            </span>
          ),
          hideOnMobile: true,
        },
        {
          name: "",
          title: "UK",
          hideOnMobile: true,
          renderData: (row) => (
            <span
              className={Styles.withBadge}
              // is-done={row?.uk !== -1 ? "true" : "false"}
              style={{
                background:
                  row?.uk < 0
                    ? "#9E9E9E"
                    : row?.uk === 0
                    ? "#D42801"
                    : "#60AA15",
              }}
            >
              {row?.uk >= 0 ? Math.trunc(row?.uk) : ""}
            </span>
          ),
        },
      ],
    };
  }, [data, navigate, width]);

  return (
    <div className={Styles.container}>
      <Searchbar />
      <div className={Styles.tableWrapper}>
        <Table
          data={template?.data}
          totalData={data?.totalData}
          totalPage={Math.ceil(data?.totalData / limit) || 0}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table}`}
          limit={limit}
          setLimit={setLimit}
          withPagination
          isLoading={loading}
          isSticky={true}
          expandComponent={(row) => (
            <div className={Styles.expandedInfo}>
              <div className={Styles.infoRow}>
                <span>Nama Peserta</span>
                <span>{row?.name}</span>
              </div>
              <div className={Styles.infoRow}>
                <span>Kode Voucher</span>
                <span>{row?.voucher}</span>
              </div>
              <div className={Styles.infoRow}>
                <span>Progress</span>
                <span>{Math.trunc(row?.progress)}%</span>
              </div>
              <div className={Styles.infoRow}>
                <span>Pretest</span>
                <span
                  className={Styles.infoRowValue}
                  is-done={row?.preTest !== -1 ? "true" : "false"}
                >
                  {row?.preTest === -1 ? "" : row?.preTest}
                </span>
              </div>
              <div className={Styles.infoRow}>
                <span>TPM 1</span>
                <span
                  className={Styles.infoRowValue}
                  style={{
                    background:
                      row?.tpm1 === null
                        ? "#9E9E9E"
                        : row?.tpm1 === -1
                        ? "#D42801"
                        : "#60AA15",
                  }}
                >
                  {row?.tpm1}
                </span>
              </div>
              <div className={Styles.infoRow}>
                <span>TPM 2</span>
                <span
                  className={Styles.infoRowValue}
                  style={{
                    background:
                      row?.tpm2 === null
                        ? "#9E9E9E"
                        : row?.tpm2 === -1
                        ? "#D42801"
                        : "#60AA15",
                  }}
                >
                  {row?.tpm2}
                </span>
              </div>
              <div className={Styles.infoRow}>
                <span>TPM 3</span>
                <span
                  className={Styles.infoRowValue}
                  style={{
                    background:
                      row?.tpm3 === null
                        ? "#9E9E9E"
                        : row?.tpm3 === -1
                        ? "#D42801"
                        : "#60AA15",
                  }}
                >
                  {row?.tpm3}
                </span>
              </div>
              <div className={Styles.infoRow}>
                <span>TPM 4</span>
                <span
                  className={Styles.infoRowValue}
                  style={{
                    background:
                      row?.tpm4 === null
                        ? "#9E9E9E"
                        : row?.tpm4 === -1
                        ? "#D42801"
                        : "#60AA15",
                  }}
                >
                  {row?.tpm4}
                </span>
              </div>
              <div className={Styles.infoRow}>
                <span>TPM 5</span>
                <span
                  className={Styles.infoRowValue}
                  style={{
                    background:
                      row?.tpm5 === null
                        ? "#9E9E9E"
                        : row?.tpm5 === -1
                        ? "#D42801"
                        : "#60AA15",
                  }}
                >
                  {row?.tpm5}
                </span>
              </div>
              <div className={Styles.infoRow}>
                <span>post Test</span>
                <span
                  className={Styles.infoRowValue}
                  is-done={row?.postTest !== -1 ? "true" : "false"}
                >
                  {row?.postTest === -1 ? "" : row?.postTest}
                </span>
              </div>
              <div className={Styles.infoRow}>
                <span>UK</span>
                <span
                  className={Styles.infoRowValue}
                  is-done={row?.uk !== -1 ? "true" : "false"}
                >
                  {row?.uk === -1 ? "" : row?.uk}
                </span>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
}
