import React from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Searchbar from "@Atom/Searchbar";
import Icon from "@Atom/Icon";
import { updateAttendance } from "@Services/admin";
import useQuery from "@Hooks/useQuery";
import TableEmptyHandler from "@Atom/TableEmptyHandler";

export default function AbsenceDetailAdmin({ data }) {
  // eslint-disable-next-line no-unused-vars
  const query = useQuery();
  const sessionNum = query.get("s");
  const batchNum = query.get("b");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleAbsence = async (data) => {
    const payload = {
      sessionNum: data.sessionNum,
      status: "OPEN",
    };
    try {
      const res = await updateAttendance(data.sheetID, payload);
      if (res.status) {
        navigate(`${pathname}?k=1&s=${sessionNum}&b=${batchNum}`, {
          replace: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={Styles.container}>
      <div>
        <Button
          variant="text"
          startIcon={"arrow-left-back"}
          text={"Kembali"}
          onClick={() =>
            navigate(`${pathname.split("/").slice(0, 4).join("/") + "?t=3"}`)
          }
        />
      </div>
      <div className={Styles.headText}>
        <p>Absensi Kelas</p>
        <div className={Styles.bulletPoint}>
          <p>•</p>
        </div>
        <p>Batch {batchNum}</p>
      </div>
      <div className={Styles.searchSection}>
        <Searchbar />
      </div>
      <div className={Styles.tableContainer}>
        <div className={Styles.tableHead}>
          <p>Nama Peserta</p>
        </div>
        <div className={Styles.tableBody}>
          {data.length < 1 ? (
            <TableEmptyHandler />
          ) : (
            data.map((el, idx) => (
              <div className={Styles.itemWrapper} key={idx}>
                <div className={Styles.item}>
                  <p>{el.name}</p>
                  {el.status === "OPEN" ? (
                    <div className={Styles.tableStatus}>
                      <p>Hadir</p>
                    </div>
                  ) : (
                    <div
                      className={Styles.tableButton}
                      onClick={() => handleAbsence(el)}
                    >
                      <Icon icon={"check-circle"} size={16} color={"#FFFF"} />
                      <p>Hadir</p>
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
