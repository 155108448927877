import { makeRandomString } from "@Helpers/makeRandomString";
import CurriculumCard from "@Molecule/CurriculumCard";
import GeneralInfoDetail from "@Molecule/GeneralInfoDetail";
import InputField from "@Molecule/InputField";
import { useMemo } from "react";
import Styles from "./style.module.scss";
import QuizCard from "@Atom/QuizCard";
import { priceFormat } from "@Helpers/priceFormat";
import ScheduleCard from "@Atom/ScheduleCard";

export default function ReviewSection({ summary, type }) {


  const template = useMemo(() => {
    return [
      {
        title: "Thumbnail dan Video Pengenalan Kelas",
        component: (
          <div className={`${Styles.thumbnail}`}>
            <InputField
              title={"Thumbnail"}
              value={summary?.thumbnail}
              placeholder="no file"
              isFile
              readOnly
            />
            <InputField
              title={"Video Pengenalan"}
              value={summary?.orientationVideo}
              placeholder="no file"
              // isFile
              readOnly
            />
          </div>
        ),
      },
      {
        title: "Informasi Umum",
        component: (
          <div className={`${Styles.generalInfo}`}>
            <GeneralInfoDetail
              title={"Judul Kelas"}
              description={summary?.generalInfo?.className || "-"}
            />
            <div className={Styles.grid}>
              <GeneralInfoDetail
                title={"Kategori Kelas"}
                description={summary?.generalInfo?.classCategory || "-"}
              />
              <GeneralInfoDetail
                title={"Jenis Kelas"}
                description={summary?.generalInfo?.classType || "-"}
              />
            </div>
            <div className={Styles.grid}>
              <GeneralInfoDetail
                title={"Jenis Peserta"}
                description={summary?.generalInfo?.participantType || "-"}
              />
              <GeneralInfoDetail
                title={"Level"}
                description={summary?.generalInfo?.level || "-"}
              />
            </div>
            <GeneralInfoDetail
              title={"Bahasa"}
              description={summary?.generalInfo?.language || "-"}
            />
            {summary?.generalInfo?.participantType === "PRAKERJA" && (
              <div className={Styles.aspectCompetency}>
                <p>Aspek Kompetensi</p>
                <div className={Styles.competencyWrapper}>
                  {summary?.competencyAspect.map((el, idx) => (
                    <div className={Styles.competency} key={idx}>
                      <p>
                        {" "}
                        {idx + 1}. {el.name}
                      </p>
                      <p>{el.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* <GeneralInfoDetail
              title={'Pembelajaran Utama'}
              description={summary?.generalInfo?.mainObjective || '-'}
            /> */}
            <GeneralInfoDetail
              title={"Deskripsi"}
              description={summary?.generalInfo?.description || "-"}
            />
            <GeneralInfoDetail
              title={"Tujuan Umum"}
              description={summary?.generalInfo?.generalPurpose || "-"}
            />
            <GeneralInfoDetail
              title={"Tujuan Khusus"}
              description={
                !summary?.generalInfo?.specificPurpose?.filter(
                  (obj) => obj?.value
                )?.length
                  ? "-"
                  : ""
              }
              list={summary?.generalInfo?.specificPurpose
                ?.filter((obj) => obj?.value)
                ?.map((obj) => obj?.value)}
            />
            <GeneralInfoDetail
              title={"Tools Pembelajaran"}
              description={!summary?.generalInfo?.tools?.length ? "-" : ""}
              list={summary?.generalInfo?.tools?.map((obj) => obj?.value)}
            />
          </div>
        ),
      },
      {
        title: "Jadwal Kursus",
        component: (
          <div className={`${Styles.schedules}`}>
            {summary?.schedules?.map((s, sI) => (
              <ScheduleCard
                key={sI}
                title={`Batch ${sI + 1}`}
                startDate={s?.startDate}
                endDate={s?.endDate}
                startTime={s?.startTime}
                endTime={s?.finishTime}
              />
            ))}
          </div>
        ),
      },
      {
        title: "Kurikulum",
        component: (
          <div className={`${Styles.sessions}`}>
            {summary?.sessions?.map((s, sI) => (
              <CurriculumCard
                cardTitle={`Sesi ${sI + 1}`}
                data={s}
                data1={{
                  title: "Introduction to UI/UX Design",
                  description: "Pengenalan UI/UX Design",
                  subjects: [
                    {
                      title: "What is UI/UX Design?",
                      files: [{ name: "file" }, { name: "file" }],
                    },
                  ],
                  quizes: [
                    {
                      id: makeRandomString(5),
                      title: "Quiz 1",
                      questions: [
                        {
                          id: makeRandomString(5),
                          type: "Pilihan ganda",
                          question: "question 1",
                          options: [
                            {
                              id: makeRandomString(5),
                              option: "option 1",
                              isCorrect: true,
                            },
                            {
                              id: makeRandomString(5),
                              option: "option 2",
                              isCorrect: true,
                            },
                            {
                              id: makeRandomString(5),
                              option: "option 3",
                              isCorrect: true,
                            },
                            {
                              id: makeRandomString(5),
                              option: "option 4",
                              isCorrect: true,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                }}
              />
            ))}
          </div>
        ),
      },
      {
        title: "Bank Soal",
        component: (
          <div className={`${Styles.questions}`}>
            <QuizCard data={{ questions: summary?.questions }} />
          </div>
        ),
      },
      {
        title: "Uji Keterampilan",
        component: (
          <div className={`${Styles.skillTest}`}>
            <InputField
              title={"Panduan Uji Keterampilan"}
              isFile
              value={summary?.skillTest?.guide}
              placeholder="no file"
              readOnly
            />
          </div>
        ),
      },
      {
        title: "Penetapan Harga",
        component: (
          <div className={`${Styles.price}`}>
            <div className={Styles.priceRow}>
              <span>Harga Kelas</span>
              <p>Rp{priceFormat(summary?.price || 0)}</p>
            </div>
            <div className={Styles.priceRow}>
              <span>Diskon Potongan</span>
              <p>-</p>
            </div>
            <div className={Styles.divider} />
            <div className={Styles.priceRow}>
              <span>Total</span>
              <p className={Styles.total}>
                Rp{priceFormat(summary?.price || 0)}
              </p>
            </div>
          </div>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    summary?.generalInfo?.classCategory,
    summary?.generalInfo?.className,
    summary?.generalInfo?.classType,
    summary?.generalInfo?.description,
    summary?.generalInfo?.generalPurpose,
    summary?.generalInfo?.language,
    summary?.generalInfo?.level,
    summary?.generalInfo?.mainObjective,
    summary?.generalInfo?.participantType,
    summary?.generalInfo?.specificPurpose,
    summary?.generalInfo?.tools,
    summary?.orientationVideo,
    summary?.price,
    summary?.questions,
    summary?.schedules,
    summary?.sessions,
    summary?.skillTest?.guide,
    summary?.thumbnail,
  ]);

  const templateToMap = useMemo(() => {
    if (type === "VIDEO") {
      return template?.filter((obj) => obj?.title !== "Jadwal Kursus");
    }
    return template;
  }, [template, type]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Review</h3>
        <span>
          Silahkan tinjau kembali kelas yang akan Anda buat, dan simpan bila
          Anda telah yakin semua telah diisi dengan benar.
        </span>
      </div>
      <div className={Styles.content}>
        {templateToMap?.map((t, tI) => (
          <div key={tI} className={Styles.section}>
            <h4>{t?.title}</h4>
            {t?.component}
          </div>
        ))}
      </div>
    </div>
  );
}
