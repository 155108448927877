import React from "react";
import Styles from "./style.module.scss";
import ImageGalleryPrakerja from "@Molecule/GalleryPrakerja";
import DescribePrakerja from "@Molecule/DescribePrakerja";
import TermConditionPrakerja from "@Molecule/TermConditionPrakerja";
import StagesPrakerja from "@Molecule/StagesPrakerja";
import BackgroundDoodle from "@Molecule/BackgroundDoodle";
import FindClassLandingPage from "@Molecule/FindClassLandingPage";
import PartnerPrakerjaCard from "@Molecule/PartnerPrakerjaCard";
import FooterLandingPage from "@Molecule/FooterLandingPage";
import ContactLandingPage from "@Molecule/ContactMeLandingPage";
export default function LayoutLandingPagePrakerja() {
  return (
    <div className={Styles.containerLandingPagePrakerja}>
      <ImageGalleryPrakerja />
      <DescribePrakerja />
      <BackgroundDoodle>
        <TermConditionPrakerja />
        <StagesPrakerja />
        <FindClassLandingPage />
      </BackgroundDoodle>
      <PartnerPrakerjaCard />
      <FooterLandingPage />
      <ContactLandingPage />
    </div>
  );
}
