import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import TextEditor from "@Atom/TextEditor";
import RadioTextArea from "@Molecule/RadioTextArea";
import { useMemo } from "react";
import style from "./style.module.scss";
export default function QuestionGenerator({
  withDeleteOption = false,
  onDelete = () => { },
  valueTextEditor = '',
  placeholderRadio,
  question = "Question",
  setValueTextEditor = () => { },
  options = [],
  setOptions = () => { },
  readOnly = false,
}) {

  const textEditor = useMemo(() => {
    return (
      <TextEditor
        type=""
        setValue={setValueTextEditor}
        value={valueTextEditor}
      />
    )
  }, [setValueTextEditor, valueTextEditor])

  return (
    <div>
      <div className={style.wrapperEditQuiz}>
        <div className={style.headerEditQuiz}>
          <Text color={"#404040"} width={"semibold"} size={"l"}>
            {question}
          </Text>
          <div className={style.spaceBetween}>
            {
              withDeleteOption
              &&
              <div>
                <Icon
                  onClick={onDelete}
                  icon={"delete-outline"}
                  color={"#D1421A"}
                  size={"22px"}
                />
              </div>

            }
          </div>
        </div>
        <div className={style.textEdit}>
          {!readOnly
          ?textEditor
        :
        <div dangerouslySetInnerHTML={{__html: valueTextEditor}}/>
        }
        </div>
        <div className={style.editQuestion}>
          {
            options?.map((o, oI) => (
              <RadioTextArea
                key={oI}
                readOnly={readOnly}
                onChange={(e) => {
                  const newOptions = options?.map(obj => {
                    if (obj?.id === o?.id) {
                      return {
                        ...obj,
                        option: e?.target?.value
                      }
                    } return obj
                  })
                  setOptions(newOptions)
                }}
                isSelected={o?.isCorrect}
                onClick={() => {
                  const newOptions = options?.map(obj => {
                    if (obj?.id === o?.id) {
                      return {
                        ...obj,
                        isCorrect: true
                      }
                    }
                    return {
                      ...obj,
                      isCorrect: false
                    }

                  })
                  setOptions(newOptions)

                }}
                placeholder={placeholderRadio}
                value={o?.option}
              />

            ))
          }
        </div>
      </div>
    </div>
  );
}
