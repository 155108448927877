import React from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import notFoundImage from "@Assets/Images/notFound.png";
export default function NotFound({ isStudent, onClick = () => {},title = "Sekarang kamu belum punya kelas aktif nih" }) {
  return (
    <div className={Styles.wrapperNotFound}>
      <section>
        <div className={Styles.relativeElement}>
          <img src={notFoundImage} alt="" />
        </div>
        <div>
          <h1>{title}</h1>
          <p>Ayo beli kelas di skills.id dan mulai belajar bersama kami!</p>
        </div>
        {isStudent && (
          <div className={Styles.button}>
            <Button text={"Cari Kelas"} onClick={onClick} />
          </div>
        )}
      </section>
    </div>
  );
}
