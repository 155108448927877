import Sidebar from "@Molecule/Sidebar";
import { Outlet, useLocation } from "react-router-dom";

export default function AdminDashboard() {
  const { pathname } = useLocation();

  if (pathname?.includes("/class/new")) {
    return <Outlet />;
  }
  return (
    <Sidebar type={"admin"}>
      <Outlet />
    </Sidebar>
  );
}
