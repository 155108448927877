import React, { useMemo, useState } from "react";
import Styles from "./style.module.scss";
import AccordionAdmin from "@Molecule/AccordionAdmin";
import { fileBaseUrl } from "@Config/index";
import Icon from "@Atom/Icon";
import moment from "moment";
import tokopediaIcon from "@Assets/Icon/tokopedia_icon.svg";
import bukalapakIcon from "@Assets/Icon/bukalapak_icon.svg";
import pintarIcon from "@Assets/Icon/pintar_icon.svg";
import Modal from "@Atom/Modal";
import InputField from "@Molecule/InputField";
import Button from "@Atom/Button";
import { createCourseCode, updateCourseCode } from "@Services/admin";
import { useNavigate } from "react-router-dom";

export default function AdminDetailClass({ data }) {
  const navigate = useNavigate();
  const classDetailList = [
    {
      label: "Jenis Peserta",
      value: data?.type,
    },
    {
      label: "Jenis Kelas",
      value: data?.platform,
    },
    {
      label: "Kategori",
      value: data?.category,
    },
    {
      label: "Tanggal Pembuatan Kelas",
      value: moment(data?.updatedAt).format("Do MMM YYYY, HH:MM"),
    },
    {
      label: "Harga Kelas",
      value: data?.price.total.toLocaleString("id"),
    },
    {
      label: "Diskon",
      value: data?.price.discount + "%",
    },
  ];

  const productVendor = useMemo(() => {
    return [
      {
        partnerName: "TOKOPEDIA",
        code:
          data?.partners.find((el) => el.partnerName === "TOKOPEDIA")?.code ||
          "-",
        icon: tokopediaIcon,
        id:
          data?.partners.find((el) => el.partnerName === "TOKOPEDIA")?._id ||
          "",
      },
      {
        partnerName: "BUKALAPAK",
        code:
          data?.partners.find((el) => el.partnerName === "BUKALAPAK")?.code ||
          "-",
        icon: bukalapakIcon,
        id:
          data?.partners.find((el) => el.partnerName === "BUKALAPAK")?._id ||
          "",
      },
      {
        partnerName: "PINTAR",
        code:
          data?.partners.find((el) => el.partnerName === "PINTAR")?.code || "-",
        icon: pintarIcon,
        id: data?.partners.find((el) => el.partnerName === "PINTAR")?._id || "",
      },
    ];
  }, [data]);

  const [openModalPartner, setOpenModalPartner] = useState(null);
  const [classCode, setClassCode] = useState("");

  const handleSaveProductCode = async () => {
    try {
      if (openModalPartner.id) {
        const res = await updateCourseCode(data._id, openModalPartner.id, {
          courseCode: classCode,
        });
        if (res.status) {
          navigate("", { replace: true });
          setOpenModalPartner(null);
          setClassCode("");
        }
      } else {
        const res = await createCourseCode(data._id, {
          code: classCode,
          partnerName: openModalPartner.partnerName,
        });
        if (res.status) {
          navigate("", { replace: true });
          setOpenModalPartner(null);
          setClassCode("");
        }
      }
    } catch (error) {}
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.classHeader}>
        <div className={Styles.thumbnail}>
          <img
            src={
              data?.asset.thumbnail.imageURL.includes("https")
                ? data?.asset.thumbnail.imageURL
                : fileBaseUrl + data?.asset.thumbnail.imageURL
            }
            alt="thumbnail"
          />
        </div>
        <div className={Styles.classDetailCard}>
          <div className={Styles.cardHead}>
            <div className={Styles.title}>
              <p>{data?.title}</p>
              <div>
                {data?.instructor.profilePicURL && (
                  <img
                    src={fileBaseUrl + data?.instructor.profilePicURL}
                    alt="avatar"
                  />
                )}
                <p>{data?.instructor.name}</p>
              </div>
            </div>
            <div className={Styles.settings}>
              <div>
                <div className={Styles.status}>
                <p> {data.isPublished ? "Published" : "Draft"}</p>
                </div>
              </div>
              <Icon
                icon={"setting-21"}
                size={20}
                onClick={() =>
                  navigate(
                    `/classes/edit/${data?.platform.toLowerCase()}/${data?._id}`
                  )
                }
              />
            </div>
          </div>
          <div className={Styles.divider} />
          <div className={Styles.cardBody}>
            {classDetailList.slice(0, 3).map((el, idx) => (
              <div className={Styles.label} key={idx}>
                <p>{el.label}</p>
                <p>{el.value}</p>
              </div>
            ))}
          </div>
          <div className={Styles.cardBody}>
            {classDetailList.slice(3).map((el, idx) => (
              <div className={Styles.label} key={idx}>
                <p>{el.label}</p>
                <p>{el.value}</p>
              </div>
            ))}
          </div>
          <div className={Styles.divider} />
          <div className={Styles.productCodeWrapper}>
            {productVendor.map((el, idx) => (
              <div className={Styles.productCode} key={idx}>
                <div className={Styles.leftSection}>
                  <div>
                    <p>Kode Kelas</p>
                    <img alt="logo" src={el.icon} />
                  </div>
                  <p>{el.code}</p>
                </div>
                <Icon
                  icon={"pencil-square"}
                  size={16}
                  color="#C2C2C2"
                  onClick={() => {
                    setOpenModalPartner(el);
                    setClassCode(el.id ? el.code : "");
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={Styles.scheduleSection}>
        <p>Jadwal Kelas</p>
        <div className={Styles.accordionList}>
          {data?.batches.map((el, idx) => (
            <div key={idx}>
              <AccordionAdmin
                head={
                  <div className={Styles.accordionHead}>
                    <div>
                      <Icon icon={"calendar2"} size={20} color={"#9360A8"} />
                    </div>
                    <p>
                      {moment(el.startDate).format("DD")} -{" "}
                      {moment(el.endDate).format("DD MMM YYYY")}
                    </p>
                  </div>
                }
                body={
                  <div className={Styles.scheduleBody}>
                    {el.sessions.map((session, idxSession) => (
                      <div className={Styles.scheduleCard} key={idxSession}>
                        <div className={Styles.cardHead}>
                          <p>Sesi {idxSession + 1}</p>
                        </div>
                        <div className={Styles.cardBody}>
                          <div>
                            <p>{moment(session.date).format("dddd")}</p>
                            <p>{moment(session.date).format("DD MMMM YYYY")}</p>
                          </div>
                          <div className={Styles.divider} />
                          <div>
                            <Icon icon={"clock"} size={16} />
                            <p>
                              {session.startHour} - {session.endHour} WIB
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div className={Styles.curriculumSection}>
        <p>Kurikulum</p>
        <div className={Styles.accordionList}>
          {data?.sessions.map((el, idx) => (
            <div key={idx}>
              <AccordionAdmin
                head={
                  <div className={Styles.accordionHead}>
                    <div>
                      <Icon icon={"book2"} size={20} color={"#9360A8"} />
                    </div>
                    <p>Sesi {idx + 1}</p>
                  </div>
                }
                body={
                  <div className={Styles.sessionBody} key={idx}>
                    <div className={Styles.divider} />
                    <div className={Styles.sessionContainer}>
                      <div className={Styles.sessionDesc}>
                        <div className={Styles.labelDesc}>
                          <p>Judul Sesi</p>
                          <p>:</p>
                          <p>{el.title}</p>
                        </div>
                        <div className={Styles.labelDesc}>
                          <p>Deskripsi</p>
                          <p>:</p>
                          <p>{el.description}</p>
                        </div>
                      </div>
                      {el.subjects.map(
                        (subject, subIdx) =>
                          subject.type !== "TASK" && (
                            <div key={subIdx}>
                              <div className={Styles.divider} />
                              <div className={Styles.subject}>
                                <p>
                                  {subject.type === "MATTER"
                                    ? "Materi"
                                    : "Kuis"}{" "}
                                  {subIdx + 1}
                                </p>
                                {subject.type === "MATTER" ? (
                                  <div className={Styles.matterDetail}>
                                    <div className={Styles.labelDesc}>
                                      <p>Judul Materi</p>
                                      <p>:</p>
                                      <p>{subject.title}</p>
                                    </div>
                                    <div className={Styles.labelDesc}>
                                      <p>File Materi {subIdx + 1}</p>
                                      <p>:</p>
                                      <div className={Styles.subjectFileDesc}>
                                        <Icon
                                          icon={"link-2"}
                                          size={20}
                                          color={"#9360A8"}
                                        />
                                        <div className={Styles.rightSection}>
                                          <p>Link Video</p>
                                          <p
                                            onClick={() =>
                                              window.open(
                                                subject?.content?.uri?.includes(
                                                  "https"
                                                )
                                                  ? subject.content.uri
                                                  : fileBaseUrl +
                                                      subject.content.uri
                                              )
                                            }
                                            className={Styles.link}
                                          >
                                            {subject?.content?.uri?.includes(
                                              "https"
                                            )
                                              ? subject.content.uri
                                              : fileBaseUrl +
                                                subject.content.uri}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    {subject?.quizzes.length > 0 && (
                                      <div className={Styles.labelDesc}>
                                        <p>Pop Up Kuis</p>
                                        <p>:</p>
                                        <div className={Styles.subjectFileDesc}>
                                          <Icon
                                            icon={"link-2"}
                                            size={20}
                                            color={"#9360A8"}
                                          />
                                          <div className={Styles.rightSection}>
                                            <p>Pertanyaan</p>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  subject?.quizzes[0]
                                                    ?.contentHTML,
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className={Styles.quizDetail}></div>
                                )}
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                }
              />
            </div>
          ))}
        </div>
      </div>
      <Modal isOpen={openModalPartner}>
        <div className={Styles.modalContainer}>
          <div className={Styles.modalHeader}>
            <p>Kode Kelas</p>
            <p>
              Silahkan masukkan kode kelas untuk vendor{" "}
              <span>{openModalPartner?.partnerName}</span>
            </p>
          </div>
          <div className={Styles.modalBody}>
            <InputField
              title="Kode Kelas"
              required
              placeholder="Kode Kelas"
              value={classCode}
              setValue={setClassCode}
            />
          </div>
          <div className={Styles.modalAction}>
            <Button
              text={"Kembali"}
              variant="outlined"
              onClick={() => setOpenModalPartner(null)}
            />
            <Button text={"Simpan"} onClick={() => handleSaveProductCode()} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
