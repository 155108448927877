import React, { useEffect, useMemo, useRef } from "react";
import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import { useNavigate, useParams } from "react-router-dom";
import { fileBaseUrl } from "@Config/index";
import { useState } from "react";
import RadioButton from "@Atom/RadioButton";
import moment from "moment";
import CalendarGradientIcon from "@Assets/Icon/calendarGradient.svg";
import Input from "@Atom/Input";
import { _checkoutClass } from "@Services/transaction";
import FooterLandingPage from "@Molecule/FooterLandingPage";
import useOutsideClick from "@Hooks/useOutsideClick";
import Images from "@Theme/Images";

export default function CheckoutCourseLayout({ data, isLoading }) {
  const navigate = useNavigate();
  const [selectedBatch, setSelectedBatch] = useState(null);
  const { id } = useParams();
  const boxRef = useRef();
  const boxOutsideClick = useOutsideClick(boxRef);
  const [detailModal, setDetailModal] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const filteredBatch = useMemo(() => {
    if (data) {
      return data?.batches.filter((el) => new Date(el.startDate) > new Date());
      // return []
    }
  }, [data]);

  useEffect(() => {
    if (boxOutsideClick) {
      setDetailModal(false);
    }
  }, [boxOutsideClick]);

  const [promo, setPromo] = useState("");
  if (isLoading) {
    return <p>loading...</p>;
  }

  // console.log(el.startDate < new Date());

  const handleCheckoutClass = async () => {
    if (!selectedBatch && data?.platform !== "VIDEO") {
      setErrorSubmit(true);
    } else {
      const payload = {
        classID: id,
        classSchedule: selectedBatch?.num || 1,
        method: "",
        promoCode: "",
      };
      try {
        setLoadingCheckout(true);
        const { data } = await _checkoutClass(payload);
        setLoadingCheckout(false);
        window.location.replace(data.payment.url);
      } catch (error) {
        setLoadingCheckout(false);
        console.log(error);
      }
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.backButton}>
        <Button
          variant="text"
          onClick={() => navigate(-1)}
          text="Kembali"
          startIcon={"chevron-left"}
        />
      </div>
      <div
        className={`${Styles.sectionWrapper} ${
          data?.platform === "VIDEO" ? Styles.videoSection : ""
        }`}
      >
        <div className={Styles.leftSection}>
          <div className={Styles.headerSection}>
            <p>Pembelian Kelas</p>
            <div className={Styles.classThumbnail}>
              <div className={Styles.thumbnailImage}>
                <img
                  src={
                    data.asset.thumbnail.imageURL.includes("https")
                      ? data.asset.thumbnail.imageURL
                      : fileBaseUrl + data.asset.thumbnail.imageURL
                  }
                  alt=""
                />
              </div>
              <div className={Styles.classDesc}>
                <p>{data.title}</p>
                <p>Oleh {data?.instructor?.name}</p>
                <div className={Styles.pricing}>
                  {data.price.discount ? (
                    <React.Fragment>
                      <div className={Styles.discount}>
                        <p>{data.price.discount}%</p>
                      </div>
                      <p className={Styles.basePrice}>
                        Rp{data.price.base.toLocaleString("id")}
                      </p>
                      <p className={Styles.totalPrice}>
                        Rp{data.price.total.toLocaleString("id")}
                      </p>
                    </React.Fragment>
                  ) : (
                    <p className={Styles.totalPrice}>
                      {data.price.total.toLocaleString("id")}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {data.platform !== "VIDEO" && (
            <div className={Styles.batchSection}>
              <p>Jadwal Kelas</p>
              <div className={Styles.batchWrapper}>
                {filteredBatch.map((el, idx) => (
                  <div
                    className={Styles.batch}
                    key={idx}
                    onClick={() => el.quota !== "0" && setSelectedBatch(el)}
                    is-selected={String(el.num === selectedBatch?.num)}
                    is-disabled={String(el.quota === "0")}
                  >
                    <div className={Styles.date}>
                      <RadioButton
                        isSelected={el.num === selectedBatch?.num || false}
                        onClick={() => el.quota !== "0" && setSelectedBatch(el)}
                      />
                      {el.num === selectedBatch?.num ? (
                        <img src={CalendarGradientIcon} alt="" />
                      ) : (
                        <Icon
                          icon={"calendar-fill"}
                          size={16}
                          color={el.quota !== "0" ? "#757575" : "#C2C2C2"}
                        />
                      )}
                      <div className={Styles.batchDateWrapper}>
                        <p>
                          {moment(el.startDate).format("DD MMMM")} -{" "}
                          {moment(el.endDate).format("DD MMMM YYYY")}
                        </p>
                        {el.quota === "0" ? (
                          <p className={Styles.quota}>Kuota Kelas Habis</p>
                        ) : (
                          el.quota &&
                          el.quota !== "-" && (
                            <p className={Styles.quota}>
                              {"Tersisa " + el.quota + " kuota"}
                            </p>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {filteredBatch.length < 1 && (
                  <div className={Styles.noBatch}>
                    <img src={Images.NO_BATCH} alt="" />
                    <p>
                      Untuk saat ini tidak ada jadwal kelas tersedia, silahkan
                      cek secara berkala untuk melihat jadwal kelas
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={Styles.rightSection}>
          <div className={Styles.cardWrapper}>
            <div className={Styles.promoSection}>
              <div className={Styles.cardHeader}>
                <p>Kode Promosi</p>
                <p>
                  Silahkan masukkan voucher promosi bila anda memiliki kode
                  promo
                </p>
              </div>
              <div className={Styles.cardInput}>
                <Input
                  placeholder="Kode Promosi"
                  value={promo}
                  setValue={(val) => setPromo(val)}
                />
              </div>
              <div className={Styles.inputButton}>
                <Button text="Submit" variant="outlined" />
              </div>
              <div className={Styles.cardFooter}>
                <p>Atau gunakan kode voucher promosi yang tersedia</p>
              </div>
            </div>
            <div className={Styles.checkoutSection}>
              <div className={Styles.cardHeader}>
                <p>Detail</p>
              </div>
              <div className={Styles.cardBody}>
                <div className={Styles.priceDetail}>
                  <p>{data.title}</p>
                  <p>Rp{data.price.base.toLocaleString("id")}</p>
                </div>
                <div className={Styles.priceDetail}>
                  <p>Potongan harga ({data.price.discount}%)</p>
                  <p>-Rp{data.price.base * (data.price.discount / 100)}</p>
                </div>
                <div className={Styles.divider} />
                <div className={Styles.priceDetail}>
                  <p>Subtotal</p>
                  <p>Rp{data.price.total.toLocaleString("id")}</p>
                </div>
              </div>
              <div className={Styles.cardAction}>
                <Button
                  text={loadingCheckout ? "Loading..." : "Checkout"}
                  disabled={loadingCheckout}
                  onClick={() => handleCheckoutClass()}
                  endIcon={"arrow-up-right"}
                  id="doku-checkout-button"
                />
              </div>
              {errorSubmit && (
                <div className={Styles.errorMessage}>
                  <Icon icon={"warning-2"} size={24} color={"#B22A12"} />
                  <p>
                    Silahkan pilih jadwal kelas terlebih dahulu untuk
                    melanjutkan
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.modalContainer} is-open={String(detailModal)}>
        <div className={Styles.modalBody} is-open={String(detailModal)}>
          <div className={Styles.line} />
          <div className={Styles.modalContent}>
            <div className={Styles.cardHead}>
              <p>Detail</p>
              <Icon
                icon="cross"
                size={28}
                onClick={() => setDetailModal(false)}
              />
            </div>
            <div className={Styles.cardBody}>
              <div className={Styles.priceDetail}>
                <p>{data.title}</p>
                <p>Rp{data.price.base.toLocaleString("id")}</p>
              </div>
              <div className={Styles.priceDetail}>
                <p>Potongan harga ({data.price.discount}%)</p>
                <p>-Rp{data.price.base * (data.price.discount / 100)}</p>
              </div>
              <div className={Styles.divider} />
              <div className={Styles.priceDetail}>
                <p>Subtotal</p>
                <p>Rp{data.price.total.toLocaleString("id")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterLandingPage />
      <div className={Styles.bottomNav} ref={boxRef}>
        <div>
          <div>
            <p>Subtotal</p>
            <p>{data.price.total.toLocaleString("id")}</p>
          </div>
          <p onClick={() => setDetailModal(true)}>Detail</p>
        </div>
        <Button
          text={loadingCheckout ? "Loading..." : "Checkout"}
          disabled={loadingCheckout}
          onClick={() => handleCheckoutClass()}
          endIcon={"arrow-up-right"}
          id="doku-checkout-button"
        />
      </div>
    </div>
  );
}
