import React from "react";
import Styles from "./style.module.scss";
import phoneMockup from "@Assets/Images/landingPageMockUp.png";
import playButton from "@Assets/Images/playButtonLandingPage.png";

export default function PhoneMockup() {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.relative}>
        <img src={phoneMockup} alt="phone-mockup" />
        <img
          src={playButton}
          alt="play-button"
          className={Styles.playButtonClick}
          onClick={() =>
            window.open(
              "https://www.youtube.com/watch?v=409TByj0Q8M"
            )
          }
        />
      </div>
    </div>
  );
}
