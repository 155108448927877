import { API } from "@Config/index";

export function postRating(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post("/ratings", payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
