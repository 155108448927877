import React from "react";
import Styles from "./style.module.scss";
export default function StagesPrakerja() {
  return (
    <section className={Styles.containerStagesPrakerja}>
      <div className={Styles.wrapperStagesPrakerja}>
        <div className={Styles.leftText}>
          <div>
            Ingin Mengikuti <span>Program Prakerja?</span>
          </div>
          <p>Ikuti langkah berikut ini</p>
        </div>
        <div className={Styles.rightText}>
          <div>
            <div className={Styles.lineTopGradient}></div>
            <h1>Pendaftaran</h1>
            <p>
              Masuk ke situs Prakerja.go.id dan buat akun dengan data diri Anda.
              Tunggu verifikasi pendaftaran yang telah dikirimkan via email.
            </p>
          </div>
          <div>
            <div className={Styles.lineTopGradient}></div>
            <h1>Seleksi</h1>
            <p>
              Ikuti tes motivasi & kemampuan dasar untuk bisa mengikuti seleksi
              gelombang dan tunggu pengumuman hasilnya. Masuk ke situs
              Prakerja.go.id dan buat akun dengan data diri Anda. Tunggu
              verifikasi pendaftaran yang telah dikirimkan via email.
            </p>
          </div>
          <div>
            <div className={Styles.lineTopGradient}></div>
            <h1>Pilih Pelatihan</h1>
            <p>
              Pilih pelatihan di website Skills.id dan pilih kelas berlabel
              Prakerja yang diinginkan, dan klik Beli Kelas.
            </p>
          </div>

          <div>
            <div className={Styles.lineTopGradient}></div>
            <h1>Ikuti Pelatihan</h1>
            <p>
              Kembali ke Skills.id lalu masukkan tukarkan kode voucher Anda.
            </p>
          </div>
          <div>
            <div className={Styles.lineTopGradient}></div>
            <h1>Beri Ulasan dan Rating</h1>
            <p>
              Berikan ulasan dan rating terhadap pelatihan Anda di dashboard
              Prakerja anda.
            </p>
          </div>
          <div>
            <div className={Styles.lineTopGradient}></div>
            <h1>Insentif Pengisian Survei Evaluasi</h1>
            <p>
              Isi 3 survey yang diberikan pasca pelatihan dan dapatkan insentif
              Rp 50 ribu untuk setiap survey.
            </p>
          </div>
          <div>
            <div className={Styles.lineTopGradient}></div>
            <h1>Insentif Biaya Mencari Kerja</h1>
            <p>
              Dapatkan insentif Rp 600 ribu/bulan selama 4 bulan setelah
              menyelesaikan pelatihan.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
