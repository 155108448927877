import Button from "@Atom/Button";
import DropdownTextArea from "@Atom/DropdownTextArea";
import Input from "@Atom/Input";
import { makeRandomString } from "@Helpers/makeRandomString";
import CheckboxOption from "@Molecule/CheckboxOption";
import InputField from "@Molecule/InputField";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import Modal from "@Atom/Modal";
import ModalSelectCertificate from "@Molecule/ModalSelectCertificate";
import { fetchMasterDataCertificate } from "@Services/admin";
import Switch from "@Atom/Switch";
import { capitalizeWords } from "@Helpers/capitallizeWords";
import { fileBaseUrl } from "@Config/index";

export default function DetailsSection({
  form,
  setForm,
  instructors,
  isLoadingSync,
  certificate,
  setCertificate,
  type,
}) {
  const { pathname } = useLocation();

  const handleChangeForm = useCallback(
    (name, newVal, childName, id) => {
      if (!childName) {
        setForm((prev) => {
          return {
            ...prev,
            [name]: newVal,
          };
        });
      } else {
        setForm((prev) => {
          return {
            ...prev,
            [name]: prev[name]?.map((obj) => {
              if (obj?.id === id) {
                return {
                  ...obj,
                  [childName]: newVal,
                };
              } else {
                return obj;
              }
            }),
          };
        });
      }
    },
    [setForm]
  );

  useEffect(() => {
    handleChangeForm(
      "instructorID",
      instructors?.find((obj) => obj?.name === form?.instructorName)?._id || ""
    );
  }, [form?.instructorName, handleChangeForm, instructors]);

  const handleSwitchCertificate = () => {
    if (certificate.isAllCertificate) {
      setCertificate({
        isAllCertificate: false,
        data: [
          {
            name: "",
            file: "",
            _id: "1",
            vendor: "BUKALAPAK",
          },
          {
            name: "",
            file: "",
            _id: "2",
            vendor: "TOKOPEDIA",
          },
          {
            name: "",
            file: "",
            _id: "3",
            vendor: "PINTAR",
          },
        ],
      });
    } else {
      setCertificate({
        isAllCertificate: true,
        data: [
          {
            name: "",
            file: "",
            _id: "1",
            vendor: "ALL",
          },
        ],
      });
    }
  };

  const handleChangeCertificate = (val) => {
    setCertificate((prev) => ({
      ...prev,
      data: prev.data.map((el) =>
        el._id === modalSelectCertificate ? { ...el, ...val } : el
      ),
    }));
  };

  const options = useMemo(() => {
    return {
      categories: [
        "Sales",
        "IT/Software",
        "Jurnalistik",
        "Manajemen",
        "Bisnis",
        "Communication",
        "Design",
        "Language",
        "Seni/Kerajinan",
        "Lifestyle",
        "Marketing",
        "SDM",
      ],
      languages: ["BAHASA", "ENGLISH"],
      participantTypes: ["GENERAL", "PRAKERJA"],
      levels: ["BEGINNER", "INTERMEDIATE", "EXPERT"],
      competencyAspects: ["KOGNITIF", "AFEKTIF", "PSIKOMOTOR"],
    };
  }, []);

  const [modalSelectCertificate, setModalSelectCertificate] = useState(false);

  const template = useMemo(() => {
    return [
      {
        title: "Thumbnail dan Video Pengenalan Kelas",
        component: (
          <div className={Styles.thumbnailsWrapper}>
            <InputField
              title={"Thumbnail"}
              value={form?.thumbnail}
              setValue={(newVal) => {
                handleChangeForm("thumbnail", newVal);
              }}
              placeholder="Pilih file untuk thumbnail kelas"
              isFile
              isImageOnly
              required
              speed={10}
            />
            <InputField
              title={"Video"}
              value={form?.video}
              setValue={(newVal) => handleChangeForm("video", newVal)}
              // placeholder='Pilih file untuk video pengenalan kelas'
              // isFile
              // isVideoOnly
              placeholder="Masukkan link video pengenalan kelas"
              isLink
              required
              speed={10}
            />
          </div>
        ),
      },
      {
        title: "Informasi Umum",
        component: (
          <div className={Styles.infoWrapper}>
            <InputField
              title={"Judul Kelas"}
              value={form?.name}
              setValue={(newVal) => handleChangeForm("name", newVal)}
              placeholder="Judul kelas"
              required
            />
            <div className={Styles.grid}>
              <InputField
                title={"Kategori Kelas"}
                value={form?.category}
                setValue={(newVal) => handleChangeForm("category", newVal)}
                placeholder="Kategori kelas"
                isDropdown
                dropdownOptions={options?.categories}
                isLoadingDropdown={false}
                required
              />
              <InputField
                title={"Bahasa"}
                value={form?.language}
                setValue={(newVal) => handleChangeForm("language", newVal)}
                placeholder="Bahasa"
                isDropdown
                dropdownOptions={options?.languages}
                isLoadingDropdown={false}
                required
              />
            </div>
            <div className={Styles.grid}>
              <InputField
                title={"Jenis Peserta"}
                value={form?.participantType}
                setValue={(newVal) =>
                  handleChangeForm("participantType", newVal)
                }
                placeholder="Jenis peserta"
                isDropdown
                dropdownOptions={options?.participantTypes}
                isLoadingDropdown={false}
                required
              />
              <InputField
                title={"Level"}
                value={form?.level}
                setValue={(newVal) => handleChangeForm("level", newVal)}
                placeholder="Level kelas"
                isDropdown
                dropdownOptions={options?.levels}
                isLoadingDropdown={false}
                required
              />
            </div>
            {pathname?.includes("admin") && (
              <InputField
                title={"Instruktur Kelas"}
                value={form?.instructorName}
                setValue={(newVal) =>
                  handleChangeForm("instructorName", newVal)
                }
                placeholder="Instruktur kelas"
                isDropdown
                dropdownOptions={instructors?.map((obj) => obj?.name)}
                isLoadingDropdown={isLoadingSync}
                required
              />
            )}

            {/* <InputField
              title={'Pembelajaran Utama'}
              value={form?.mainObjective}
              setValue={(newVal) => handleChangeForm('mainObjective', newVal)}
              placeholder='Pembelajaran utama kelas'
              required
            /> */}
            <InputField
              title={"Deskripsi"}
              value={form?.description}
              setValue={(newVal) => handleChangeForm("description", newVal)}
              placeholder="Deskripsi"
              isTextArea
              required
            />
            <InputField
              title={"Tujuan Umum"}
              value={form?.generalPurpose}
              setValue={(newVal) => handleChangeForm("generalPurpose", newVal)}
              placeholder="Tujuan umum"
              isTextArea
            />
            <div className={Styles.specificPurposeWrapper}>
              <InputField
                title={"Tujuan Khusus"}
                value={form?.specificPurpose[0]?.value}
                setValue={(newVal) =>
                  handleChangeForm(
                    "specificPurpose",
                    newVal,
                    "value",
                    form?.specificPurpose[0]?.id
                  )
                }
                placeholder="Tujuan khusus"
              />
              {form?.specificPurpose?.length - 1 > 0 &&
                form?.specificPurpose
                  ?.slice(1, form?.specificPurpose?.length)
                  ?.map((p, pI) => (
                    <Input
                      key={pI}
                      value={p?.value}
                      setValue={(newVal) =>
                        handleChangeForm(
                          "specificPurpose",
                          newVal,
                          "value",
                          p?.id
                        )
                      }
                      placeholder="Tujuan khusus"
                      withDeleteOption
                      onDelete={() => {
                        setForm((prev) => {
                          return {
                            ...prev,
                            specificPurpose: prev?.specificPurpose?.filter(
                              (obj) => obj?.id !== p?.id
                            ),
                          };
                        });
                      }}
                    />
                  ))}
              <Button
                text={"Tambah Tujuan Khusus"}
                variant="text"
                startIcon={"plus"}
                iconSize={"11px"}
                style={{ alignSelf: "flex-start", padding: "0px" }}
                onClick={() =>
                  setForm((prev) => {
                    return {
                      ...prev,
                      specificPurpose: [
                        ...prev?.specificPurpose,
                        {
                          id: makeRandomString(5),
                          value: "",
                        },
                      ],
                    };
                  })
                }
              />
            </div>

            <InputField
              title={"Alat Pembelajaran"}
              placeholder={"Alat pembelajaran"}
              value={form?.tools}
              setValue={(newValues) => {
                handleChangeForm("tools", newValues);
              }}
              isMultipleTag
            />
          </div>
        ),
      },
      {
        title: "Unit Kompetensi",
        component: (
          <div className={Styles.competencyWrapper}>
            <InputField
              title={"Standar Kompetensi Kerja Nasional Indonesia (SKKNI)"}
              value={form?.kkniTitle}
              setValue={(newVal) => handleChangeForm("kkniTitle", newVal)}
              placeholder="Standar Kompetensi Kerja Nasional Indonesia (SKKNI)"
              isTextArea
              required
            />
            <div className={Styles.competencyUnitsWrapper}>
              {form?.competencyUnits?.map((c, cI) => (
                <div key={cI} className={Styles.cUnit}>
                  <InputField
                    title={cI === 0 ? "Kode Unit" : ""}
                    value={c?.code}
                    setValue={(newVal) => {
                      handleChangeForm(
                        "competencyUnits",
                        newVal,
                        "code",
                        c?.id
                      );
                    }}
                    placeholder="Kode unit"
                    required
                  />
                  <InputField
                    title={cI === 0 ? "Judul Kompetensi" : ""}
                    value={c?.title}
                    setValue={(newVal) => {
                      handleChangeForm(
                        "competencyUnits",
                        newVal,
                        "title",
                        c?.id
                      );
                    }}
                    placeholder="Judul kompetensi"
                    withDeleteOption={cI !== 0 ? true : false}
                    onDelete={() => {
                      setForm((prev) => {
                        return {
                          ...prev,
                          competencyUnits: prev?.competencyUnits?.filter(
                            (obj) => obj?.id !== c?.id
                          ),
                        };
                      });
                    }}
                    required
                  />
                </div>
              ))}
            </div>
            <Button
              text={"Tambah Unit Kompetensi"}
              variant="text"
              startIcon={"plus"}
              iconSize={"11px"}
              style={{ alignSelf: "flex-start", padding: "0px" }}
              onClick={() =>
                setForm((prev) => {
                  return {
                    ...prev,
                    competencyUnits: [
                      ...prev?.competencyUnits,
                      {
                        id: makeRandomString(5),
                        code: "",
                        title: "",
                      },
                    ],
                  };
                })
              }
            />
            <div className={Styles.competencyAspectsWrapper}>
              {form?.competencyAspects?.map((a, aI) => (
                <DropdownTextArea
                  key={aI}
                  option={options?.competencyAspects}
                  inputValue={a?.description}
                  setInputValue={(newVal) => {
                    // setForm(prev => {
                    //   return {
                    //     ...prev,
                    //     competencyAspects: prev?.competencyAspects?.map(obj => {
                    //       if (obj?.id === a?.id) {
                    //         return {
                    //           ...obj,
                    //           description: newVal
                    //         }
                    //       } return obj
                    //     })
                    //   }
                    // })
                    handleChangeForm(
                      "competencyAspects",
                      newVal,
                      "description",
                      a?.id
                    );
                  }}
                  selectValue={a?.name}
                  setSelectValue={(newVal) => {
                    // setForm(prev => {
                    //   return {
                    //     ...prev,
                    //     competencyAspects: prev?.competencyAspects?.map(obj => {
                    //       if (obj?.id === a?.id) {
                    //         return {
                    //           ...obj,
                    //           name: newVal
                    //         }
                    //       } return obj
                    //     })
                    //   }
                    // })
                    handleChangeForm(
                      "competencyAspects",
                      newVal,
                      "name",
                      a?.id
                    );
                  }}
                  withDeleteOption={aI !== 0 ? true : false}
                  handleDelete={() => {
                    setForm((prev) => {
                      return {
                        ...prev,
                        competencyAspects: prev?.competencyAspects?.filter(
                          (obj) => obj?.id !== a?.id
                        ),
                      };
                    });
                  }}
                />
              ))}
            </div>
            <Button
              text={"Tambah Aspek Kompetensi"}
              variant="text"
              startIcon={"plus"}
              iconSize={"11px"}
              style={{ alignSelf: "flex-start", padding: "0px" }}
              onClick={() =>
                setForm((prev) => {
                  return {
                    ...prev,
                    competencyAspects: [
                      ...prev?.competencyAspects,
                      {
                        id: makeRandomString(5),
                        name: "",
                        description: "",
                      },
                    ],
                  };
                })
              }
            />
          </div>
        ),
      },
      {
        title: "Sasaran Peserta",
        description:
          "Tuliskan deskripsi yang jelas dari sasaran peserta untuk kelas webinar ini.",
        component: (
          <div className={Styles.targetWrapper}>
            <div className={Styles.targetParticipantsWrapper}>
              {form?.targetParticipants?.map((t, tI) => (
                <InputField
                  key={tI}
                  title={tI === 0 ? "Sasaran Peserta" : ""}
                  value={t?.name}
                  setValue={(newVal) => {
                    handleChangeForm(
                      "targetParticipants",
                      newVal,
                      "name",
                      t?.id
                    );
                  }}
                  withDeleteOption={tI !== 0 ? true : false}
                  onDelete={() => {
                    setForm((prev) => {
                      return {
                        ...prev,
                        targetParticipants: prev?.targetParticipants?.filter(
                          (obj) => obj?.id !== t?.id
                        ),
                      };
                    });
                  }}
                  placeholder="Sasaran peserta"
                  required
                />
              ))}
            </div>
            <Button
              text={"Tambah Sasaran Peserta"}
              variant="text"
              startIcon={"plus"}
              iconSize={"11px"}
              style={{ alignSelf: "flex-start", padding: "0px" }}
              onClick={() =>
                setForm((prev) => {
                  return {
                    ...prev,
                    targetParticipants: [
                      ...prev?.targetParticipants,
                      {
                        id: makeRandomString(5),
                        name: "",
                      },
                    ],
                  };
                })
              }
            />
          </div>
        ),
      },
      {
        title: "",
        component: (
          <div className={Styles.certificateContainer}>
            <div className={Styles.headWrapper}>
              <p>Sertifikat</p>
              <div>
                <p>Semua vendor menggunakan sertifikat yang sama :</p>
                <Switch
                  isChecked={certificate.isAllCertificate}
                  setIsChecked={() => {
                    handleSwitchCertificate();
                  }}
                />
              </div>
            </div>
            <div>
              {certificate.data.map((el, idx) => (
                <div className={Styles.certificateWrapper} key={idx}>
                  {el.vendor !== "ALL" && (
                    <p>Sertifikat {capitalizeWords(el.vendor)}</p>
                  )}
                  {el.file ? (
                    <div className={Styles.certificateImageContainer}>
                      <img src={fileBaseUrl + el.file} alt="" />
                      <div className={Styles.buttonWrapper}>
                        <Button
                          text={"Ganti Template"}
                          endIcon={"chevron-right"}
                          variant="neutral-contained"
                          onClick={() => setModalSelectCertificate(el._id)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className={Styles.inputContainer}>
                      <p>Pilih Template Sertifikat</p>
                      <img src={Images.CERTIFICATE_INPUT} alt="" />
                      <Button
                        text={"Pilih Sertifikat"}
                        onClick={() => setModalSelectCertificate(el._id)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ),
      },
      {
        title: "Konfigurasi",
        component: (
          <div className={Styles.configurationWrapper}>
            <CheckboxOption
              textCheckbox={
                "Peserta diizinkan untuk mempercepat video pembelajaran"
              }
              isChecked={form?.skipVideo}
              onClick={() =>
                setForm((prev) => {
                  return {
                    ...prev,
                    skipVideo: !prev?.skipVideo,
                  };
                })
              }
            />
            <CheckboxOption
              textCheckbox={
                "Peserta diizinkan untuk menonton video secara tidak berurutan"
              }
              isChecked={form?.unorderedWatch}
              onClick={() =>
                setForm((prev) => {
                  return {
                    ...prev,
                    unorderedWatch: !prev?.unorderedWatch,
                  };
                })
              }
            />
            <CheckboxOption
              textCheckbox={
                "Peserta diizinkan untuk mengerjakan kuis sebelum materi selesai"
              }
              isChecked={form?.doQuizFromStart}
              onClick={() =>
                setForm((prev) => {
                  return {
                    ...prev,
                    doQuizFromStart: !prev?.doQuizFromStart,
                  };
                })
              }
            />
          </div>
        ),
      },
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form?.category,
    form?.competencyAspects,
    form?.competencyUnits,
    form?.description,
    form?.doQuizFromStart,
    form?.generalPurpose,
    form?.instructorName,
    form?.kkniTitle,
    form?.language,
    form?.level,
    form?.name,
    form?.participantType,
    form?.skipVideo,
    form?.specificPurpose,
    form?.targetParticipants,
    form?.thumbnail,
    form?.tools,
    form?.unorderedWatch,
    form?.video,
    handleChangeForm,
    instructors,
    isLoadingSync,
    options?.categories,
    options?.competencyAspects,
    options?.languages,
    options?.levels,
    options?.participantTypes,
    pathname,
    setForm,
    certificate,
  ]);

  const templateToMap = useMemo(() => {
    if (form?.participantType === "PRAKERJA") {
      return template?.filter((obj) => obj?.title !== "Konfigurasi");
    }
    return template?.filter((obj) => obj?.title !== "Unit Kompetensi");
  }, [form?.participantType, template]);

  const [certificateData, setCertificateData] = useState([]);

  const getDataCertificate = async () => {
    try {
      const res = await fetchMasterDataCertificate();
      if (res.status) {
        setCertificateData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataCertificate();
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Detail Kelas</h3>
        <span>Isi dan lengkapi data informasi kelas yang akan dibuat</span>
      </div>
      <div className={Styles.content}>
        {templateToMap?.map((t, tI) => (
          <div key={tI} className={Styles.each}>
            <div className={Styles.eachHeader}>
              <h4>{t?.title}</h4>
              {!!t?.description && <span>{t?.description}</span>}
            </div>
            {t?.component}
          </div>
        ))}
      </div>
      <Modal isOpen={modalSelectCertificate}>
        <ModalSelectCertificate
          onClose={() => setModalSelectCertificate(false)}
          data={certificateData}
          handleSelect={(val) => {
            handleChangeCertificate(val);
            setModalSelectCertificate(false);
          }}
        />
      </Modal>
    </div>
  );
}
