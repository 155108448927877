import AdminPrakerjaLayout from "@Organism/Admin/Prakerja";
import { getDataTablePrakerja } from "@Services/admin";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = async (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const startDate = params.get("startDate") || "";
  const endDate = params.get("endDate") || "";
  const search = params.get("search") || "";
  const getData = await getDataTablePrakerja(page, limit, startDate, endDate,search);
  return defer({
    getDataTable: getData,
  });
};

export default function AdminPrakerjaPage() {
  const { getDataTable } = useLoaderData();

  return (
    <Suspense fallback={<p>loading....</p>}>
      <Await
        resolve={getDataTable}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <AdminPrakerjaLayout data={value} />}
      </Await>
    </Suspense>
  );
}
