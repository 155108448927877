import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import InputField from "@Molecule/InputField";
import Button from "@Atom/Button";
import Table from "@Molecule/Table";
import Modal from "@Atom/Modal";
import {
  getDataNotifValidation,
  sendMessageValidation,
  sendMessageValidationAll,
} from "@Services/admin";
import SuccessNotifModal from "@Molecule/SuccessNotifModal";

export default function ValidationNotif() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState({
    totalData: 0,
    data: [],
  });
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const handleFetchData = async () => {
    try {
      setLoading(true);
      const res = await getDataNotifValidation(page, limit,search);
      if (res.status) {
        setData(res);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit,search]);

  useEffect(() => {
    setPage(1)
  }, [search])
  

  const handleSendMessage = async (data) => {
    const payload = {
      data,
      templateId: "manualValidation",
    };
    try {
      const res = await sendMessageValidation(payload);
      if (res.status) {
        if (data.length > 1) {
          setSuccess("Seluruh Murid");
        } else {
          setSuccess(data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendMessageAll = async () => {
    const payload = {
      templateId: "manualValidation",
    };
    try {
      const res = await sendMessageValidationAll(payload);
      if (res.status) {
        setSuccess("Seluruh Murid");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const template = useMemo(() => {
    return {
      data: data.data,
      columns: [
        {
          name: "Name",
          title: "Nama Student",
          renderData: (row) => <span>{row.user[0].name}</span>,
        },
        {
          name: "",
          title: "Kelas",
          renderData: (row) => <span>{row.course.title}</span>,
        },
        {
          name: "code",
          title: "Kode Voucher",
        },
        {
          name: "",
          title: "",
          renderData: (row) => (
            <div className={Styles.buttonSendTable}>
              <Button
                text={"Kirim Reminder"}
                endIcon={"document-upload-filled"}
                variant="outlined"
                onClick={() => handleSendMessage([row.user[0].name])}
              />
            </div>
          ),
          hideOnMobile: true,
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={Styles.container}>
      <div className={Styles.controlSection}>
        <div className={Styles.searchBar}>
          <InputField
            startIcon="search-normal"
            placeholder="Cari Nama"
            iconSize="20"
            setValue={setSearch}
            value={search}
          />
        </div>
        <div className={Styles.buttonWrapper}>
          <Button
            text={"Kirim Semua"}
            startIcon={"sms-tracking"}
            disabled={data.length < 1}
            onClick={() => handleSendMessageAll()}
          />
        </div>
      </div>
      <div className={Styles.bodySection}>
        <Table
          data={template?.data}
          totalData={data?.totalData}
          totalPage={Math.ceil(data.totalData / limit)}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          withPagination
          withNumbering
          isLoading={loading}
        />
      </div>
      <Modal isOpen={success}>
        <SuccessNotifModal handleClose={() => setSuccess(false)} to={success} />
      </Modal>
    </div>
  );
}
