import useQuery from "@Hooks/useQuery";
import { useEffect } from "react";

const OldCertificatePage = () => {
  const query = useQuery();
  const userId = query.get("studentId");
  const classId = query.get("courseId");
  useEffect(() => {
    if (userId) {
      window.location.replace(
        `https://old.skills.id/certificate?courseId=${classId}&studentId=${userId}`
      );
    }
  }, [userId]);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
};

export default OldCertificatePage;
