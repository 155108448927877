import React, { useRef, useState } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import Images from "@Theme/Images";
import examplePhoto from "@Assets/Images/exampleImageClass.png";
import DatePicker from "@Atom/DatePicker";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import Footer from "@Molecule/Footer";
import InputField from "@Molecule/InputField";
export default function SettingAccount({ isEdit }) {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };
  const [isOpenModalPhoto, setIsOpenModalPhoto] = useState(false);
  const handleOpenModalPhoto = () => {
    setIsOpenModalPhoto(!isOpenModalPhoto);
  };
  const refPhoto = useRef(null);
  const handleUploadPhoto = () => {
    refPhoto.current.click();
  };
  const [imageProfile, setImageProfile] = useState();
  const handlePreviewImage = (event) => {
    const selectedPreview = event.target.files[0];
    if (selectedPreview) {
      setImageProfile(URL.createObjectURL(selectedPreview));
    } else {
      setImageProfile(null);
    }
  };
  const ref = useRef(null);
  const [input, setInput] = useState({
    name: "",
    email: "",
    telephone: "",
    gender: "",
    dateOfBirth: "",
    jobs: "",
  });
  // const datas = { name: "" };
  const handleChangeInput = (name, value) => {
    setInput((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <div>
      <div className={Styles.layout}>
        <div className={Styles.wrapper}>
          <section className={Styles.doodle}>
            <DoodleGradientCard>
              <div className={Styles.topCard}>
                <div className={Styles.leftSection}>
                  <div>
                    <p className={Styles.title}>
                      <span>Detail Akun</span>
                    </p>
                  </div>
                </div>
                <div className={Styles.rightSection}>
                  <img src={Images.ID_CARD} alt="icon" />
                </div>
              </div>
            </DoodleGradientCard>
          </section>
          <div className={Styles.profile} onClick={handleUploadPhoto}>
            <img
              src={examplePhoto}
              alt="profile"
              onClick={() => ref.current?.click()}
            />
            <input
              type="file"
              hidden
              ref={refPhoto}
              onChange={handlePreviewImage}
            />
          </div>
        </div>
        <section className={Styles.editProfile}>
          <InputField
            placeholder="Nama Lengkap"
            id={"name"}
            htmlFor={"name"}
            title="Nama"
            setValue={(val) => handleChangeInput("name", val)}
            value={input.name}
          />
          <div className={Styles.mailPhone}>
            <div>
              <InputField
                title={"Email"}
                placeholder="Email"
                htmlFor={"email"}
                id={"email"}
                setValue={(val) => handleChangeInput("email", val)}
                value={input.email}
                isEmail={true}
              />
            </div>
            <div>
              <InputField
                title="No Telpon"
                placeholder="No Telephone"
                htmlFor={"notlp"}
                id={"notlp"}
                setValue={(val) => handleChangeInput("telephone", val)}
                value={input.telephone}
                isNumber={true}
              />
            </div>
          </div>
          <div className={Styles.mailPhone}>
            <div>
              <label htmlFor="gender" style={{ position: "relative" }}>
                Jenis Kelamin
                <select
                  className={Styles.select}
                  isChoose={input.gender ? "true" : "false"}
                  onChange={(val) =>
                    handleChangeInput("gender", val.target.value)
                  }
                  value={input.gender}
                >
                  <option value="" hidden>
                    Jenis Kelamin
                  </option>
                  <option value="laki-laki">Laki-Laki</option>
                  <option value="perempuan">Perempuan</option>
                </select>
                <Icon
                  icon={"arrow-right"}
                  size={"20"}
                  className={Styles.arrowDropdown}
                />
              </label>
            </div>
            <div className={Styles.datePicker}>
              <label htmlFor="date">Tanggal Lahir</label>
              <DatePicker
                id={"date"}
                type={"date"}
                placeholder={"Tanggal Lahir"}
                handleChange={(e) => handleChangeInput("dateOfBirth", e)}
                value={input.dateOfBirth}
              />
            </div>
          </div>
          <InputField
            placeholder="Karyawan Swasta"
            id={"job"}
            htmlFor={"job"}
            title="Pekerjaan"
            setValue={(val) => handleChangeInput("jobs", val)}
            value={input.jobs}
          />
          <div className={Styles.buttonProfile}>
            <button
              isDisabled={!!imageProfile ? "false" : "true"}
              onClick={handleOpenModal}
            >
              <span>Perbarui</span>
            </button>
          </div>
        </section>
        <Modal isOpen={isOpen || !!imageProfile}>
          {!!imageProfile ? (
            <div className={Styles.wrapperModal} isEdit={isEdit}>
              <div className={Styles.wrapperPhoto}>
                <div className={Styles.headerClose}>
                  <h1>Tambah Foto Profil</h1>
                  <Icon
                    icon={"cross"}
                    size={"24"}
                    className={Styles.iconClose}
                    onClick={() => setImageProfile(null)}
                  />
                </div>
                <div>
                  {/* {imageProfile ? ( */}
                  <img src={imageProfile} alt="profile images " />
                  {/* ) : ( */}
                  {/* <div>
                    <img src={examplePhoto} alt="profile" />
                  </div>
                )} */}
                </div>
              </div>

              <div>
                <div className={Styles.buttonModal}>
                  <button onClick={handleOpenModalPhoto}>
                    <span>Kembali</span>
                  </button>
                  <button>
                    <span>Simpan</span>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className={Styles.wrapperModal} isEdit={isEdit}>
              <div className={Styles.desc}>
                <h1>
                  {isEdit
                    ? "Terdapat Perubahan yang Belum Tersimpan!"
                    : "Perbaharui Detail Akun Kamu?"}{" "}
                </h1>
                <p>
                  {isEdit
                    ? "Perubahan detail akunmu belum disimpan apakah kamu yakin tidak ingin menggati detail pada akunmu?"
                    : "Apakah kamu yakin ingin melakukan perubahan pada detail akunmu?"}
                </p>
              </div>
              <div>
                <div className={Styles.buttonModal}>
                  <button onClick={handleOpenModal}>
                    <span>{isEdit ? "Simpan Perubahan" : "Tidak"}</span>
                  </button>
                  <button>
                    <span>{isEdit ? "Batalkan Perubahan" : " Ya"}</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </div>
      <Footer />
    </div>
  );
}
