import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import InputField from "@Molecule/InputField";
import Table from "@Molecule/Table";
import { getDataNotifLearn } from "@Services/admin";
import RedeemNotifDetail from "./RedeemDetail";
import { useLocation, useNavigate } from "react-router-dom";

export default function RedeemNotif() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState({
    totalData: 0,
    data: [],
  });
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleFetchData = async () => {
    try {
      setLoading(true);
      const res = await getDataNotifLearn(page, limit,search);
      if (res.status) {
        setData(res);
        // setSuccess(true);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit,search]);

  const template = useMemo(() => {
    return {
      data: data.data,
      columns: [
        {
          name: "Name",
          title: "Judul Kelas",
          renderData: (row) => (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelected(row);
                navigate(`${pathname}?c=${row.title}`, {
                  replace: true,
                });
              }}
            >
              {row.title}
            </span>
          ),
        },
        {
          name: "",
          title: "Nama Instruktur",
          renderData: (row) => <span>{row.instructor.name}</span>,
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!!selected) {
    return (
      <RedeemNotifDetail
        handleBack={() => setSelected(null)}
        dataProps={selected}
      />
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.controlSection}>
        <div className={Styles.searchBar}>
          <InputField
            startIcon="search-normal"
            placeholder="Cari Kelas"
            iconSize="20"
            setValue={setSearch}
            value={search}
          />
        </div>
        {/* <div className={Styles.buttonWrapper}>
          <Button
            text={"Kirim Semua"}
            startIcon={"sms-tracking"}
            disabled={data.length < 1}
          />
        </div> */}
      </div>
      <div className={Styles.bodySection}>
        <Table
          data={template?.data}
          totalData={data?.totalData}
          totalPage={Math.ceil(data.totalData / limit)}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          //   className={`${Styles.table} ${
          //     !template?.data?.length ? Styles.grow : ""
          //   }`}
          limit={limit}
          setLimit={setLimit}
          withPagination
          withNumbering
          isLoading={loading}
        />
      </div>
    </div>
  );
}
