import React, { useMemo } from "react";
import Styles from "./style.module.scss";
import Lottie from "lottie-react";
import loadingClass from "@Assets/animation/loadingClass.json";
import Footer from "@Molecule/Footer";
import Button from "@Atom/Button";
import moment from "moment";
import findBankVariable from "@Helpers/bankVariables";
import { useNavigate } from "react-router-dom";
import Images from "@Theme/Images";

export default function TransacionDetailLayout({ data, isLoading }) {
  const bankDetail = useMemo(() => {
    return findBankVariable(data?.method);
  }, [data]);
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className={Styles.loadingContainer}>
        <Lottie animationData={loadingClass} loop={true} />
        <p>Memuat Kontent</p>
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <div className={Styles.header}>
          <Button
            text={"Kembali"}
            variant="text"
            startIcon={"chevron-left"}
            onClick={() => navigate(-1)}
          />
          <div className={Styles.status} status={data.status}>
            <p>
              {data.status === "PAID-OFF"
                ? "Transaksi Berhasil"
                : "Transaksi Tidak Berhasil"}
            </p>
          </div>
        </div>
        <div className={Styles.topSection}>
          <div>
            <p>{data.invoiceCode}</p>
            {data.status === "PAID-OFF" && (
              <React.Fragment>
                <p>•</p>
                <p>
                  terbayar pada : {moment(data.paidDate).format("DD MMMM YYYY")}
                </p>
              </React.Fragment>
            )}
          </div>
          {data.status === "PAID-OFF" && data.method !== "PRAKERJA" && (
            <div className={Styles.right}>
              <img alt="" src={bankDetail?.logo} />
              <span>•</span>
              <span>{bankDetail?.label}</span>
            </div>
          )}
        </div>
        <div className={Styles.mainSection}>
          <div className={Styles.left}>
            <div className={Styles.card}>
              <div className={Styles.cardContent}>
                <p>Detail Pembeli</p>
                <div className={Styles.label}>
                  <p>Name</p>
                  <p>{data.user.name}</p>
                </div>
                <div className={Styles.label}>
                  <p>Email</p>
                  <p>{data.user.email}</p>
                </div>
                <div className={Styles.label}>
                  <p>No Telp</p>
                  <p>{data.user.phone}</p>
                </div>
              </div>
              <div className={Styles.divider} />
              <div className={Styles.cardContent}>
                <p>Detail Pembelian</p>
                <div className={Styles.label}>
                  <p>{data.courseTitle}</p>
                  <p>Rp{(+data.price.base).toLocaleString("id")}</p>
                </div>
                {data.method !== "PRAKERJA" && (
                  <div className={Styles.discount}>
                    <p>
                      Discount <span>({data.price.discount})</span>
                    </p>
                    <p>
                      - Rp
                      {(
                        +data.price.base *
                        (+data.price.discount.split("%").join("") / 100)
                      ).toLocaleString("id")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={Styles.right}>
            <div className={Styles.card}>
              <p>Rincian</p>
              <div className={Styles.cardContent}>
                <div className={Styles.label}>
                  <p>
                    SubTotal <span>(1 Kelas)</span>
                  </p>
                  <p>Rp{(+data.price.base).toLocaleString("id")}</p>
                </div>
                {data.method !== "PRAKERJA" && (
                  <div className={Styles.discount}>
                    <p>
                      Discount <span>({data.price.discount})</span>
                    </p>
                    <p>
                      - Rp
                      {(
                        +data.price.base *
                        (+data.price.discount.split("%").join("") / 100)
                      ).toLocaleString("id")}
                    </p>
                  </div>
                )}
              </div>
              <div className={Styles.divider} />
              <div className={Styles.paidTotal}>
                <p>Total Bayar</p>
                <p>
                  {(data.method === "PRAKERJA"
                    ? +data.price.base
                    : +data.price.actual
                  ).toLocaleString("id")}
                </p>
              </div>
              {data.method === "PRAKERJA" && (
                <div className={Styles.prakerjaMethod}>
                  <p>Kode Voucher yang digunakan :</p>
                  <div className={Styles.codeWrapper}>
                    <p>{data.voucherCode}</p>
                    <img alt="" src={Images.LOGO_PRAKERJA_NEW} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
