import React from "react";
import style from "./index.module.scss";
import Icon from "@Atom/Icon";
import skillsIcon from "../../../assets/Images/logo.png";
import moment from "moment";
export default function Footer() {
  return (
    <div className={style.container}>
      <div className={style.wrapperFooter}>
        <div className={style.skillsIcon}>
          <img
            src={skillsIcon}
            className={style.iconGradient}
            alt="logo skills.id"
          />
          <span className={style.skilldId}>Skills.id</span>
        </div>
        <div className={style.copyright}>
          Copyright © {moment().format("YYYY")}. Skills.id, All rights reserved.
        </div>
        <div className={style.socialMediaIcon}>
          <a
            href="https://www.instagram.com/skillsid_official"
            target="_balank"
          >
            <Icon icon={"instagram"} color={"white"} size={"18"} />
          </a>
          <a href="https://www.youtube.com/@skillsidofficial" target="_balank">
            <Icon icon={"Lozenge"} color={"white"} size={"18"} />
          </a>
          <a href="https://tiktok.com/@skillsidofficial" target="_balank">
            <Icon icon={"TikTok---Negative"} color={"white"} size={"18"} />
          </a>
        </div>
      </div>
      <div className={style.copyrightMobile}>
        Copyright © {moment().format("YYYY")}. Skills.id, All rights reserved.
      </div>
    </div>
  );
}
