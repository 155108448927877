import InputField from '@Molecule/InputField'
import Styles from './style.module.scss'

export default function PricingSection({
  price,
  setPrice = () => { }
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Penetapan Harga</h3>
        <span>Tetapkan harga untuk kelas yang akan dibuat</span>
      </div>
      <InputField
        title={'Harga Kelas'}
        value={price}
        setValue={setPrice}
        placeholder='Harga kelas'
        isCurrency
      />
    </div>
  )
}