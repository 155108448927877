import DoodleGradientCard from "@Atom/DoodleGradientCard";
import Footer from "@Molecule/Footer";
import Table from "@Molecule/Table";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import ChevronGradient from "@Assets/Icon/gradientArrowRight.svg";
import Avatar from "@Atom/Avatar";
import useWindowSize from "@Hooks/useWindowSize";
import { makeRandomString } from "@Helpers/makeRandomString";

export default function DetailStudentLayout({ data }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { width } = useWindowSize();
  const template = useMemo(() => {
    return {
      data: data?.courses?.map((el) => {
        return {
          ...el,
          id: el?._id || makeRandomString(5),
          title: el?.courseTitle,
          instructor: el?.instructor?.name,
          voucher: el?.vouchers[0]?.code,
          redeemCode: el?.vouchers[0]?.redeemCode || "-",
          type: el?.type,
          progress: el?.progress,
          emailInstructor: el?.instructor?.email,
        };
      }),
      columns: [
        {
          name: "",
          title: "Judul Kelas",
          width: "214px",
          renderData: (row) => <div className={Styles.title}>{row?.title}</div>,
        },
        {
          name: "",
          title: "Instructor",
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.wrapInstructor}>
              <Avatar
                iconName="user"
                iconSize="10px"
                size="24px"
                className={Styles.icon}
              />
              <div className={Styles.names}>
                <h5> {row?.instructor || "-"}</h5>
                <span>{row?.emailInstructor || "-"}</span>
              </div>
            </div>
          ),
        },
        {
          name: "voucher",
          title: "Kode Voucher",
          hideOnMobile: true,
        },
        {
          name: "redeemCode",
          title: "Reedem Code",
          hideOnMobile: true,
        },
        {
          name: "type",
          title: "Jenis Kelas",
          hideOnMobile: true,
        },
        {
          name: "progress",
          title: "Progress",
          hideOnMobile: true,
        },
      ],
    };
  }, [data]);

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <div
          className={Styles.backButton}
          onClick={() => navigate("/admin/student")}
        >
          <img src={ChevronGradient} alt="" className={Styles.icon} />
          <span>Kembali</span>
        </div>
        <div className={Styles.wrapHeader}>
          <DoodleGradientCard className={Styles.header} />
          <div className={Styles.wrapperProfile}>
            {/* <img
              src="https://placehold.co/160x160"
              alt=""
              className={Styles.images}
            /> */}
            <Avatar
              iconName="user"
              iconSize={width > 768 ? "100px" : "50px"}
              size={width > 768 ? "160px" : "80px"}
              className={Styles.images}
            />
            <div className={Styles.containProfile}>
              <h1>Detail Peserta</h1>
              <div className={Styles.descProfile}>
                <div className={Styles.wrap}>
                  <span>Nama Peserta</span>
                  <h2>{data?.name}</h2>
                </div>
                <div className={Styles.divider}></div>
                <div className={Styles.wrap}>
                  <span>Email</span>
                  <h2>{data?.email}</h2>
                </div>
                <div className={Styles.divider}></div>
                <div className={Styles.wrap}>
                  <span>Nomor Handphone</span>
                  <h2>{data?.phoneNumber}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          className={`${Styles.table} ${
            !template?.data?.length ? Styles.grow : ""
          } ${Styles.wrapperTable}`}
          data={template.data}
          columns={template.columns}
          page={page}
          limit={limit}
          totalData={data?.courses?.length}
          totalPage={Math.ceil(data?.courses?.length / limit)}
          setLimit={setLimit}
          setPage={setPage}
          withPagination
          withNumbering
          descTitle={"Riwayat Kelas Tidak Tersedia"}
          descEmpty={
            "Peserta ini belum pernah mengikuti pelatihan pada Skills.id"
          }
          expandComponent={(row) => (
            <div className={Styles.expandedInfo}>
              <div className={Styles.infoRow}>
                <span>Instructor</span>
                <span>{row?.instructor}</span>
              </div>
              <div className={Styles.infoRow}>
                <span>Kode Voucher</span>
                <span>{row?.voucher}</span>
              </div>
              <div className={Styles.infoRow}>
                <span>Reedem Code</span>
                <span>{row?.redeemCode}</span>
              </div>
              <div className={Styles.infoRow}>
                <span>Jenis Kelas</span>
                <span>{row?.type}</span>
              </div>
              <div className={Styles.infoRow}>
                <span>Progress</span>
                <span>{row?.progress}</span>
              </div>
            </div>
          )}
        />
      </div>

      <Footer />
    </div>
  );
}
