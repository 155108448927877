import React from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import phoneMockupPrakerja from "@Assets/Images/Phone-mockup-prakerja.png";
import { Link } from "react-router-dom";
export default function DescribePrakerja() {
  return (
    <div className={Styles.describePrakerja}>
      <img src={phoneMockupPrakerja} alt="phone-mockup" />
      <div className={Styles.wrapperText}>
        <h1>
          Apa itu Program <span>Prakerja?</span>
        </h1>
        <div className={Styles.text}>
          <p>
            Program Kartu Prakerja adalah program beasiswa pelatihan untuk
            meningkatkan kompetensi kerja dan kewirausahaan. Program ini
            ditujukan bukan hanya untuk pencari kerja, tapi juga mereka yang
            sudah bekerja maupun buruh yang ingin mendapatkan peningkatan skill
            atau kompetensi, juga pekerja/buruh yang terkena pemutusan hubungan
            kerja, termasuk pelaku usaha mikro dan kecil.
          </p>
        </div>
        <div>
          <Link
            to={"https://dashboard.prakerja.go.id/daftar"}
            className={Styles.linkTo}
            target="_blank"
          >
            <Button text={"Daftar Prakerja Sekarang"} />
          </Link>
        </div>
      </div>
    </div>
  );
}
