import React from "react";
import Styles from "./style.module.scss";
import imageGallery from "@Assets/Images/landingPageGallery.png";
import Icon from "@Atom/Icon";
export default function ImageGallery({ className, ...props }) {
  return (
    <div className={className} {...props}>
      <section className={Styles.wrapperImage}>
        <div className={Styles.imageGallery}>
          <img src={imageGallery} alt="" />
        </div>
        <div className={Styles.description} id="prakerja">
          <h1 >Apa itu Prakerja?</h1>
          <p>
            Program Kartu Prakerja adalah program beasiswa pelatihan untuk
            meningkatkan kompetensi kerja dan kewirausahaan. Program ini
            ditujukan bukan hanya untuk pencari kerja, tapi juga mereka yang
            sudah bekerja maupun buruh yang ingin mendapatkan peningkatan skill
            atau kompetensi, juga pekerja/buruh yang terkena pemutusan hubungan
            kerja, termasuk pelaku usaha mikro dan kecil.
          </p>
          <a href={"prakerja"} className={Styles.linkTo}>
            <div className={Styles.buttonGallery}>
              <div>Lihat Selengkapnya</div>
              <Icon
                size={"16"}
                // color={"white"}
                icon={"arrow-right-next"}
                className={Styles.iconGallery}
              />
            </div>
          </a>
        </div>
      </section>
    </div>
  );
}
