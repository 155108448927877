import AbsenceDetailAdmin from "@Organism/Admin/AbsenceDetail";
import { getClassAbsence } from "@Services/admin";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader({ params, request }) {
  const { id } = params;
  const url = new URL(request.url);
  const sessionNum = url.searchParams.get("s") || 1;
  const courseBatch = url.searchParams.get("b") || 1;
  const absence = await getClassAbsence(id, sessionNum, courseBatch);

  return defer({
    dataAbsence: absence,
  });
}

export default function AbsenceClassAdminPage() {
  const { dataAbsence } = useLoaderData();

  return (
    <Suspense fallback={<p>Loading....</p>}>
      <Await resolve={dataAbsence} errorElement={<div>error</div>}>
        {(dataAbsence) => <AbsenceDetailAdmin data={dataAbsence} />}
      </Await>
    </Suspense>
  );
}
