import React, { useMemo } from "react";
import Button from "@Atom/Button";
import { priceFormat } from "@Helpers/priceFormat";
import Images from "@Theme/Images";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import Lottie from "lottie-react";
import loadingClass from "@Assets/animation/loadingClass.json";
import findBankVariable from "@Helpers/bankVariables";

export default function AdminTransactionDetailsLayout({ data, isLoading }) {
  const navigate = useNavigate();
  // const bankDetail = findBankVariable(data?.method)

  const bankDetail = useMemo(() => {
    return findBankVariable(data?.method);
  }, [data]);

  if (isLoading) {
    return (
      <div className={Styles.loadingContainer}>
        <Lottie animationData={loadingClass} loop={true} />
        <p>Memuat Kontent</p>
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.back}>
          <Button
            variant="text"
            text={"Kembali"}
            startIcon={"chevron-left"}
            style={{ padding: 0 }}
            onClick={() => navigate(-1)}
          />
          <div className={Styles.status} status={data.status}>
            <p>
              {data.status === "PAID-OFF"
                ? "Transaksi Berhasil"
                : "Transaksi Tidak Berhasil"}
            </p>
          </div>
        </div>

        <div className={Styles.title}>
          <div className={Styles.left}>
            <span>{data?.invoiceCode}</span>
            {data.status === "PAID-OFF" && (
              <React.Fragment>
                <span>•</span>
                <span>{moment(data?.paidDate)?.format("ll")}</span>
              </React.Fragment>
            )}
          </div>
          {data.status === "PAID-OFF" && data.method !== "PRAKERJA" && (
            <div className={Styles.right}>
              <img alt="" src={bankDetail?.logo} />
              <span>•</span>
              <span>{bankDetail?.label}</span>
            </div>
          )}
        </div>
      </div>
      <div className={Styles.divider} />
      <div className={Styles.content}>
        <div className={Styles.leftCard}>
          <div className={Styles.section}>
            <h4>Detail Pembeli</h4>
            <div className={Styles.details}>
              <div className={Styles.row}>
                <p>Nama</p>
                <span>{data?.userName}</span>
              </div>
              <div className={Styles.row}>
                <p>Email</p>
                <span>{data?.user.email}</span>
              </div>
              <div className={Styles.row}>
                <p>No Telp</p>
                <span>+{data?.user.phone}</span>
              </div>
            </div>
          </div>
          <div className={Styles.divider} />
          <div className={Styles.section}>
            <h4>Detail Pembelian</h4>
            <div className={Styles.details}>
              <div className={Styles.row}>
                <p>{data?.courseTitle}</p>
                <span>Rp{priceFormat(+data?.price.base)}</span>
              </div>
              {data.method !== "PRAKERJA" && (
                <div className={Styles.row}>
                  <p>
                    Discount{" "}
                    <span className={Styles.green}>
                      ({data.price.discount})
                    </span>
                  </p>
                  <span className={Styles.red}>
                    -Rp{" "}
                    {+data.price.base *
                      (+data.price.discount.split("%").join("") / 100)}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={Styles.rightCard}>
          <div className={Styles.section}>
            <h4>Rincian</h4>
            <div className={Styles.details}>
              <div className={Styles.row}>
                <p>
                  Subtotal <span className={Styles.grey}>(1 Kelas)</span>
                </p>
                <span>Rp{priceFormat(data?.price.base)}</span>
              </div>
              {data.method !== "PRAKERJA" && (
                <div className={Styles.row}>
                  <p>
                    Discount{" "}
                    <span className={Styles.green}>
                      ({data.price.discount})
                    </span>
                  </p>
                  <span className={Styles.red}>
                    -Rp
                    {+data.price.base *
                      (+data.price.discount.split("%").join("") / 100)}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={Styles.divider} />
          <div className={Styles.section}>
            <div className={Styles.details}>
              <div className={Styles.row}>
                <p>Total Bayar</p>
                <span>
                  Rp
                  {priceFormat(
                    data.method !== "PRAKERJA"
                      ? data?.price.actual
                      : data?.price.base
                  )}
                </span>
              </div>
            </div>
          </div>
          {data.method === "PRAKERJA" && (
            <div className={Styles.prakerjaMethod}>
              <p>Kode Voucher yang digunakan :</p>
              <div className={Styles.codeWrapper}>
                <p>{data.voucherCode}</p>
                <img alt="" src={Images.LOGO_PRAKERJA_NEW} />
              </div>
            </div>
          )}
          {/* <div className={Styles.section}>
            <h4>Bukti Pembayaran</h4>
            <div className={Styles.proof}>
              <img alt='' src={data?.transferProof} onClick={() => setShowProof(true)} />
              <span>{data?.transferProof}.jpgs</span>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className={Styles.actions}>
        <Button
          text={'Tolak Pembayaran'}
          variant='outlined-danger'
          onClick={() => setConfirmationType('negative')}
        />
        <Button
          text={'Terima Pembayaran'}
          onClick={() => setConfirmationType('positive')}
        />
      </div> */}
      {/* <ViewPaymentProofModal
        show={showProof}
        setShow={setShowProof}
        proof={data?.transferProof}
      />
      <PaymentConfirmationModal
        type={confirmationType}
        onClose={() => setConfirmationType('')}
      /> */}
    </div>
  );
}
