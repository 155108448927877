import React, { useState, useMemo } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import Images from "@Theme/Images";
import Icon from "@Atom/Icon";
import ClassesCard from "@Molecule/ClassesCard";
import TransparentSearchbar from "@Atom/TransparentSearchbar";
import Button from "@Atom/Button";
import NotFound from "@Molecule/NotFound";
import { decryptStorageData } from "@Helpers/encryptDecrypt";

export default function InstructorDashboardActivity({ data = [] }) {
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = data.classes
    .filter((item) => item.isPublished === true)
    .filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  const classSync = useMemo(() => {
    return [
      {
        type: "Total Kelas",
        value: data.totalClass,
        icon: "book1",
        color: "#7847EF",
      },
      {
        type: "Total Murid",
        value: data.totalStudent,
        icon: "personalcard",
        color: "#768CC4",
      },
      {
        type: "Kelas Berlangsung",
        value: data.onGoingClas,
        icon: "book-3",
        color: "#F38433",
      },
      {
        type: "Kelas Selesai",
        value: data.completedClass,
        icon: "bookmark-2",
        color: "#05AFCD",
      },
    ];
  }, [data]);

  const [tab, setTab] = useState("active");
  const isNull = filteredData.length === 0 || data.length === 0;
  const user = decryptStorageData("user", localStorage);

  const dataToShow = useMemo(() => {
    let dataActive = [];
    let dataFinished = [];
    for (let i = 0; i < filteredData.length; i++) {
      const batch = filteredData[i].batches;
      if (batch.length < 1 && filteredData[i].platform !== "VIDEO") {
        dataFinished.push(filteredData[i]);
      }
      if (batch.length < 1 && filteredData[i].platform === "VIDEO") {
        dataActive.push(filteredData[i]);
      } else if (
        new Date(batch.slice(-1)[0].endDate).getTime() > new Date().getTime()
      ) {
        dataActive.push(filteredData[i]);
      } else {
        dataFinished.push(filteredData[i]);
      }
      // if()
    }
    if (tab === "active") {
      return dataActive;
    } else {
      return dataFinished;
    }
  }, [filteredData, tab]);

  return (
    <div className={Styles.container}>
      <DoodleGradientCard>
        <div className={Styles.topCard}>
          <div className={Styles.leftSection}>
            <p className={Styles.title}>
              Selamat Datang <span>{user?.name}</span>
            </p>
            <p className={Styles.desc}>
              Berikut adalah Dashboard kelas, serta detail kelas untuk kamu
              melakukan absensi & penilaian
            </p>
            <div className={Styles.inputWrapper}>
              <TransparentSearchbar
                placeholder="Cari Kelasmu"
                width="100%"
                setValue={setSearchQuery}
                value={searchQuery}
              />
            </div>
          </div>
          <div className={Styles.rightSection}>
            <img src={Images.BOOK} alt="book" />
          </div>
        </div>
      </DoodleGradientCard>
      <div className={Styles.section}>
        <div className={Styles.sectionTitle}>
          <p>Dashboard</p>
        </div>
        {/* rating Section */}
        {/* <div className={Styles.ratingSection}>
          <p>Rating Saya</p>
          <div className={Styles.body}>
            <div className={Styles.leftSection}>
              <p>4.5</p>
              <Icon icon={"star-rating"} size={32} color={"#FFC531"} />
            </div>
            <div className={Styles.rightSection}>
              {[5, 4, 3, 2, 1].map((el, idx) => (
                <div key={idx} className={Styles.rating}>
                  <p>{el}</p>
                  <div className={Styles.progress}>
                    <div className={Styles.base} />
                    <div
                      className={Styles.fill}
                      style={{ width: `${el * 10}%` }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        <div className={Styles.cardContainer}>
          {classSync.map((el, idx) => (
            <div className={Styles.card} key={idx}>
              <div className={Styles.cardIcon} style={{ background: el.color }}>
                <Icon icon={el.icon} size={32} />
              </div>
              <div className={Styles.cardText}>
                <p>{el.type}</p>
                <p>{Number(el.value).toLocaleString("id")}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={Styles.section}>
        <div className={Styles.sectionTitle}>
          <p>Kelas Saya</p>
        </div>
        <div className={Styles.tabWrapper}>
          <Button
            variant="tab"
            text={"Kelas Aktif"}
            isActive={tab === "active"}
            onClick={() => setTab("active")}
          />
          <Button
            variant="tab"
            text={"Kelas Selesai"}
            isActive={tab === "finished"}
            onClick={() => setTab("finished")}
          />
        </div>
        <div className={isNull ? Styles.notFound : Styles.classCardWrapper}>
          {isNull ? (
            <NotFound />
          ) : (
            <>
              {dataToShow.map((item, idx) => (
                <ClassesCard
                  key={idx}
                  image={item.asset.thumbnail.imageURL}
                  level={item.level}
                  type={item.type}
                  platform={item.platform}
                  title={item.title}
                  id={item._id}
                  data={item}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
