import { API } from "@Config/index";

export function getCertificateWithValidate(id, name) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/certificates/download/${id}?name=${name.toUpperCase()}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getCertificateWithQr(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/certificates/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
