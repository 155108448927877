import React, { useMemo, useState } from "react";
import Styles from "./style.module.scss";
import InputField from "@Molecule/InputField";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import Table from "@Molecule/Table";
import InputFileModal from "@Molecule/InputFileModal";
import Modal from "@Atom/Modal";
import {
  sendMessageRedeem,
  updateDataNotifRegister,
} from "@Services/admin";
import SelectNotifTemplateModal from "@Molecule/SelectNotifTemplateModal";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessNotifModal from "@Molecule/SuccessNotifModal";

const dataTemplateNotif = [
  {
    id: "0e4ebf69-23c8-40b0-a40a-e3b4ae501687",
    organization_id: "5dad9447-e7e7-4f63-bdac-cf163a57fd78",
    name: "reminderRedeemOne",
    language: "id",
    header: null,
    body: 'Hi Kak {{1}}, untuk kode voucher dan kode redeem pelatihan sudah bisa ditukarkan sekarang ya, maksimal penukaran 1 jam setelah pelatihan dimulai. Pastikan sudah registrasi di skills.id https://skills.id/register . \n\nBerikut kode voucher {{2}} yang bisa kamu tukarkan. Untuk kode redeem bisa dilihat di dashboard Prakerjamu (Pastikan tidak tertukar!). \n\nUrutan penukaran : Peserta registrasi akun di Skills.id. Masukan kode voucher (Tokopedia) dikolom yang sudah disediakan lalu klik "tukarkan sekarang", pilih jadwal pelatihan hari ini, input kode voucher kembali, lalu input kode redeem. Mulai pelatihan dengan mengerjakan pretest dan menunggu jadwal webinar dimulai. Jika ada kendala, silakan balaskan chat ini. Terima kasih\n\nNote : Jika Terlambat menukarkan, maka kode voucher hangus dan tidak bisa mengikuti pelatihan Prakerja kembali. \n\n*Silakan klik link dibawah untuk tutorial/cara mengikuti pelatihannya dari penukaran hingga penyelesaian kelas*',
    footer: null,
    buttons: [
      {
        url: "https://www.youtube.com/watch?v=seranIveHZs&t=38s",
        text: "Klik Disini",
        type: "URL",
      },
    ],
    status: "APPROVED",
    category: "MARKETING",
    quality_rating: null,
    quality_rating_text: "Neutral",
  },
  {
    id: "847b4f25-209e-463e-85c6-9914f65ee806",
    organization_id: "5dad9447-e7e7-4f63-bdac-cf163a57fd78",
    name: "reminderRedeemTwo",
    language: "id",
    header: null,
    body: 'Hi Kak {{1}}, untuk kode voucher dan kode redeem pelatihan sudah bisa ditukarkan sekarang ya. Pastikan sudah registrasi akun di https://skills.id/register . \n\nBerikut kode voucher {{2}} yang bisa kamu tukarkan. Untuk kode redeem bisa dilihat di dashboard Prakerjamu (Pastikan tidak tertukar!). \n\nUrutan penukaran : Peserta registrasi akun di Skills.id. Masukan kode voucher (Tokopedia) dikolom yang sudah disediakan lalu klik "tukarkan sekarang", pilih jadwal pelatihan hari ini, input kode voucher kembali, lalu input kode redeem dan tukarkan. \n\nPastikan tiba di lokasi pelatihan tepat waktu, karena peserta wajib melakukan absen menggunakan scan barcode. Caranya: Peserta menunjukan kode QR (di dashboard Prakerja) dengan klik tombol Lihat QR di lokasi pelatihan dan tunjukkan kode QR ke lembaga pelatihan untuk verifikasi pelatihan yang akan kamu ikuti.\n\nNote : Pastikan untuk hadir di lokasi pelatihan tepat waktu karena akan ada absen via barcode untuk status di Prakerja berubah terverifikasi. ',
    footer: null,
    buttons: [
      {
        url: "https://goo.gl/maps/eiB3MFyfs6F3eeSd8",
        text: "Lokasi Pelatihan",
        type: "URL",
      },
    ],
    status: "APPROVED",
    category: "UTILITY",
    quality_rating: null,
    quality_rating_text: "Neutral",
  },
];
export default function RedeemNotifDetail({ dataProps, handleBack }) {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalSelect, setModalSelect] = useState(false);
  const [dataToSend, setDataToSend] = useState([]);
  const [success, setSuccess] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const template = useMemo(() => {
    return {
      data: data.slice(limit * (page - 1), limit * page),
      columns: [
        {
          name: "Name",
          title: "Judul Kelas",
        },
        {
          name: "",
          title: "",
          renderData: (row) => (
            <div className={Styles.buttonSendTable}>
              <Button
                text={"Kirim Reminder"}
                endIcon={"document-upload-filled"}
                variant="outlined"
                onClick={() => {
                  setModalSelect(true);
                  setDataToSend([
                    {
                      name: row.Name,
                      phoneNumber: row.Phone_Number,
                      voucherCode: row.Voucher_Code,
                    },
                  ]);
                }}
              />
            </div>
          ),
          hideOnMobile: true,
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, data]);

  const handleFetchData = async (file) => {
    try {
      const res = await updateDataNotifRegister(file);
      setDataToSend([]);
      if (res.status) {
        setData(res.data);
        setOpenModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendMessage = async (name) => {
    const payload = {
      data: dataToSend,
      templateId: name,
    };
    try {
      const res = await sendMessageRedeem(payload);
      if (res.status) {
        if (dataToSend.length > 1) {
          setSuccess("Seluruh Murid");
        } else {
          setSuccess(dataToSend[0].name);
        }
        setModalSelect(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={Styles.container}>
      <div>
        <Button
          variant="text"
          text={"Kembali"}
          startIcon={"chevron-left"}
          onClick={() => {
            navigate(`${pathname}?c=`, {
              replace: true,
            });
            handleBack();
          }}
        />
      </div>
      <div className={Styles.titleHead}>
        <p>{dataProps?.title}</p>
      </div>
      <div className={Styles.controlSection}>
        <div className={Styles.searchBar}>
          <InputField
            startIcon="search-normal"
            placeholder="Cari Kelas"
            iconSize="20"
            setValue={setSearch}
            value={search}
          />
        </div>
        <div className={Styles.buttonWrapper}>
          <Button
            text={"Kirim Semua"}
            startIcon={"sms-tracking"}
            disabled={data.length < 1}
            onClick={() => {
              setModalSelect(true);
              setDataToSend(
                data.map((el) => ({
                  name: el.Name,
                  phoneNumber: el.Phone_Number,
                  voucherCode: el.Voucher_Code,
                }))
              );
            }}
          />
          <Button
            text={"Import Data Peserta"}
            startIcon={"document-upload-filled"}
            variant="outlined"
            onClick={() => setOpenModal(true)}
          />
          <div
            className={Styles.buttonClear}
            onClick={() => setData([])}
            is-disabled={data.length < 1 ? "true" : "false"}
          >
            <Icon icon={"trash"} size={16} />
            <p>Clear Data</p>
          </div>
        </div>
      </div>
      <div className={Styles.bodySection}>
        <Table
          data={template?.data}
          totalData={data?.length}
          totalPage={Math.ceil(data.length / limit)}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table} ${
            !template?.data?.length ? Styles.grow : ""
          }`}
          limit={limit}
          setLimit={setLimit}
          withPagination
          withNumbering
        />
      </div>
      <Modal isOpen={openModal}>
        <InputFileModal
          onClose={() => setOpenModal(false)}
          text="Peserta"
          handleSubmit={(file) => handleFetchData(file)}
        />
      </Modal>
      <Modal isOpen={modalSelect}>
        <SelectNotifTemplateModal
          list={dataTemplateNotif}
          onClose={() => setModalSelect(false)}
          handleSubmit={(name) => handleSendMessage(name)}
        />
      </Modal>
      <Modal isOpen={success}>
        <SuccessNotifModal handleClose={() => setSuccess(false)} to={success} />
      </Modal>
    </div>
  );
}
