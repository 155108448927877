import React, { useMemo, useState } from "react";
import Styles from "./style.module.scss";
import InputField from "@Molecule/InputField";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import Table from "@Molecule/Table";
import InputFileModal from "@Molecule/InputFileModal";
import Modal from "@Atom/Modal";
import { sendMessageRegister, updateDataNotifRegister } from "@Services/admin";
import SelectNotifTemplateModal from "@Molecule/SelectNotifTemplateModal";
import SuccessNotifModal from "@Molecule/SuccessNotifModal";

const dataTemplateRegister = [
  {
    id: "4cdc6113-20ff-4d81-aaea-34ce4d02cf34",
    organization_id: "5dad9447-e7e7-4f63-bdac-cf163a57fd78",
    name: "register",
    language: "id",
    header: null,
    body: "Hallo kak, terima kasih sudah mempercayakan Skills.id sebagai lembaga pelatihan Prakerjamu. \n\nSaat ini kami bantu cek, kakak belum mendaftarkan akun di skills.id/register ya, silakan registrasi sekarang ya kak. Karena seluruh kegiatan pelatihan akan berlangsung di website skills.id. \n\nPenukaran kode voucher dan kode redeem dapat dilakukan 1 jam sebelum pelatihan dimulai, *Pastikan tidak terlambat menukarkan*. Mohon konfirmasikan pada kami jika sudah berhasil melakukan registrasi akun dan jika ada kendala bisa membalaskan chat ini.\n\nTerima kasih ☺️",
    footer: null,
    buttons: [],
    status: "APPROVED",
    category: "UTILITY",
    quality_rating: null,
    quality_rating_text: "Neutral",
  },
  {
    id: "4cdc6113-20ff-4d81-aaea-34ce4d02cf34",
    organization_id: "5dad9447-e7e7-4f63-bdac-cf163a57fd78",
    name: "registerOne",
    language: "id",
    header: null,
    body: "Hallo kak, terima kasih sudah mempercayakan Skills.id sebagai lembaga pelatihan Prakerjamu. \n\nSaat ini kami bantu cek, kakak belum mendaftarkan akun di skills.id/register ya, silakan registrasi sekarang ya kak. Karena seluruh kegiatan pelatihan akan berlangsung di website skills.id. \n\nPenukaran kode voucher dan kode redeem dapat dilakukan 1 jam sebelum pelatihan dimulai, *Pastikan tidak terlambat menukarkan*. Mohon konfirmasikan pada kami jika sudah berhasil melakukan registrasi akun dan jika ada kendala bisa membalaskan chat ini.\n\nTerima kasih ☺️",
    footer: null,
    buttons: [],
    status: "APPROVED",
    category: "UTILITY",
    quality_rating: null,
    quality_rating_text: "Neutral",
  },
  {
    id: "d252dabd-4c72-402d-b58c-a28b414b10e8",
    organization_id: "5dad9447-e7e7-4f63-bdac-cf163a57fd78",
    name: "registerThree",
    language: "id",
    header: null,
    body: "Hallo Kak, terima kasih sudah mempercayakan Skills.id sebagai lembaga pelatihan untuk Prakerjamu.\n\nSaat ini kami bantu pengecekan, kakak melakukan pembelian pelatihan di Skills.id. Untuk itu silakan melakukan registrasi akun terlebih dahulu di Skills.id dengan nomor yang digunakan pada Prakerjamu.\n\nSebagai informasi, kode voucher dan kode redeem dapat ditukarkan 1 jam sebelum pelatihan dimulai dan maksimal 1 jam setelah pelatihan dimulai. Contoh: Pelatihan dimulai pukul 16.00 WIB , maka kakak sudah bisa melakukan penukaran kode voucher pada pukul 15.00 WIB dan maksimal 17.00 WIB. Jika melewati jam penukaran, kode voucher tidak dapat digunakan lagi. \n\nJika ada yang ingin ditanyakan, silakan membalaskan chat ini. Terima kasih ☺️ ",
    footer: null,
    buttons: [
      {
        url: "https://skills.id/register",
        text: "Registrasi disini",
        type: "URL",
      },
    ],
    status: "APPROVED",
    category: "UTILITY",
    quality_rating: null,
    quality_rating_text: "Neutral",
  },
  {
    id: "f7bc51ad-bd76-4559-a558-d98df64ed5c7",
    organization_id: "5dad9447-e7e7-4f63-bdac-cf163a57fd78",
    name: "registerFour",
    language: "id",
    header: null,
    body: 'Hallo Kak, terima kasih sudah mempercayakan Skills.id sebagai lembaga pelatihan untuk Prakerjamu. \n\nSaat ini kami bantu pengecekan, kakak melakukan pembelian pelatihan di Skills.id. Untuk itu silakan melakukan registrasi akun terlebih dahulu di Skills.id/register dengan nomor/email yang digunakan pada Prakerjamu. \n\nSebagai informasi, kode voucher dan kode redeem dapat ditukarkan 1 jam sebelum pelatihan dimulai. Pastikan peserta hadir tepat waktu di lokasi pelatihan. Saat tiba di lokasi pelatihan, peserta wajib melakukan absensi melalui barcode yang ada di akun Prakerjamu untuk status berubah "Terverifikasi".\n\nJika melewati jam penukaran, kode voucher tidak dapat digunakan lagi. Jika ada yang ingin ditanyakan, silakan membalaskan chat ini. Terima kasih ☺️',
    footer: null,
    buttons: [],
    status: "APPROVED",
    category: "UTILITY",
    quality_rating: null,
    quality_rating_text: "Neutral",
  },
];

export default function RegisterNotif() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalSelect, setModalSelect] = useState(false);
  const [dataToSend, setDataToSend] = useState([]);
  const [success, setSuccess] = useState(false);

  const template = useMemo(() => {
    return {
      data: data.slice(limit * (page - 1), limit * page),
      columns: [
        {
          name: "Name",
          title: "Judul Kelas",
        },
        {
          name: "",
          title: "",
          renderData: (row) => (
            <div className={Styles.buttonSendTable}>
              <Button
                text={"Kirim Reminder"}
                endIcon={"document-upload-filled"}
                variant="outlined"
                onClick={() => {
                  setModalSelect(true);
                  setDataToSend([
                    { name: row.Name, phoneNumber: row.Phone_Number },
                  ]);
                }}
              />
            </div>
          ),
          hideOnMobile: true,
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, data]);

  const handleFetchData = async (file) => {
    try {
      const res = await updateDataNotifRegister(file);
      setDataToSend([]);
      if (res.status) {
        setData(res.data);
        setOpenModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendMessage = async (name) => {
    const payload = {
      data: dataToSend,
      templateId: name,
    };
    try {
      const res = await sendMessageRegister(payload);
      if (res.status) {
        if (payload.data.length > 1) {
          setSuccess("Seluruh Murid");
        } else {
          setSuccess(dataToSend[0].name);
        }
        setModalSelect(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.controlSection}>
        <div className={Styles.searchBar}>
          <InputField
            startIcon="search-normal"
            placeholder="Cari Kelas"
            iconSize="20"
            setValue={setSearch}
            value={search}
          />
        </div>
        <div className={Styles.buttonWrapper}>
          <Button
            text={"Kirim Semua"}
            startIcon={"sms-tracking"}
            disabled={data.length < 1}
            onClick={() => {
              setModalSelect(true);
              setDataToSend(
                data.map((el) => ({
                  name: el.Name,
                  phoneNumber: el.Phone_Number,
                }))
              );
            }}
          />
          <Button
            text={"Import Data Peserta"}
            startIcon={"document-upload-filled"}
            variant="outlined"
            onClick={() => setOpenModal(true)}
          />
          <div
            className={Styles.buttonClear}
            onClick={() => setData([])}
            is-disabled={data.length < 1 ? "true" : "false"}
          >
            <Icon icon={"trash"} size={16} />
            <p>Clear Data</p>
          </div>
        </div>
      </div>
      <div className={Styles.bodySection}>
        <Table
          data={template?.data}
          totalData={data?.length}
          totalPage={Math.ceil(data.length / limit)}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table} ${
            !template?.data?.length ? Styles.grow : ""
          }`}
          limit={limit}
          setLimit={setLimit}
          withPagination
          withNumbering
        />
      </div>
      <Modal isOpen={openModal}>
        <InputFileModal
          onClose={() => setOpenModal(false)}
          text="Peserta"
          handleSubmit={(file) => handleFetchData(file)}
        />
      </Modal>
      <Modal isOpen={modalSelect}>
        <SelectNotifTemplateModal
          list={dataTemplateRegister}
          onClose={() => setModalSelect(false)}
          handleSubmit={(name) => handleSendMessage(name)}
        />
      </Modal>
      <Modal isOpen={success}>
        <SuccessNotifModal handleClose={() => setSuccess(false)} to={success} />
      </Modal>
    </div>
  );
}
