import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import GeneralInfoDetail from '@Molecule/GeneralInfoDetail'
import InputField from '@Molecule/InputField'
import { useState } from 'react'
import Styles from './style.module.scss'
import QuizCard from '@Atom/QuizCard'

export default function CurriculumCard({
  cardTitle,
  data
}) {
  const [expand, setExpand] = useState(false)

  // const datae = {
  //   title: '',
  //   description: '',
  //   subjects: [
  //     {
  //       title: '',
  //       files: [
  //         // {File}
  //       ]
  //     }
  //   ],

  // }

  return (
    <div className={`${Styles.container} ${expand ? Styles.expand : ''}`} onClick={() => !expand && setExpand(true)}>
      <div className={Styles.header} onClick={() => setExpand(!expand)}>
        <h4>{cardTitle}</h4>
        <Icon icon={'arrow-down'} size={24} style={{ transform: expand ? 'rotate(180deg)' : 'none' }} />
      </div>
      {
        expand
        &&
        <div className={Styles.content}>
          <div className={Styles.section}>
            <GeneralInfoDetail
              title={'Judul Sesi'}
              description={data?.title || '-'}
            />
            <GeneralInfoDetail
              title={'Deskripsi'}
              description={data?.description || '-'}
            />
          </div>
          {
            !!data?.subjects?.length
            &&
            data?.subjects?.map((s, sI) => (
              <div key={sI} className={`${Styles.section}`}>
                <h3>{`Materi ${sI + 1}`}</h3>
                <GeneralInfoDetail
                  title={'Judul Materi'}
                  description={s?.title || '-'}
                />
                {
                  s?.files?.map((f, fI) => (
                    <InputField
                      isFile
                      title={fI === 0 ? `File Materi ${sI + 1}` : ''}
                      value={f}
                      readOnly

                    />
                  ))
                }
              </div>
            ))
          }
          {
            !!data?.quizes?.length
            &&
            data?.quizes?.map((q, qI) => (
              <div className={`${Styles.section} ${qI = data?.quizes?.length - 1 ? Styles.noBorder : ''}`} key={qI}>
                <h3>{`Kuis ${qI + 1}`}</h3>
              <QuizCard data={q}/>
              </div>
            ))
          }
          <div className={Styles.closeWrapper}>
            <Button
              text={'Tampilkan Lebih Sedikit'}
              variant='text'
              onClick={()=> setExpand(false)}
              endIcon={expand ? 'arrow-up' : 'arrow-down'}
            />
          </div>

        </div>

      }
    </div>
  )
}