import CertificateLayout from "@Organism/Certificate";
import { getCertificateWithQr } from "@Services/certificate";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
export async function loader({ params }) {
  const { id } = params;
  const getData = getCertificateWithQr(id);
  return defer({
    getCertificate: getData,
  });
}
const CertificatePage = () => {
  const { getCertificate } = useLoaderData();

  return (
    <Suspense fallback={<p>loading....</p>}>
      <Await
        resolve={getCertificate}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <CertificateLayout data={value} />}
      </Await>
    </Suspense>
  );
};

export default CertificatePage;
