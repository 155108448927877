/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import arrowAccordion from "@Assets/Icon/arrowAccordion.svg";
import Button from "@Atom/Button";
export default function AccordionLandingPage() {
  const question = useMemo(() => {
    return [
      {
        question: "Bagaimana Cara Melakukan Pembelian Pelatihan Kelas Prakerja",
        answer: (
          <div>
            <br />
            Untuk pembelian pelatihan menggunakan prakerja. Silakan mengikuti
            langkah-langkah berikut:
            <ol>
              <li>
                Silakan kunjungi media platform seperti Tokopedia,Pintaria atau
                Bukalapak
              </li>
              <li>
                Ketik "Kartu Prakerja" di kolom pencarian dan pilih lembaga
                Pelatihan “Skills.id”
              </li>
              <li>
                Klik pelatihan yang diminati dan pilih jadwal serta lokasi yang
                tersedia 
              </li>
              <li>
                Masukan 16 digit nomor Kartu Prakerja dan lakukan verifikasi
                melalui SMS
              </li>
              <li>
                Setelah transaksi berhasil, cek redemption code pada email
                peserta kartu prakerja atau dashboard kartu prakerja
              </li>
              <li>
                Lakukan penukaran kode voucher dan redeem code di laman
                Skills.id 
              </li>
              <li>
                Setelah berhasil login, masukkan kode voucher yang di dapatkan
                dari Mitra Platform Prakerja pada kolom yang sudah disediakan,
                lalu klik Tukarkan sekarang
              </li>
              <li>
                Klik jadwal sesuai dengan yang kamu pilih pada platform, lalu
                tukarkan kode voucher
              </li>
              <li>
                Setelah itu kamu akan diarahkan ke halaman kelas saya klik
                “Lanjutkan belajar” dan akan muncul permintaan untuk memasukan
                kode redeem yang bisa kamu temukan di dashboard prakerja dan
                klik “Redeem sekarang”.
              </li>
              <li>
                Setelah penukaran kode redeem berhasil dilakukan, kamu akan
                diarahkan untuk login ke akun prakerjamu untuk melakukan scan
                wajah, pastikan mengikuti instruksinya ya! 
              </li>
              <br />
            </ol>
            <span>
              (Penukaran dapat dilakukan 1 jam sebelum pelatihan dimulai dan
              maksimal 1 jam setelah pelatihan dimulai)
            </span>
          </div>
        ),
      },
      {
        question: "Cara melihat kode voucher untuk peserta kartu Prakerja",
        answer: (
          <div>
            <span>
              <br />
              Berikut Langkah – Langkah untuk melihat kode voucher yang sudah
              kamu beli di Platform digital :
              <br />
            </span>
            <br />
            <span>A. Untuk melihat Kode Voucher Dari Tokopedia:</span>
            <ol>
              <li>Masuk ke aplikasi Tokopedia </li>
              <li>Klik “Daftar Transaksi”</li>
              <li>Klik “Lihat Detail Transaksi”</li>
              <li>Kode voucher akan ada di detail pembelian.  </li>
            </ol>
            <br />
            <span>B. Untuk Melihat Kode Voucher Dari Bukalapak:</span>

            <ol>
              <li>Buka Aplikasi Bukalapak</li>
              <li>Klik menu “ Transaksi”</li>
              <li>Klik Transaksi pembelian kelas Skills.id</li>
              <li>Scroll ke bawah dan cari kode kupon</li>
            </ol>
            <br />
            <span>
              Atau kamu juga bisa cek kode vouchernya di email yang dikirimkan
              oleh pihak Mitra Platform digital ( Tokopedia, Bukalapak dan
              PINTAR).
            </span>
          </div>
        ),
      },
      {
        question: "Bagaimana cara mendapatkan Redeem code",
        answer: (
          <div>
            <span>
              <br />
              Cara mendapatkan kode redeem :
            </span>
            <span>
              <br />
              kamu bisa log in ke akun Prakerjamu lalu klik menu “Pelatihan” dan
              lihat “Riwayat pelatihan” lalu temukan kode redeem atau kamu bisa
              lihat di email yang dikirimkan oleh Prakerja. Kamu sudah bisa
              menukarkan kode redeem mu 1 jam sebelum pelatihan dimulai dan
              maksimal 1 jam setelah pelatihan dimulai.
              <br />
              <br />
            </span>
            <span>
              Untuk kelas luring kamu wajib melakukan scan QR code pada hari
              pertama pelatihan dan dilakukan langsung di lokasi pelatihan.
              Pelatihan akan dimulai dengan mengerjakan pretest dan diakhiri
              dengan pengerjaan uji keterampilan. 
              <br />
            </span>

            <br />
            <span>
              Pelatihan dapat diselesaikan jika kelas daring (online)
              kehadirannya sudah mencapai 80% dan kelas luring/ offline harus
              100% .
            </span>
          </div>
        ),
      },
      {
        question:
          "Cara mengakses dan menyelesaikan pelatihan yang sudah dibeli",
        answer: (
          <div>
            <span>
              <br />
              Untuk mengikuti pelatihan di Skills.ID kamu bisa mengikuti Langkah
              - Langkah berikut : <br />
            </span>
            <ol>
              <li>
                Silahkan klik link:{" "}
                <a href="https://skills.id/register" islinktext="true">
                  <span>https://skills.id/register</span>
                </a>{" "}
                untuk melakukan registrasi akun di skills.id . Isi semua data
                dengan benar.
              </li>
              <li>
                ⁠Cek nomor whatsapp untuk kode OTP dan verifikasi akun. Lalu
                masukkan username dan password kamu, klik tombol log in.
              </li>
              <li>
                Setelah berhasil Log in, segera tukarkan kode voucher kamu di
                kolom yang telah disediakan. Kemudian klik tombol "Tukarkan
                Sekarang".
              </li>
              <li>
                ⁠Masukan kode voucher dari Mitra platform seperti Tokopedia,
                Pintar dan Bukalapak di kolom laman awal Skills.ID, lalu klik
                “Tukarkan sekarang” .
              </li>
              <li>
                Lalu pilih jadwal pelatihan sesuai dengan jadwal yang kamu pilih
                di Mitra Platform dan klik “Tukarkan kode Voucher”
              </li>
              <li>
                Lalu kamu akan diarahkan kehalaman kelas saya klik “Lanjutkan
                belajar” dan akan muncul permintaan untuk memasukan kode redeem
                yang bisa kamu temukan di dashboard prakerja dan klik “Redeem
                sekarang”.
              </li>
              <li>
                Setelah penukaran kode redeem berhasil dilakukan, kamu akan
                diarahkan untuk login ke akun prakerjamu untuk melakukan scan
                wajah, pastikan mengikuti instruksinya ya!
              </li>
              <li>
                Kerjakan pretest hingga uji keterampilan untuk bisa
                menyelesaikan pelatihan dan dapatkan sertifikat serta
                insentifmu. 
              </li>
            </ol>
            <span>
              Selesai, pelatihan bisa dimulai sesuai jadwal yang dipilih.
              Selamat mengikuti Pelatihanmu!
              <br />
            </span>
            <span>
              <br />
              Atau kamu bisa melihat video Langkah-langkah penukarannya melalui
              link berikut :
              <a
                href="https://bit.ly/Penukaranskillsxprakerja"
                islinktext="true"
              >
                <span>https://bit.ly/Penukaranskillsxprakerja</span>
              </a>
            </span>
          </div>
        ),
      },
      {
        question: "Kapan peserta harus melakukan verifikasi muka?",
        answer: (
          <div>
            <strong>
              {" "}
              <br />
              A. Pelatihan Webinar
            </strong>
            <ul>
              <li>Redeem pelatihan atau sebelum mulai pelatihan;</li>
              <li>
                Setiap sebelum pertemuan/ sesi baru dimulai dan sebelum post
                test.
              </li>
              <li>
                Scan wajah bisa dilakukan 1 jam sebelum pelatihan dimulai dan
                maksimal 1 jam setelah pelatihan dimulai
              </li>
            </ul>
            <strong>B. Pelatihan SPL (Self-Paced Learning)</strong>
            <ul>
              <li>Redeem pelatihan atau sebelum mulai pelatihan;</li>
              <li>Setiap sebelum sesi pelatihan dan sebelum post test.</li>
              <li>
                Scan wajah bisa dilakukan 1 jam sebelum pelatihan dimulai dan
                maksimal 1 jam setelah pelatihan dimulai
              </li>
            </ul>
            <strong>C. Pelatihan Offline</strong>
            <ul>
              <li>Setiap sebelum pertemuan dimulai.</li>
              <li>
                Scan wajah bisa dilakukan 1 jam sebelum pelatihan dimulai dan
                maksimal 2 jam setelah pelatihan dimulai
              </li>
            </ul>
            <span>
              <br />
              Untuk tutorial melakukan scan wajah, peserta bisa lihat di link
              berikut. (Ingat, scan wajah bersifat WAJIB!) :
              <a
                href="#"
                islinktext="true"
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/watch?v=sVhcS-EV-Tw&feature=youtu.be"
                  )
                }
              >
                <span>
                  https://www.youtube.com/watch?v=sVhcS-EV-Tw&feature=youtu.be 
                </span>
              </a>
            </span>
          </div>
        ),
      },
    ];
  }, []);

  const [isOpenArray, setIsOpenArray] = useState(
    new Array(question.length).fill(false)
  );

  const handleToggle = useCallback((index) => {
    setIsOpenArray((prevIsOpenArray) => {
      const updatedIsOpenArray = [...prevIsOpenArray];
      updatedIsOpenArray[index] = !updatedIsOpenArray[index];
      return updatedIsOpenArray;
    });
  }, []);

  return (
    <section className={Styles.container} >
      <div className={Styles.wrapperAccordion}>
        {question.map((item, idx) => (
          <main key={idx}>
            <div className={Styles.contain}>
              <div
                className={Styles.isClickIndicator}
                onClick={() => handleToggle(idx)}
              >
                <p active={isOpenArray[idx] ? "true" : "false"}>
                  {item.question}{" "}
                </p>
                <img
                  src={arrowAccordion}
                  active={isOpenArray[idx] ? "true" : "false"}
                  alt="arrow"
                />
              </div>
              {isOpenArray[idx] && (
                <p className={Styles.answer}>{item.answer}</p>
              )}
            </div>
            <div className={Styles.lineSeperator}></div>
          </main>
        ))}
      </div>
      <a href={"faq"}>
        <Button text={"Lihat Selengkapnya"} />
      </a>
    </section>
  );
}
