import Button from "@Atom/Button";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import Icon from "@Atom/Icon";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Searchbar from "@Atom/Searchbar";
import Table from "@Molecule/Table";
import Avatar from "@Atom/Avatar";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "@Molecule/Footer";
import useQuery from "@Hooks/useQuery";
import { useDebounce } from "@Hooks/useDebounce";
import Modal from "@Atom/Modal";
import LogoBlendQ from "@Assets/Images/blend-question.png";
import WomanAndBook from "@Assets/Images/woman-and-book.png";
import { publishClass } from "@Services/admin";
import Lottie from "lottie-react";
import loadingPublish from "@Assets/animation/loadingClass.json";
export default function AdminClassLayout({ dataPublished, dataDraft }) {
  const user = decryptStorageData("user", localStorage);
  const navigate = useNavigate();
  const [classType, setClassType] = useState(0);
  const [tableTab, setTableTab] = useState(0);
  const query = useQuery();
  const limitQuery = query.get("limit") || 10;
  const searchQuery = query.get("search") || "";
  const pageQuery = query.get("page") || 1;
  const [searchTerm, setSearchTerm] = useState(searchQuery);
  const [page, setPage] = useState(pageQuery);
  const [limit, setLimit] = useState(limitQuery);
  const { pathname } = useLocation();
  const debouncedTrigger = useDebounce(searchTerm, 500);
  useEffect(() => {
    navigate(
      `${pathname}?q=q&classType=${
        classType === 0 ? "PRAKERJA" : "GENERAL"
      }&tableTab=${tableTab}&limit=${limit}&page=${page}&search=${searchTerm}`,
      {
        replace: true,
      }
    );
  }, [
    page,
    limit,
    navigate,
    pathname,
    debouncedTrigger,
    classType,
    searchTerm,
    tableTab,
  ]);

  useEffect(() => {
    setPage(1)
  }, [tableTab, classType])
  

  const dataToShow = useMemo(() => {
    if (tableTab === 0) {
      return dataPublished;
    } else {
      return dataDraft;
    }
  }, [tableTab, dataPublished, dataDraft]);

  const [isNull, setIsNull] = useState(false);
  const [selectedClassDetail, setSelectedClassDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCloseModal, setisCloseModal] = useState(false);
  const publishClassByID = useCallback(
    async (courseID) => {
      setIsLoading(true);
      try {
        const response = await publishClass(courseID);
        handleCloseModal();
        navigate(
          `${pathname}?q=q&classType=${
            classType === 0 ? "PRAKERJA" : "GENERAL"
          }&tableTab=${tableTab}&limit=${limit}&page=${page}&search=${searchTerm}`,
          { replace: true }
        );
        console.log(response);
        setIsLoading(false);
        setIsNull(false);
      } catch (error) {
        setIsNull(true);
        console.log(error);
        handleCloseModal();
        setIsLoading(false);
        setIsOpenModalFirst(false);
        setisCloseModal(true);
      }
    },
    [classType, limit, navigate, page, pathname, searchTerm, tableTab]
  );
  const [selectedID, setSelectedID] = useState(null);
  useEffect(() => {
    if (selectedID) {
      const selectedClass = dataToShow?.data?.find(
        (el) => el._id === selectedID
      );
      setSelectedClassDetail(selectedClass);
    }
  }, [selectedID, dataToShow?.data]);
  const handleOpenModal = (id) => {
    setSelectedID(id);
    setIsOpenModalFirst(true);
  };

  const handleCloseModal = () => {
    setSelectedID(null);
    setIsOpenModalFirst(false);
    setisCloseModal(false);
  };
  const [isOpenModalFirst, setIsOpenModalFirst] = useState(false);
  
  const template = useMemo(() => {
    return {
      data: dataToShow.data?.map((el) => ({
        id: el?._id,
        title: el?.title,
        instructorName: el?.instructor?.name,
        typeClass: el?.platform,
        createdAt: el?.createdAt,
        isAvailable: el?.isAvailable || false,
        isPublished: el?.isPublished || false,
      })),
      columns: [
        {
          name: "title",
          width: "300px",
          title: "Judul Kelas",
          renderData: (row) => (
            <span
              className={Styles.ellipsisTwoColumn}
              onClick={() => navigate(`${row.id}`)}
            >
              {row.title}
            </span>
          ),
        },
        {
          name: "",
          width: "200px",
          title: "Instruktur",
          renderData: (row) => (
            <div className={`${Styles.instructor} ${Styles.ellipsis}`}>
              <Avatar
                imgUrl={row?.instructor?.profilePicture}
                iconName="user"
                iconSize="12px"
                size="24px"
              />
              <span className={Styles.ellipsisOneColumn}>
                {row?.instructorName}
              </span>
            </div>
          ),
          hideOnMobile: true,
        },
        {
          name: "typeClass",
          title: "Tipe Kelas",
          hideOnMobile: true,
        },
        {
          name: "",
          title: "Tgl. Pembuatan",
          renderData: (row) => (
            <span>{moment(new Date(row?.createdAt)).format("ll")}</span>
          ),
          hideOnMobile: true,
        },

        {
          name: "",
          title: tableTab === 0 && "Jadwal Kelas",
          renderData: (row) =>
            tableTab === 0 && (
              <span
                className={Styles.classSchedule}
                is-available={row?.isAvailable?.toString()}
              >
                {row?.isAvailable ? "Tersedia" : "Habis"}
              </span>
            ),
          hideOnMobile: true,
        },

        {
          name: "",
          title: "",
          renderData: (row) => (
            <div
              className={Styles.published}
              is-published={row?.isPublished?.toString()}
              onClick={() => handleOpenModal(row?.id)}
            >
              <Icon
                icon={"document-upload-filled"}
                color={row?.isPublished ? "#D1421A" : "#9360A8"}
                size={"20"}
              />
              <span> {tableTab === 0 ? "Unpublish" : "Publish"} </span>
            </div>
          ),
          hideOnMobile: true,
        },
      ],
    };
  }, [dataToShow.data, tableTab, navigate]);
  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <DoodleGradientCard className={Styles.header}>
          <div className={Styles.title}>
            <h3>Selamat datang {<span>{user?.name}</span>}</h3>
            <span>
              Berikut adalah daftar seluruh kursus/kelas yang kamu punya.
            </span>
          </div>
          <button onClick={() => navigate("new")}>
            <Icon icon={"plus"} size={10} />
            <span>Tambah Kelas</span>
          </button>
        </DoodleGradientCard>
        <div className={Styles.tabs}>
          <Button
            text={"Kelas Prakerja"}
            variant="tab"
            isActive={classType === 0}
            onClick={() => setClassType(0)}
          />
          <Button
            text={"Kelas Reguler"}
            variant="tab"
            isActive={classType === 1}
            onClick={() => setClassType(1)}
          />
        </div>
        <div className={Styles.content}>
          <div className={Styles.contentTabs}>
            <Button
              variant="table-tab"
              text={"Published"}
              dataLength={dataPublished.totalData}
              isActive={tableTab === 0}
              onClick={() => setTableTab(0)}
            />
            <Button
              variant="table-tab"
              text={"Draft"}
              dataLength={dataDraft.totalData}
              isActive={tableTab === 1}
              onClick={() => setTableTab(1)}
            />
          </div>
          <div className={Styles.contentFilter}>
            <Searchbar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder="Cari kelas/instruktur"
            />
            <Button
              text={"Filter"}
              startIcon={"setting-4"}
              variant="neutral-outlined-2"
            />
          </div>

          <Table
            data={template?.data}
            totalData={dataToShow?.totalData}
            totalPage={Math.ceil(dataToShow?.totalData / limit)}
            columns={template?.columns}
            page={page}
            setPage={setPage}
            className={`${Styles.table} ${
              !template?.data?.length ? Styles.grow : ""
            }`}
            limit={limit}
            setLimit={setLimit}
            withPagination
            isLoading={isLoading}
            expandComponent={(row) => (
              <div className={Styles.expandedInfo}>
                <div className={Styles.infoRow}>
                  <span>Instruktur</span>
                  <div className={Styles.instructor}>
                    <Avatar
                      imgUrl={row?.instructor?.profilePicture}
                      iconName="user"
                      iconSize="12px"
                      size="24px"
                    />
                    <span>{row?.instructorName}</span>
                  </div>
                </div>
                <div className={Styles.infoRow}>
                  <span>Tipe Kelas</span>
                  <span>{row?.typeClass}</span>
                </div>
                <div className={Styles.infoRow}>
                  <span>Tgl. Pembuatan</span>
                  <span>{moment(new Date(row?.createdAt)).format("ll")}</span>
                </div>
                {tableTab === 0 && (
                  <div className={`${Styles.infoRow}`}>
                    <span>Jadwal Kelas</span>
                    <span
                      className={Styles.classSchedule}
                      is-available={row?.isAvailable?.toString()}
                    >
                      {row?.isAvailable ? "Tersedia" : "Habis"}
                    </span>
                  </div>
                )}
                <div
                  className={`${Styles.published} infoRow`}
                  style={{ marginTop: "6px" }}
                  is-published={row?.isPublished?.toString()}
                  onClick={() => handleOpenModal(row?.id)}
                >
                  <Icon
                    icon={"document-upload-filled"}
                    color={row?.isPublished ? "#D1421A" : "#9360A8"}
                    className={Styles.iconUpload}
                  />
                  <span>{tableTab === 0 ? "Unpublish" : "Publish"}</span>
                </div>
              </div>
            )}
          />
        </div>
      </div>

      {isOpenModalFirst && (
        <Modal isOpen={true}>
          {isLoading ? (
            <div className={Styles.lottieLoad}>
              <Lottie
                animationData={loadingPublish}
                loop={true}
                className={Styles.lottie}
              />
              <p>Harap Tunggu...</p>
            </div>
          ) : (
            <div className={Styles.modalPublished}>
              <h2>
                {selectedClassDetail?.isPublished
                  ? "Unpublish Kelas ?"
                  : "Publish Kelas ?"}
              </h2>
              <img src={LogoBlendQ} className={Styles.blend} alt="modal-logo" />
              <p className={Styles.descModal}>
                {`Apakah kamu yakin ingin melakukan ${
                  tableTab === 0 ? "Unpublish" : "Publish"
                } untuk kelas “${selectedClassDetail?.title}”?`}
              </p>
              <div className={Styles.btnModal}>
                <div onClick={() => handleCloseModal()}>
                  <span>Batal</span>
                </div>
                <Button
                  className={Styles.btnRight}
                  text={"Ya"}
                  onClick={() => publishClassByID(selectedID)}
                />
              </div>
            </div>
          )}
        </Modal>
      )}

      {isNull && isCloseModal && (
        <Modal isOpen={isNull && isCloseModal}>
          <div className={Styles.modalPublished}>
            <h2>Data Kelas Belum Lengkap</h2>
            <img src={WomanAndBook} className={Styles.blend} alt="modal-logo" />
            <p className={Styles.descModal}>
              {`Data kelas “${selectedClassDetail?.title}” belum lengkap, silahkan lengkapi data kelas terlebih dahulu untuk dapat melakukan publish pada kelas ini.`}
            </p>
            <div className={Styles.btnModal}>
              <div onClick={() => handleCloseModal()}>
                <span>Batal</span>
              </div>
              <Button
                className={Styles.btnRight}
                text={"Lengkapi Data"}
                onClick={() =>
                  navigate(
                    `/classes/edit/${selectedClassDetail?.platform?.toLowerCase()}/${
                      selectedClassDetail?._id
                    }`
                  )
                }
              />
            </div>
          </div>
        </Modal>
      )}
      <Footer />
    </div>
  );
}
