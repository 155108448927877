import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/index";

export default function ModalSelectCertificate({
  onClose,
  data,
  handleSelect,
}) {
  return (
    <div className={Styles.container}>
      <div className={Styles.modalHeader}>
        <p>Pilih Template Sertifikat</p>
        <Icon icon={"cross"} size={24} onClick={() => onClose()} />
      </div>
      <div className={Styles.cardWrapper}>
        {data.map((el, idx) => (
          <div
            className={Styles.certificateCard}
            key={idx}
            onClick={() => handleSelect(el)}
          >
            <p>{el.name}</p>
            <img src={fileBaseUrl + el.file} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}
