import DoodleGradientCard from "@Atom/DoodleGradientCard";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Footer from "@Molecule/Footer";
import Table from "@Molecule/Table";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import useWindowSize from "@Hooks/useWindowSize";
import Searchbar from "@Atom/Searchbar";
import useQuery from "@Hooks/useQuery";
import { useDebounce } from "@Hooks/useDebounce";

export default function ListStudentLayout({ dataStudent }) {
  const user = decryptStorageData("user", localStorage);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const query = useQuery();
  const pageQuery = query?.get("page");
  const limitQuery = query?.get("limit");
  useEffect(() => {
    setPage(+pageQuery || 1);
    setLimit(+limitQuery || 10);
  }, [limitQuery, pageQuery]);
  useEffect(() => {
    setData(dataStudent);
  }, [dataStudent]);
  const { width } = useWindowSize();

  const debouncedSearchQuery = useDebounce(searchTerm, 500);
  useEffect(() => {
    if (debouncedSearchQuery?.length > 1) {
      setLoading(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [debouncedSearchQuery]);
  useEffect(() => {
    if (searchTerm) {
      // const prevPage = Math.min(dataStudent?.totalData * limit, page);
      navigate(
        `${pathname}?limit=${limit}&page=${page}&search=${debouncedSearchQuery}`,
        {
          replace: true,
        }
      );
    } else {
      navigate(`${pathname}?limit=${limit}&page=${page}`, {
        replace: true,
      });
    }
  }, [
    data.totalData,
    dataStudent?.totalData,
    debouncedSearchQuery,
    limit,
    navigate,
    page,
    pathname,
    searchTerm,
  ]);

  const template = useMemo(() => {
    return {
      data: data?.map((obj) => {
        return {
          ...obj,
          id: obj?._id,
        };
      }),
      // .filter((el) =>
      //   el.name.toLowerCase().includes(searchTerm.toLowerCase())
      // )
      // .slice(limit * (page - 1), limit * page),
      columns: [
        {
          name: "",
          title: "Nama Peserta",
          width: width > 768 ? "455px" : "200px",
          renderData: (row) => (
            <div
              className={Styles.student}
              onClick={() => navigate(`detail/${row?._id}`)}
            >
              <span>{row?.name}</span>
            </div>
          ),
        },
        {
          name: "email",
          title: "Email",
          hideOnMobile: true,
        },
        {
          name: "phoneNumber",
          title: "No Telpon",
          hideOnMobile: true,
        },
      ],
    };
  }, [data, width, navigate]);

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <DoodleGradientCard className={Styles.header}>
          <div className={Styles.title}>
            <h3>Selamat datang {<span>{user?.name}</span>}</h3>
            <span>
              Berikut adalah daftar student yang telah mendaftar di skills.id
            </span>
          </div>
        </DoodleGradientCard>
        <div style={{ width: "100%" }}>
          <Searchbar
            value={searchTerm}
            setValue={setSearchTerm}
            placeholder="Cari Peserta"
          />
        </div>
        <Table
          data={template?.data}
          totalData={data?.length}
          totalPage={Math.ceil(data.length / limit)}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table} ${
            !template?.data?.length ? Styles.grow : ""
          }`}
          limit={limit}
          setLimit={setLimit}
          withPagination
          isLoading={loading}
          withNumbering
          expandComponent={(row) => (
            <div className={Styles.expandedInfo}>
              <div className={Styles.infoRow}>
                <span>Email</span>
                <span>{row?.email}</span>
              </div>
              <div className={Styles.infoRow}>
                <span>No. Telepon</span>
                <span>{row?.phoneNumber}</span>
              </div>
            </div>
          )}
        />
      </div>

      <Footer />
    </div>
  );
}
