import CheckBox from "@Atom/Checkbox";
import React from "react";
import style from "./index.module.scss";
import Text from "@Atom/Text";
export default function CheckboxOption({ textCheckbox, isChecked, onClick }) {
  return (
    <div onClick={onClick} className={style.wrapperCheckbox}>
      <CheckBox isChecked={isChecked} />
      <Text size={"l"} color={"#404040"} width={"regular"}>
        {textCheckbox}
      </Text>
    </div>
  );
}
