import Button from "@Atom/Button";
import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import Images from "@Theme/Images";
import InputTextArea from "@Atom/InputTextArea";
import InputText from "@Atom/InputText";
import Icon from "@Atom/Icon";
import useQuery from "@Hooks/useQuery";
import { fileBaseUrl } from "@Config/index";
import { _feedbackUk, _prakerjaSync } from "@Services/instructor";
import Modal from "@Atom/Modal";
import FileUploadSuccess from "@Molecule/FileUploadSuccessModal";
import PrakerjaSyncModal from "@Molecule/PrakerjaSyncModal";
import { translateError } from "@Helpers/translateError";

export default function SkillTestDetail({ data }) {
  const navigate = useNavigate();
  const query = useQuery();
  const name = query.get("name") || "-";
  const [dataState, setDataState] = useState({
    score: data.score.skillTest || "",
    feedback: data.skillTest.feedback,
  });
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState(0);
  const [message, setMessage] = useState("");
  const [openModalPrakerja, setopenModalPrakerja] = useState(false);
  const [isSync, setIsSync] = useState("");

  const handleSubmitUk = async () => {
    const dataToSend = { ...dataState, userSheetID: data._id };
    try {
      setLoading(true);
      const res = await _feedbackUk(dataToSend);
      setLoading(false);
      if (res.status) {
        setStatus(1);
        setOpenModal(true);
      }
    } catch (error) {
      setLoading(false);
      setStatus(0);
      setOpenModal(true);
      setMessage(error.response.data.error);
    }
  };

  const handleSyncPrakerja = async (idx) => {
    const payload = {
      userSheetID: data._id,
      type: "uk",
    };
    try {
      setopenModalPrakerja(true);
      setStatus(2);
      const res = await _prakerjaSync(payload);
      if (res.status) {
        setStatus(0);
        setIsSync(true);
      }
    } catch (error) {
      if (
        error.response.data.error ===
        "File yang sama sebelumnya sudah di upload"
      ) {
        setStatus(0);
        setIsSync(true);
      } else {
        setStatus(1);
        setMessage(error.response.data.error);
        setIsSync(false);
      }
    }
  };

  useEffect(() => {
    if (data.skillTest.feedback === "" && !data.score.skillTest && data.courseType === "PRAKERJA") {
      handleSyncPrakerja();
    } else {
      setIsSync(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={Styles.container}>
      <Button
        variant="text"
        startIcon={"chevron-left"}
        text="Kembali"
        onClick={() => navigate(-1)}
      />
      <div className={Styles.titleSection}>
        <p>Penilaian Tugas Unjuk Keterampilan</p>
      </div>
      <div className={Styles.profileSection}>
        <p>Nama Murid</p>
        <p>{name}</p>
      </div>
      <div className={Styles.fileSection}>
        <div className={Styles.fileLabel}>
          <p>File Jawaban</p>
        </div>
        <div className={Styles.fileWrapper}>
          {/* file card */}
          {data.skillTest.answer.length > 0 ? (
            data.skillTest.answer.map((el, idx) => (
              <div className={Styles.fileCard} key={idx}>
                <div className={Styles.cardLeft}>
                  <div>
                    <img
                      src={Images.EXCEL_ICON}
                      alt="file-icon"
                      type={"excel"}
                    />
                  </div>
                  <div className={Styles.fileName}>
                    <p>{el}</p>
                  </div>
                </div>
                <div className={Styles.cardRight}>
                  <Icon
                    icon={"eye-visible"}
                    size={22}
                    onClick={() => window.open(fileBaseUrl + el)}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className={Styles.noFile}>
              <img src={Images.DOC_ICON} alt="document-icon" />
              <p>Belum ada tugas yang dikumpulkan peserta</p>
            </div>
          )}

          {/* no file */}
        </div>
      </div>

      {data.skillTest.answer.length > 0 && (
        <div className={Styles.feedbackSection}>
          <InputTextArea
            labelValue="Feedback"
            required
            placeholder="Tulis feedback Anda disini"
            value={dataState.feedback}
            onChange={(e) =>
              setDataState((el) => ({ ...el, feedback: e.target.value }))
            }
            disabled={!isSync}
          />
        </div>
      )}
      {data.skillTest.answer.length > 0 && (
        <div className={Styles.actionSection}>
          <div className={Styles.score}>
            <p>Skor</p>
            <InputText
              labelValue=""
              placeholder="0-100"
              value={dataState.score}
              onChange={(e) => {
                if (+e.target.value && +e.target.value < 101 && !String(e.target.value).includes(".")) {
                  setDataState((el) => ({ ...el, score: e.target.value }));
                } else if (e.target.value === "") {
                  setDataState((el) => ({ ...el, score: "" }));
                }
              }}
              disabled={!isSync}
            />
          </div>
          <Button
            variant="contained"
            text="Simpan"
            disabled={
              loading ||
              !isSync ||
              dataState.score === "" ||
              dataState.feedback === ""
            }
            onClick={() => handleSubmitUk()}
          />
        </div>
      )}
      <Modal isOpen={openModal}>
        <FileUploadSuccess
          onClose={() => setOpenModal(false)}
          status={!!status ? "success" : false}
          text={status ? "Uji Keterampilan Berhasil Dinilai" : message}
          headText={status ? "Penilaian Sukses" : "Penilaian Gagal"}
        />
      </Modal>
      <Modal isOpen={openModalPrakerja}>
        <PrakerjaSyncModal
          status={status}
          handleClose={() => setopenModalPrakerja(false)}
          message={message}
        />
      </Modal>
    </div>
  );
}
