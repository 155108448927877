import iconPrakerja from "@Assets/Images/prakerja.png";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import TabHeader from "@Atom/TabHeader";
import Footer from "@Molecule/Footer";
import { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import moment from "moment";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import { fileBaseUrl } from "@Config/index";
import { useMemo } from "react";
import Button from "@Atom/Button";
import Lottie from "lottie-react";
import loadingClass from "@Assets/animation/loadingClass.json";
import { addDays } from "@Helpers/addDays";
import { addLeadingZero } from "@Helpers/addLeadingZero";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";

export default function Transaction({ datas, isLoading }) {
  const [tabs, setTabs] = useState(1);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const query = useQuery();
  const tabQuery = query.get("t") || 1;
  useEffect(() => {
    navigate(`${pathname}?t=${tabs}`);
  }, [pathname, tabs]);
  useEffect(() => {
    setTabs(+tabQuery || 1);
  }, []);

  const tabOptions = [
    "Transaksi Berhasil",
    "Menunggu Pembayaran",
    "Transaksi Tidak Berhasil",
  ];
  const filterData = useMemo(() => {
    const dataTemp = [
      ...datas.map((el) => ({ ...el, expiryDate: addDays(el.createdAt, 1) })),
    ];
    switch (tabs) {
      case 1:
        return dataTemp.filter((item) => item.status === "PAID-OFF");
      case 2:
        return dataTemp
          .filter((item) => item.status === "PENDING")
          .filter((item) => item.expiryDate > new Date());
      default:
        return dataTemp
          .filter((item) => item.status !== "PAID-OFF")
          .filter((item) => item.expiryDate < new Date());
    }
  }, [datas, tabs]);
  // console.log("transaksi di batalkan :", isTimeUp);
  const user = decryptStorageData("user", localStorage);

  return (
    <div className={Styles.main}>
      <div className={Styles.layout}>
        <section className={Styles.doodle}>
          <DoodleGradientCard>
            <div className={Styles.topCard}>
              <div className={Styles.leftSection}>
                <div>
                  <p className={Styles.title}>
                    Selamat Datang <span>{user?.name}</span>
                  </p>
                  <p className={Styles.desc}>
                    Berikut daftar transaksimu di Skills.id
                  </p>
                </div>
              </div>
            </div>
          </DoodleGradientCard>
        </section>
        <section className={Styles.tabsHeader}>
          <div className={Styles.tabsWrapper}>
            <TabHeader tabs={tabs} setTabs={setTabs} tabOptions={tabOptions} />
          </div>
          {isLoading ? (
            <div className={Styles.loadingContainer}>
              <Lottie animationData={loadingClass} loop={true} />
              <p>Memuat Kontent</p>
            </div>
          ) : (
            <div className={Styles.wrapperCard}>
              {filterData.map((data, idx) => (
                <div className={Styles.card} key={idx}>
                  <div className={Styles.invoice}>
                    <p>{data.invoiceCode}</p>
                  </div>
                  <img
                    src={
                      data.thumbnail.imageURL.includes("https")
                        ? data.thumbnail.imageURL
                        : fileBaseUrl + data.thumbnail.imageURL
                    }
                    className={Styles.images}
                    alt="images"
                  />
                  <div className={Styles.describeClass}>
                    <div className={Styles.classDescription}>
                      <div className={Styles.text}>
                        <div className={Styles.typeClass}>
                          <div>
                            <span>{data.level}</span>
                          </div>
                          <div>
                            <span>{data.platform}</span>
                          </div>
                          {data.participantType === "PRAKERJA" && (
                            <img
                              src={iconPrakerja}
                              alt="prakerja"
                              width={"43.269px"}
                              height={"13.823px"}
                            />
                          )}
                        </div>
                        <div className={Styles.textEllipsis}>
                          <p>{data.courseTitle}</p>
                        </div>
                        <p className={Styles.price}>
                          Rp{(+data.price.base).toLocaleString("id")}
                        </p>
                        {tabs === 1 && (
                          <p className={Styles.paidDate}>
                            Terbayar Pada{" "}
                            {moment(data.paidDate).format("DD MMMM YYYY")}
                          </p>
                        )}
                        {tabs === 2 && (
                          <p className={Styles.paidDate}>
                            Selesaikan transaksi sebelum{" "}
                            {moment(data.expiryDate).format(
                              "DD MMMM YYYY, HH:mm"
                            )}{" "}
                            WIB
                          </p>
                        )}
                        {tabs === 2 && <TimeLeft date={data.expiryDate} />}
                      </div>
                      {/* <div
                      className={Styles.buttonCard}
                      isPending={tabs === 2 && isPending.toString()}
                      onClick={
                        isPending
                          ? undefined
                          : isPending === false && tabs === 2
                          ? handleOpenModal
                          : onClick
                      }
                      tabs={tabs === 2 ? "2" : tabs === 3 ? "3" : ""}
                    >
                      {tabs === 1 ? (
                        <span>
                          {" "}
                          {isVoucher ? "Ditukar " : "Terbayar "}Pada{" "}
                          {moment(datePayment).format("DD MMM YYYY")}
                        </span>
                      ) : tabs === 2 ? (
                        <span>
                          {" "}
                          {isPending
                            ? "Sedang Dalam Proses Verifikasi"
                            : "Bayar Sekarang"}{" "}
                        </span>
                      ) : (
                        <span>Transaksi Dibatalkan</span>
                      )}
                    </div> */}
                    </div>
                  </div>
                  <div className={Styles.cardAction}>
                    <Button
                      text={tabs === 2 ? "Bayar Sekarang" : "Detail Transaksi"}
                      onClick={() =>
                        tabs === 2
                          ? window.location.replace(data.linkDoku)
                          : navigate(data._id)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </section>
        {/* <Modal isOpen={isOpen}>
          <div style={{ paddingBlock: "90px 20px" }}>
            <div className={Styles.containerPayment}>
              <div className={Styles.relative}>
                <div className={Styles.invoice}>
                  <h1>{invoice}</h1>
                  <span>•</span>
                  <p className={Styles.date}>
                    {moment(date).format("MMMM Do, YYYY")}
                  </p>
                  <p className={Styles.time}>
                    {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
                  </p>
                  <Icon
                    icon={"cross"}
                    color={"#232323"}
                    size={"24"}
                    className={Styles.iconClose}
                    onClick={handleOpenModal}
                  />
                </div>
                <div className={Styles.detailPayment}>
                  <h1>Detail Pembayaran</h1>
                  <div className={Styles.titleClass}>
                    <div>{titleClass}</div>
                    <p>Rp {price.toLocaleString("id")}</p>
                  </div>
                  <div className={Styles.discount}>
                    <p>
                      Discount <span>({(discount * 100).toFixed()}%)</span>{" "}
                    </p>
                    <p>-Rp {totalDiscount.toLocaleString("id")}</p>
                  </div>
                  <div className={Styles.total}>
                    <div>
                      <h1>Total:</h1>
                      <h1>Rp {totalPrice.toLocaleString("id")}</h1>
                    </div>
                  </div>
                </div>
                <div className={Styles.lineSeperator}></div>
                <div className={Styles.paymentMethode}>
                  <div>
                    Metode Pembayaran : <span>{paymentMethode}</span>
                  </div>
                  <div className={Styles.typeMethod}>
                    <img src={bcaIcon} alt="" />
                    <span>•</span>

                    <div>
                      <span>Transfer Ke Rekening BCA</span>
                      <span>3790801293 - A.N Skills.ID</span>
                    </div>
                  </div>
                </div>
                <div className={Styles.upload}>
                  <h1>Unggah Bukti Pembayaran</h1>
                  <input
                    type="file"
                    ref={ref}
                    hidden
                    onChange={handlePreviewImage}
                    accept=".png,.jpg,.jpeg"
                  />
                  {imagePayment ? (
                    <div className={Styles.fileUpload} isChoose={"true"}>
                      <img
                        src={imagePayment}
                        onClick={() => ref.current?.click()}
                        alt="payment-images"
                      />
                    </div>
                  ) : (
                    <div className={Styles.fileUpload}>
                      <img src={documentUpload} alt="" />
                      <h1>Unggah bukti pembayaran Anda disini</h1>
                      <div
                        className={Styles.button}
                        onClick={() => ref.current?.click()}
                      >
                        <label>Pilih File</label>
                      </div>
                    </div>
                  )}
                </div>
                <div className={Styles.buttonCard} onClick={onClick}>
                  <span>Simpan</span>
                </div>
              </div>
            </div>
          </div>
        </Modal> */}
      </div>
      <Footer />
    </div>
  );
}

const TimeLeft = ({ date }) => {
  // const [timeLeft, setTimeLeft] = useState({});
  const [timeToShow, setTimeToShow] = useState("");
  const calculateTimeLeft = () => {
    const difference = +new Date(date) - +new Date();
    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    setTimeToShow(
      addLeadingZero(
        [timeLeft.hours, timeLeft.minutes, timeLeft.seconds].join(":"),
        2
      )
    );
    return timeLeft;
  };

  useEffect(() => {
    const timerAction = () => {
      const timer = setTimeout(() => {
        calculateTimeLeft();
      }, 1000);

      return () => clearTimeout(timer);
    };

    timerAction();
  });

  return (
    <div className={Styles.countdownContainer}>
      <Icon icon="clock" size={20} color={"#275F06"} />
      <p>{timeToShow}</p>
    </div>
  );
};
