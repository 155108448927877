import { API } from "@Config/index";

export const getLandingPageData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get("/landing?page");
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
