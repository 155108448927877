import React, { useMemo } from "react";
import Styles from "./style.module.scss";
import _ from "lodash";
import Button from "@Atom/Button";
import { fileBaseUrl } from "@Config/index";
import Icon from "@Atom/Icon";
import { useNavigate } from "react-router-dom";

export default function ClassDetailProgress({ data }) {
  const navigate = useNavigate();
  const fileAssets = _.get(
    data,
    ["course", "asset", "thumbnail", "imageUrl"],
    ""
  );

  const progress = useMemo(() => {
    let percentage = Math.floor(
      (data?.progress?.subjectsFinishedTotal / data?.progress?.subjectsTotal) *
        100
    );
    if (percentage > 100) {
      percentage = 100;
    }
    return percentage + "%";
  }, [data?.progress?.subjectsFinishedTotal, data?.progress?.subjectsTotal]);

  if (data) {
    return (
      <div className={Styles.container}>
        <div className={Styles.header}>
          <p>Kelas Berlangsung</p>
        </div>
        <div className={Styles.body}>
          <div className={Styles.cardWrapper}>
            <div className={Styles.cardBody}>
              <div className={Styles.image}>
                <img
                  src={
                    fileAssets.includes("http")
                      ? fileAssets
                      : fileBaseUrl + fileAssets
                  }
                  alt=""
                />
              </div>
              <div className={Styles.desc}>
                <div className={Styles.top}>
                  <p>{_.get(data, ["course", "title"], "default")}</p>
                  {/* <div>
                    <Icon icon="calendar-outlined" size={16}/>
                  </div> */}
                </div>
                <div className={Styles.bottom}>
                  <div className={Styles.progressSection}>
                    <div className={Styles.progressWrapper}>
                      <div className={Styles.progressComponent}>
                        <div className={Styles.progressBase} />
                        <div
                          className={Styles.progress}
                          style={{ width: progress }}
                        />
                      </div>
                      <p className={Styles.progressText}>{progress}</p>
                    </div>
                    <div className={Styles.progressDesc}>
                      <Icon icon={"book"} size={18} color={"#9360A8"} />
                      <p>{`${
                        data?.progress?.subjectsFinishedTotal >
                        data?.progress?.subjectsTotal
                          ? data?.progress?.subjectsTotal
                          : data?.progress?.subjectsFinishedTotal
                      } / ${data?.progress?.subjectsTotal} Materi Selesai`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.cardAction}>
              <Button
                text={"Lanjutkan Kelas"}
                variant="outlined"
                onClick={() =>
                  navigate(
                    `/classes/learn/${data.course.courseID}/rules?t=${data.course.title}`
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
