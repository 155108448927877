import VoucherAdminLayout from "@Organism/Admin/Voucher";
import { getAllVoucherAdmin } from "@Services/voucher";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const search = params?.get("search") || "";
  const getAllVoucher = await getAllVoucherAdmin(limit, page,search);
  return defer({
    getAllVoucher,
  });
}
export default function AdminVocuherPages() {
  const { getAllVoucher } = useLoaderData();
  return (
    <Suspense fallback={<p>loading....</p>}>
      <Await
        resolve={getAllVoucher}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <VoucherAdminLayout data={value.data} />}
      </Await>
    </Suspense>
  );
}
