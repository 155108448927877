import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import { getCertificateWithValidate } from "@Services/certificate";
import { postRating } from "@Services/rating";
import { useNavigate, useParams } from "react-router-dom";
import { translateError } from "@Helpers/translateError";


export default function CertificateValidateModal({ setIsOpen, data }) {
  const [rating, setRating] = useState({
    rate: 0,
    feedback: "",
  });
  const [name, setName] = useState("");
  const [step, setStep] = useState(1);
  const [errorValidate, setErrorValidate] = useState(false);
  const [certificate, setCertificate] = useState(null);
  const navigate = useNavigate();
  const {id} = useParams();

  const handleVerifyName = async () => {
    try {
      const res = await getCertificateWithValidate(id, name);
      if (res.status) {
        setStep(step + 1);
        setCertificate(res);
      }
    } catch (error) {
      setErrorValidate(translateError(error.response.data.error));
    }
  };

  const handlePostRating = async () => {
    try {
      const res = await postRating({
        ...rating,
        rate: rating.rate.toString(),
        courseID: data.courseID,
      });
      if (res.status) {
        navigate(`/certificate/${certificate.certificate._id}`);
      }
    } catch (error) {
      // setErrorValidate(translateError(error.response.data.error));
      navigate(`/certificate/${certificate.certificate._id}`);
    }
  };

  useEffect(() => {
    if (name) {
      setErrorValidate(false);
    }
  }, [name]);

  const handleNext = () => {
    if (step === 1) {
      handleVerifyName();
    } else {
      handlePostRating();
    }
  };
  const handleBack = () => {
    if (step === 1) {
      setIsOpen(false);
    } else {
      setStep(step - 1);
    }
  };

  console.log(errorValidate);
  return (
    <div className={Styles.modalContainer}>
      <section>
        <div className={Styles.headerModal}>
          <h1>Cetak Sertifikat</h1>
          <Icon
            icon={"cross"}
            onClick={() => setIsOpen(false)}
            size={"24px"}
            className={Styles.closeIcon}
          />
        </div>
        <main className={Styles.main}>
          <div className={Styles.step}>
            <p>Langkah {step}/2</p>
          </div>
          <div
            className={Styles.sectionWrapper}
            is-disabled={step !== 1 ? "true" : "false"}
          >
            <div className={Styles.validateName}>
              <div>
                <p>Masukan Nama Anda</p>
                <p>
                  Nama yang Anda masukan disini akan tercantum pada sertifikat
                  yang akan Anda dapatkan
                </p>
              </div>
              <div
                className={Styles.validateInput}
                is-error={errorValidate ? "true" : "false"}
              >
                <Input placeholder="Nama" value={name} setValue={setName} />
                {errorValidate && (
                  <span className={Styles.errorMessage}>{errorValidate}</span>
                )}
              </div>
            </div>
          </div>
          <div
            className={Styles.sectionWrapper}
            is-disabled={step !== 2 ? "true" : "false"}
          >
            <div className={Styles.review}>
              <div className={Styles.cardRating}>
                <div className={Styles.rating}>
                  <h1>Rating</h1>
                  <div className={Styles.star}>
                    {[1, 2, 3, 4, 5].map((item, idx) => (
                      <Icon
                        key={idx}
                        icon={rating.rate >= item ? "star" : "Star-Atom"}
                        color={rating.rate >= item ? "#ffa534" : "#BABABA"}
                        size={"32"}
                        className={Styles.iconStars}
                        onClick={() =>
                          setRating((prev) => ({ ...prev, rate: item }))
                        }
                      />
                    ))}
                  </div>
                  <div className={Styles.expression}>
                    <span
                      style={rating.rate === 1 ? { color: "black" } : undefined}
                    >
                      Sangat Buruk
                    </span>
                    <span
                      style={rating.rate === 5 ? { color: "black" } : undefined}
                    >
                      Sangat Baik
                    </span>
                  </div>
                </div>
                <div className={Styles.feedback}>
                  <h1>Feedback</h1>
                  <textarea
                    placeholder="Feedback"
                    value={rating.feedback}
                    onChange={(e) =>
                      setRating((prev) => ({
                        ...prev,
                        feedback: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.buttonModal}>
            <div onClick={() => handleBack(false)}>
              <span>Kembali</span>
            </div>
            <div onClick={() => handleNext()}>
              <span>{step > 1 ? "Submit" : "Lanjut"}</span>
            </div>
          </div>
        </main>
      </section>
    </div>
  );
}
