import BankLogo from "@Theme/BankLogo";
const bankVariables = [
  {
    value: "VIRTUAL_ACCOUNT_BCA",
    label: "BCA Virtual Account",
    logo: BankLogo.BCA,
  },
  {
    value: "VIRTUAL_ACCOUNT_BANK_MANDIRI",
    label: "BANK MANDIRI Virtual Account",
    logo: BankLogo.MANDIRI,
  },
  {
    value: "VIRTUAL_ACCOUNT_BANK_SYARIAH_MANDIRI",
    label: "BANK SYARIAH MANDIRI Virtual Account",
    logo: BankLogo.MANDIRI,
  },

  {
    value: "VIRTUAL_ACCOUNT_BRI",
    label: "BRI Virtual Account",
    logo: BankLogo.BRI,
  },
  {
    value: "VIRTUAL_ACCOUNT_BNI",
    label: "BNI Virtual Account",
    logo: BankLogo.BNI,
  },
  {
    value: "VIRTUAL_ACCOUNT_BANK_PERMATA",
    label: "BANK PERMATA Virtual Account",
    logo: BankLogo.PERMATA,
  },
  {
    value: "VIRTUAL_ACCOUNT_BANK_CIMB",
    label: "BANK CIMB Virtual Account",
    logo: BankLogo.CIMB,
  },
  {
    value: "VIRTUAL_ACCOUNT_BANK_DANAMON",
    label: "BANK DANAMON Virtual Account",
    logo: BankLogo.DANAMON,
  },
  { value: "ONLINE_TO_OFFLINE_ALFA", label: "ALFA" },
  { value: "CREDIT_CARD", label: "CREDIT CARD" },
  { value: "DIRECT_DEBIT_BRI", label: "DIRECT DEBIT BRI", logo: BankLogo.BRI },
  { value: "EMONEY_SHOPEEPAY", label: "SHOPEEPAY" },
  { value: "EMONEY_OVO", label: "OVO", logo: BankLogo.OVO },
  { value: "EMONEY_DANA", label: "DANA", logo: BankLogo.DANA },
  { value: "QRIS", label: "QRIS"},
];

export default function findBankVariable(variable) {
  return bankVariables.find((el) => el.value === variable);
}
