import DetailStudentLayout from "@Organism/Admin/DetailStudent";
import React, { Suspense } from "react";
import { getDataDetailStudent } from "@Services/admin";
import { Await, defer, useLoaderData } from "react-router-dom";
export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const { id } = route?.params;
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const search = params?.get("search") || "";
  const getDataDetail = await getDataDetailStudent(id, limit, page, search);
  return defer({
    getDataDetail,
  });
}
export default function ListStudentPage() {
  const { getDataDetail } = useLoaderData();
  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <Await resolve={getDataDetail}>
        {(value) => <DetailStudentLayout data={value[0]} />}
      </Await>
    </Suspense>
  );
}
