import Avatar from "@Atom/Avatar";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import ProfileDropdown from "@Atom/ProfileDropdown";
import useOutsideClick from "@Hooks/useOutsideClick";
import Images from "@Theme/Images";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import useWindowSize from "@Hooks/useWindowSize";
import useQuery from "@Hooks/useQuery";
import { fileBaseUrl } from "@Config/index";
import { decryptStorageData } from "@Helpers/encryptDecrypt";

export default function Topbar({ children }) {
  const [expandProfile, setExpandProfile] = useState(false);
  const { pathname } = useLocation();
  const isLogin = localStorage.getItem("accessToken") ? true : false;
  const navigate = useNavigate();
  const query = useQuery();
  const title = query.get("t") || "";
  const user = decryptStorageData("user", localStorage);

  const type = useMemo(() => {
    switch (pathname?.split("/")[1]) {
      case "admin":
        return "dashboard";
      case "student":
        return "dashboard";
      case "instructor":
        return "dashboard";
      default:
        return "landing-page";
    }
  }, [pathname]);

  // {
  //   link: "prakerja",
  //   title: "Prakerja",
  // },
  // {
  //   link: "#categories",
  //   title: "Kategori",
  // },
  // {
  //   link: "#benefit",
  //   title: "Benefit",
  // },
  // {
  //   link: "#testimonials",
  //   title: "Testimoni",
  // },
  // {
  //   link: "#faq",
  //   title: "FAQ",
  // },

  const topNavs = useMemo(() => {
    return [
      {
        id: "homepageSection",
        title: "Beranda",
        link: "#beranda",
      },
      {
        id: "prakerjaSection",
        title: "Prakerja",
        link: "#prakerja",
      },
      {
        id: "regulerclassSection",
        title: "Kelas Reguler",
        link: "#regulerclass",
      },
      {
        id: "categorySection",
        title: "Kategori",
        link: "#categories",
      },
      {
        id: "benefitSection",
        title: "Benefit",
        link: "#benefit",
      },
      {
        id: "testimonySection",
        title: "Testimoni",
        link: "#testimonials",
      },
      {
        id: "FAQSection",
        title: "FAQ",
        link: "#faq",
      },
    ];
  }, []);

  const popupRef = useRef();
  const outsidePopupClick = useOutsideClick(popupRef);

  useEffect(() => {
    if (expandProfile && outsidePopupClick) {
      setExpandProfile(false);
    }
  }, [outsidePopupClick, expandProfile]);

  const { width } = useWindowSize();

  return (
    <div
      className={`${Styles.container} ${
        pathname?.includes("login") || pathname?.includes("register")
          ? Styles.isGradientBg
          : ""
      }`}
    >
      <div className={Styles.topWrapper}>
        <div className={Styles.topContent}>
          {width > 768 && (
            <div className={Styles.logo} onClick={() => navigate("/")}>
              <img src={Images.LOGO} alt="" />
              <span>Skills.id</span>
            </div>
          )}

          {type === "landing-page" &&
          pathname === "/" &&
          !pathname.split("/").includes("learn") ? (
            <div className={Styles.topNavs}>
              {topNavs?.map((n, nI) => (
                <a href={n.link} key={nI} className={Styles.navbarLink}>
                  <li>{n.title}</li>
                </a>
              ))}
            </div>
          ) : pathname.split("/").includes("learn") ? (
            <div className={Styles.topNavs}>
              <p>{title}</p>
            </div>
          ) : (
            <div className={Styles.topNavs} />
          )}
          {isLogin ? (
            <div className={Styles.right}>
              {/* <div className={Styles.actions}>
                  {
                    type === 'dashboard'
                    &&
                    <button>
                      <Icon icon={'shopping_cart'} size={24} />
                    </button>

                  }
                  <button>
                    <Icon icon={'notification-solid'} size={24} />
                  </button>
                </div> */}
              <div
                ref={popupRef}
                className={Styles.profileButton}
                onClick={() => setExpandProfile(!expandProfile)}
              >
                <Avatar
                  iconName="user"
                  size="38px"
                  iconSize="24px"
                  iconColor="#000"
                  imgUrl={
                    user.profilePicture
                      ? fileBaseUrl + user?.profilePicture
                      : ""
                  }
                />
                <Icon
                  icon={expandProfile ? "arrow-up" : "arrow-down"}
                  size={24}
                />

                {expandProfile && (
                  <div className={Styles.dropdownWrapper}>
                    <ProfileDropdown />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={Styles.right}>
              <Button
                variant="outlined"
                text={"Register/Login"}
                className={Styles.authButton}
                onClick={() => navigate("/login")}
              />
            </div>
          )}
        </div>
      </div>

      <div
        className={`${Styles.contentWrapper} ${
          pathname === "/" || pathname === "/prakerja" ? Styles.noMaxWidth : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
}
