import React from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import InputText from "@Atom/InputText";
import InputField from "@Molecule/InputField";

export default function ModalGenerateVoucherAdmin({
  valueVendor,
  valueDiscount,
  valueQuantity,
  isOpen,
  onClose,
  onClick,
  handleChange,
  isErrorVendor,
  isErrorQuantity,
  isErrorDiscount,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={Styles.wrapperModalVoucher}>
        <h2>Generate Voucher Kelas</h2>
        {/* {ubah jadi dropdown} */}
        <InputField
          name={"partner"}
          setValue={(e) =>
            handleChange({ target: { name: "partner", value: e } })
          }
          value={valueVendor}
          placeholder="Vendor"
          required
          labelValue="Vendor"
          isError={isErrorVendor}
          isDropdown
          title={"Vendor"}
          dropdownOptions={["Tokopedia", "Pintar", "Bukalapak", "Test"]}
        />
        {/* <Select
          name={"partner"}
          setValue={handleChange}
          value={valueVendor}
          placeholder="Vendor"
          required
          labelValue="Vendor"
          isError={isErrorVendor}
          options={['TOKOPEDIA','PINTAR','BUKALAPAK']}
        /> */}

        <InputText
          type="text"
          name="quantity"
          value={valueQuantity}
          onChange={handleChange}
          placeholder="cth.10"
          required
          labelValue="Jumlah Voucher"
          isError={isErrorQuantity}
        />
        <InputField
          type="text"
          placeholder="cth. 100"
          labelValue="Potongan"
          isError={isErrorDiscount}
          isPercentage
          name={"discount"}
          setValue={(e) => {
            if (+e && +e < 101 && !String(e).includes(".")) {
              handleChange({ target: { name: "discount", value: e } });
            } else if (e === "") {
              handleChange({ target: { name: "discount", value: "" } });
            }
          }}
          value={valueDiscount}
          required
          title={"Potongan"}
        />

        <div className={Styles.buttonModal}>
          <div onClick={onClose}>
            <span>Kembali</span>
          </div>
          <div onClick={onClick}>
            <span>Generate</span>
          </div>
        </div>
      </div>
    </Modal>
  );
}
