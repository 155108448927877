import Button from "@Atom/Button";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import PhotoField from "@Atom/PhotoField";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Footer from "@Molecule/Footer";
import InputField from "@Molecule/InputField";
import Images from "@Theme/Images";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import FormErrorMessage from "@Atom/FormErrorMessage";
import useWindowSize from "@Hooks/useWindowSize";
import {
  createInstructor,
  editInstructor,
  getInstructorDetail,
} from "@Services/admin";
import Modal from "@Atom/Modal";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/index";

export default function AdminCreateInstructorLayout({ isEdit }) {
  const user = decryptStorageData("user", localStorage);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    img: null,
    avatar: null,
    name: "",
    email: "",
    phoneNumber: "",
    nik: "",
    npwp: "",
    ig: "",
    fb: "",
    x: "",
    biography: "",
    accountHolder: "",
    bankName: "",
    accountNumber: "",
  });
  

  const getInstructor = async () => {
    try {
      const { data } = await getInstructorDetail(id);

      //   "data": {
      //     "_id": "671863b35e675938aa9ad428",
      //     "name": "TEST INTRUCTOR WITH DISPLAY AND PROFILE PICT",
      //     "profilePicture": "17296516356530-243186c8377bedfef2affcadf2fb57af.png",
      //     "displayPicture": "17296516356541-profilePict",
      //     "bio": "TEST BIO",
      //     "phoneNumber": "821923912312",
      //     "email": "mail@mail.com",
      //     "role": "EXTERNAL",
      //     "isInstructor": true,
      //     "idNumber": "1231231231231231",
      //     "taxNumber": "1231231231231231",
      //     "bank": {
      //         "accountName": "rekening",
      //         "accountNumber": "123123123123",
      //         "account": "nama bank",
      //         "_id": "671863b35e675938aa9ad429"
      //     },
      //     "socialMedia": {
      //         "instagram": "test",
      //         "twitter": "test",
      //         "_id": "671863b35e675938aa9ad42a"
      //     },
      //     "createdAt": "2024-10-23T02:47:15.693Z",
      //     "updatedAt": "2024-10-23T02:47:15.693Z",
      //     "__v": 0
      // }
      setForm({
        img: data?.displayPicture ? fileBaseUrl + data?.displayPicture : null,
        avatar: data?.profilePicture
          ? fileBaseUrl + data?.profilePicture
          : null,
        name: data?.name || "",
        email: data?.email || "",
        phoneNumber: data?.phoneNumber.startsWith("62")
          ? data?.phoneNumber.slice(2)
          : data?.phoneNumber,
        nik: data?.idNumber || "",
        npwp: data?.taxNumber || "",
        ig: data?.socialMedia?.instagram || "",
        fb: data?.socialMedia?.facebook || "",
        x: data?.socialMedia?.twitter || "",
        biography: data?.bio || "",
        accountHolder: data?.bank?.accountName || "",
        bankName: data?.bank?.account || "",
        accountNumber: data?.bank?.accountNumber || "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isEdit) {
      getInstructor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, id]);

  const handleChangeForm = useCallback((name, newVal) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: newVal,
      };
    });
  }, []);

  const { width } = useWindowSize();

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error, setError]);

  const handleSubmit = async () => {
    const dataToSend = {
      name: form.name,
      email: form.email,
      bio: form.biography,
      phoneNumber: "62" + form.phoneNumber,
      idNumber: form.nik,
      taxNumber: form.npwp,
      bankName: form.bankName,
      bankAccountName: form.accountHolder,
      bankAccountNumber: form.accountNumber,
      instagram: form.ig,
      twitter: form.x,
      facebook: form.fb,
    };
    if (typeof form.img !== "string") {
      dataToSend.files = [form.avatar, form.img];
    }
    if (form.nik.length > 0 && form.nik.length < 16) {
      setError("NIK yang Anda masukkan kurang dari 16 digit.");
      return;
    }
    if (form.npwp.length > 0 && form.npwp.length < 16) {
      setError("NPWP yang Anda masukkan kurang dari 16 digit.");
      return;
    }
    try {
      setLoading(true);
      if (isEdit) {
        const res = await editInstructor(id, dataToSend);
        if (res.status) {
          setSuccess(true);
        }
      } else {
        const res = await createInstructor(dataToSend);
        if (res.status) {
          setSuccess(true);
        }
      }
      setLoading(false);
    } catch (error) {
      // console.log()
      setLoading(false);

      setConfirmEdit(false);
      setError(error.response.data.error);
    }
  };

  const template = useMemo(() => {
    return [
      {
        title: "Informasi Akun",
        component: (
          <div className={Styles.informationSection}>
            <PhotoField
              value={form?.img}
              setValue={(newVal) => handleChangeForm("img", newVal)}
              setAvatar={(newVal) => handleChangeForm("avatar", newVal)}
            />
            <div className={Styles.infoFields}>
              <InputField
                title={"Name"}
                required
                value={form?.name}
                setValue={(newVal) => handleChangeForm("name", newVal)}
                placeholder="Masukkan nama instruktur"
              />
              <InputField
                title={"Email"}
                value={form?.email}
                setValue={(newVal) => handleChangeForm("email", newVal)}
                placeholder="Masukkan email instruktur"
                isEmail
                required
                isPhoneNumber={false}
                isCurrency={false}
              />
              <InputField
                title={"No. Handphone"}
                required
                value={form?.phoneNumber}
                setValue={(newVal) => handleChangeForm("phoneNumber", newVal)}
                placeholder="Masukkan no hanphone instruktur"
                isPhoneNumber
              />
              <div className={Styles.grid}>
                <InputField
                  title={"NIK"}
                  value={form?.nik}
                  setValue={(newVal) =>
                    handleChangeForm(
                      "nik",
                      newVal.length < 17 ? newVal : form.nik
                    )
                  }
                  placeholder="Masukkan NIK instruktur"
                  isNumber
                />
                <InputField
                  title={"NPWP"}
                  value={form?.npwp}
                  setValue={(newVal) =>
                    handleChangeForm(
                      "npwp",
                      newVal.length < 17 ? newVal : form.npwp
                    )
                  }
                  placeholder="Masukkan NPWP instruktur"
                  isNumber
                />
              </div>
              <div className={Styles.grid}>
                <InputField
                  title={"Instagram"}
                  value={form?.ig}
                  setValue={(newVal) => handleChangeForm("ig", newVal)}
                  placeholder="@john_doe"
                  startIcon="instagram"
                />
                <InputField
                  title={"Facebook"}
                  value={form?.fb}
                  setValue={(newVal) => handleChangeForm("fb", newVal)}
                  placeholder="@john_doe"
                  startIcon="facebook"
                />
                <InputField
                  title={"X"}
                  value={form?.x}
                  setValue={(newVal) => handleChangeForm("x", newVal)}
                  placeholder="@john_doe"
                  startIcon="x-twitter"
                />
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Biografi Instruktur.",
        component: (
          <InputField
            title={"Biografi"}
            value={form?.biography}
            setValue={(newVal) => handleChangeForm("biography", newVal)}
            placeholder="Tuliskan biografi instruktur disini"
            isTextArea
            required
          />
        ),
      },
      {
        title: "Rekening Bank",
        component: (
          <div className={Styles.bankAccountSection}>
            <InputField
              title={"Nama Pemilik Rekening"}
              value={form?.accountHolder}
              setValue={(newVal) => handleChangeForm("accountHolder", newVal)}
              placeholder="Masukkan nama pemilik rekening"
            />
            <div className={Styles.grid}>
              <InputField
                title={"Nama Bank"}
                value={form?.bankName}
                setValue={(newVal) => handleChangeForm("bankName", newVal)}
                placeholder="Masukkan nama bank"
              />
              <InputField
                title={"Nomor Rekening"}
                value={form?.accountNumber}
                setValue={(newVal) => handleChangeForm("accountNumber", newVal)}
                placeholder="Masukkan nomor rekening anda"
                isNumber
              />
            </div>
            <FormErrorMessage message={error} />
            <div className={Styles.submit}>
              <Button
                text={loading ? "Loading..." : "Simpan"}
                disabled={!form?.name || !form?.phoneNumber || loading}
                onClick={() => (isEdit ? setConfirmEdit(true) : handleSubmit())}
              />
            </div>
          </div>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, form, handleChangeForm, loading]);

  return (
    <div className={Styles.container}>
      <div className={Styles.breadcrumbsWrapper}>
        <div
          className={Styles.backIcon}
          onClick={() => navigate("/admin/instructor")}
        >
          <Icon size={16} icon={"chevron-left"} />
        </div>
        <div className={Styles.breadcrumbs}>
          <p>Daftar Instruktur</p>
          <Icon size={16} icon={"chevron-right"} />
          <p>{isEdit ? "Detail Instruktur" : "Tambah Instruktur"}</p>
        </div>
      </div>
      <div className={Styles.wrapper}>
        <DoodleGradientCard className={Styles.header}>
          <div className={Styles.title}>
            <h3>Selamat Datang {<span>{user?.name}</span>}</h3>
            <span>
              Silahkan lakukan pengaturan terhadap akun kamu di halaman ini
            </span>
          </div>
          {width > 768 && (
            <img className={Styles.img} src={Images.ID_CARD} alt="" />
          )}
        </DoodleGradientCard>
        <div className={Styles.content}>
          {template?.map((t, tI) => (
            <div key={tI} className={Styles.each}>
              <span>{t?.title}</span>
              {t?.component}
            </div>
          ))}
        </div>
      </div>
      <Footer />
      <Modal isOpen={success}>
        <div className={Styles.successModal}>
          <div className={Styles.modalBody}>
            <p>
              {isEdit
                ? "Perubahan Berhasil Disimpan"
                : "Instruktur Berhasil Ditambahkan"}
            </p>
            <img src={Images.SUCCESS_MODAL} alt="" />
            <p>
              {isEdit
                ? "Data instruktur telah berhasil diperbaharui"
                : `Instruktur ${form.name} ditambahkan.`}
            </p>
          </div>
          <div className={Styles.modalAction}>
            <Button
              text="Tutup"
              onClick={() =>
                isEdit ? setSuccess(false) : navigate("/admin/instructor")
              }
            />
          </div>
        </div>
      </Modal>
      <Modal isOpen={confirmEdit}>
        <div className={Styles.confirmModal}>
          <div className={Styles.modalBody}>
            <p>Perbaharui Detail Akun Instruktur?</p>
            <p>
              Apakah kamu yakin ingin melakukan perubahan pada detail instruktur
              ini?
            </p>
          </div>
          <div className={Styles.modalAction}>
            <Button
              text="Tidak"
              onClick={() => setConfirmEdit(false)}
              variant="outlined"
            />
            <Button
              text="Ya"
              onClick={() => {
                handleSubmit();
                setConfirmEdit(false);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
