import React, { useState, useEffect } from "react";
import Styles from "./style.module.scss";
import useWindowSize from "@Hooks/useWindowSize";

export default function CounterLandingPage() {
  const [year, setYear] = useState(0);
  const [course, setCourse] = useState(0);
  const [student, setStudent] = useState(0);

  const dataStudent = 1000;
  const dataCourses = 31;
  const dataPerYears = 7;
  const duration = 2000;

  useEffect(() => {
    const startTime = Date.now();

    const timer = setInterval(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;

      const progress = elapsedTime / duration;
      setStudent(Math.min(Math.floor(dataStudent * progress), dataStudent));
      setCourse(Math.min(Math.floor(dataCourses * progress), dataCourses));
      setYear(Math.min(Math.floor(dataPerYears * progress), dataPerYears));

      if (elapsedTime >= duration) {
        clearInterval(timer);
      }
    }, 10);

    return () => clearInterval(timer);
  }, []);
  const { width } = useWindowSize();
  return (
    <div
      className={Styles.counter}
      style={{ position: "relative", zIndex: width < 768 ? "1" : "-1" }}
    >
      <div className={Styles.year}>
        <h1>{year}</h1>
        <p>Tahun</p>
      </div>
      <span></span>
      <div className={Styles.course}>
        <h1>{course}</h1>
        <p>Kursus</p>
      </div>
      <span></span>
      <div className={Styles.studies}>
        <h1>{student}+</h1>
        <p>Murid</p>
      </div>
    </div>
  );
}
