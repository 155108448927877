/* eslint-disable eqeqeq */
import Button from "@Atom/Button";
import DatePicker from "@Atom/DatePicker";
import { DragAndDropInput } from "@Atom/DragAndDropInput";
import Icon from "@Atom/Icon";
import ProgressBar from "@Atom/ProgressBar";
import Spinner from "@Atom/Spinner";
import { dataURLtoFile } from "@Helpers/dataURLToFile";
import { fancyTimeFormat } from "@Helpers/fancyTimeFormat";
import { formatBytes } from "@Helpers/formatBytes";
import { makeRandomString } from "@Helpers/makeRandomString";
import { priceFormat } from "@Helpers/priceFormat";
import { stringToNumber } from "@Helpers/stringToNumber";
import useOutsideClick from "@Hooks/useOutsideClick";
import PinMaps from "@Molecule/PinMaps";
import ViewFileModal from "@Molecule/ViewFileModal";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";

export default function Input({
  value = "",
  setValue = () => {},
  placeholder = "",
  className = "",
  padding = "12px",
  borderRadius = "8px",
  isPhoneNumber = false,
  isCurrency = false,
  isNumber = false,
  isPassword = false,
  isEmail = false,
  isPercentage = false,
  isDropdown = false,
  dropdownOptions = [],
  isTextArea = false,
  isLoadingDropdown = false,
  customDropdownPositioning = { top: "58px" },
  isDragAndDrop = false,
  isFile = false,
  accept = { "image/jpeg": [".jpeg", ".png"] },
  disabled = false,
  forceUpperCase = false,
  speed = 50,
  withDeleteOption = false,
  onDelete = () => {},
  isDate,
  isTime,
  readOnly,
  isImageOnly = false,
  isPDFOnly = false,
  isVideoOnly = false,
  isPinPoint = false,
  forceConvertFile = false,
  startIcon = "",
  iconSize = "24px",
  iconColor = "#C2C2C2",
  isLink = false,
  name,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [expandDropdown, setExpandDropdown] = useState(false);
  const [expandAll, setExpandAll] = useState(true);
  const options = useMemo(() => {
    if (expandAll) {
      return dropdownOptions;
    }
    return dropdownOptions?.filter((obj) =>
      obj?.toLowerCase()?.includes(value?.toLowerCase())
    );
  }, [dropdownOptions, expandAll, value]);

  useEffect(() => {
    if (!expandDropdown) {
      setExpandAll(true);
    }
  }, [expandDropdown]);

  useEffect(() => {
    if (
      !isLoadingDropdown &&
      !expandDropdown &&
      isDropdown &&
      value &&
      !dropdownOptions?.find((obj) => obj === value)
    ) {
      if (
        !dropdownOptions?.find(
          (el) => el?.toLowerCase() === value?.toLowerCase()
        )
      ) {
        setValue("");
      } else {
        setValue(
          dropdownOptions?.find(
            (el) => el?.toLowerCase() === value?.toLowerCase()
          )
        );
      }
    }
  }, [
    dropdownOptions,
    expandDropdown,
    isDropdown,
    isLoadingDropdown,
    setValue,
    value,
  ]);

  const boxRef = useRef();
  const boxOutsideClick = useOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && expandDropdown) {
      setExpandDropdown(false);
    }
  }, [boxOutsideClick, expandDropdown]);

  const [showPinpointModal, setShowPinpointModal] = useState(false);

  function onlyNumberInput(event) {
    var key = event.which || event.keyCode;
    if (key && (key <= 47 || key >= 58) && key != 8) {
      if (key != 9) {
        event.preventDefault();
      }
    }
  }

  function phoneNumberValidation(event) {
    var key = event.which || event.keyCode;
    if (!value?.length) {
      if (key && key != 56) {
        event.preventDefault();
      }
    } else {
      if (key && (key <= 47 || key >= 58) && key != 8) {
        if (key != 9) {
          event.preventDefault();
        }
      }
    }
  }

  const handleChangeDropdown = useCallback(
    (e) => {
      setExpandDropdown(true);
      setExpandAll(false);
      setValue(e?.target?.value);
    },
    [setValue]
  );

  const handleChangeCurrency = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;

      if (value === "") {
        return setValue(0);
      }

      const valueAsNumber = stringToNumber(value || "0");

      return setValue(valueAsNumber || 0);
    },
    [setValue]
  );

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  if (isFile) {
    return (
      <FileInput
        file={value}
        setFile={setValue}
        placeholder={placeholder}
        speed={speed}
        readOnly={readOnly}
        disabled={disabled}
        isImageOnly={isImageOnly}
        isPDFOnly={isPDFOnly}
        isVideoOnly={isVideoOnly}
        forceConvertFile={forceConvertFile}
      />
    );
  }

  if (isDragAndDrop) {
    return <DragAndDropInput file={value} setFile={setValue} accept={accept} />;
  }

  if (isDate) {
    return (
      <DatePicker
        type={"date"}
        value={value}
        placeholder={placeholder}
        handleChange={(newVal) => setValue(newVal)}
        {...props}
      />
    );
  }

  if (isTime) {
    return (
      <DatePicker
        type={"time"}
        placeholder={placeholder}
        value={value}
        handleChange={(newVal) => setValue(newVal)}
      />
    );
  }

  // if(isPinPoint) {
  //   return (
  //     <div className={Styles.container}>

  //     </div>
  //   )
  // }

  const handleChangePhoneNumber = (e) => {
    const re = /^(8[0-9]{0,11})$/;
    // const re = /^(0|08|08[0-9]{1,9})$/
    if (e.target.value === "" || re.test(e.target.value)) {
      setValue(e.target.value);
    }
  };

  return (
    <div
      ref={boxRef}
      className={`${Styles.container} ${className} ${
        isPinPoint ? Styles.pinpoint : ""
      }`}
      onClick={() => isDropdown && setExpandDropdown(true)}
    >
      {(isPhoneNumber || isCurrency) && (
        <div className={Styles.phoneCurrencyAdornment}>
          <span>{isPhoneNumber ? "+62" : "Rp"}</span>
        </div>
      )}
      {!!startIcon && (
        <Icon
          icon={startIcon}
          size={iconSize}
          className={Styles.startIcon}
          color={iconColor}
        />
      )}
      {!!isLink && (
        <Icon
          icon={"link-2"}
          size={iconSize}
          className={Styles.startIcon}
          color={"#9360A8"}
        />
      )}
      {!isTextArea ? (
        <input
          name={name}
          id={makeRandomString(5)}
          onInput={forceUpperCase ? toInputUppercase : () => {}}
          type={
            isPassword
              ? showPassword
                ? "text"
                : "password"
              : isEmail
              ? "email"
              : "text"
          }
          value={isCurrency ? priceFormat(+value) : value}
          placeholder={placeholder}
          onChange={(e) =>
            isCurrency
              ? handleChangeCurrency(e)
              : isDropdown
              ? handleChangeDropdown(e)
              : isPhoneNumber
              ? handleChangePhoneNumber(e)
              : setValue(e.target.value)
          }
          onKeyDown={(e) => {
            // if (isPhoneNumber) {
            //   phoneNumberValidation(e)
            // }
            if (isNumber) {
              onlyNumberInput(e);
            }
          }}
          onFocus={() => {
            isDropdown && setExpandDropdown(true);
          }}
          style={{
            padding,
            borderRadius,
            paddingLeft:
              isPhoneNumber || isCurrency
                ? "60px"
                : !!startIcon || !!isLink
                ? "44px"
                : "auto",
          }}
          autoComplete={isPassword ? "on" : "off"}
          disabled={disabled}
          readOnly={readOnly}
          onClick={() => {
            isPinPoint && setShowPinpointModal(true);
          }}
          {...props}
        />
      ) : (
        <textarea
          id={makeRandomString(5)}
          onInput={forceUpperCase ? toInputUppercase : () => {}}
          type={
            isPassword
              ? showPassword
                ? "text"
                : "password"
              : isEmail
              ? "email"
              : "text"
          }
          value={isCurrency ? priceFormat(+value) : value}
          placeholder={placeholder}
          onChange={(e) =>
            isCurrency
              ? handleChangeCurrency(e)
              : isDropdown
              ? handleChangeDropdown(e)
              : setValue(e.target.value)
          }
          onKeyDown={(e) => {
            if (isPhoneNumber) {
              phoneNumberValidation(e);
            }
            if (isNumber) {
              onlyNumberInput(e);
            }
          }}
          onFocus={() => {
            isDropdown && setExpandDropdown(true);
          }}
          style={{
            padding,
            borderRadius,
            paddingLeft:
              isPhoneNumber || isCurrency || !!startIcon ? "60px" : "auto",
          }}
          autoComplete={isPassword ? "on" : "off"}
          disabled={disabled}
        />
      )}

      {isPinPoint && (
        <button
          className={Styles.pinpointButton}
          onClick={() => setShowPinpointModal(true)}
        >
          <span>Set Pinpoint</span>
          <Icon icon={"location-bold"} size={24} />
        </button>
      )}

      {isPercentage && (
        <div className={Styles.percentageAdornment}>
          <span>%</span>
        </div>
      )}

      {isPassword && (
        <button
          type="button"
          className={Styles.spButton}
          onClick={() => setShowPassword(!showPassword)}
        >
          <Icon
            icon={showPassword ? "eye-visible" : "eye-invisible"}
            size={20}
          />
        </button>
      )}
      {isDropdown && isLoadingDropdown && (
        <Spinner size="27px" className={Styles.spinner} />
      )}
      {isDropdown && (
        <button
          className={`${Styles.dropdownAdornment}`}
          onClick={(e) => {
            e.stopPropagation();
            setExpandDropdown(!expandDropdown);
          }}
        >
          <Icon icon={expandDropdown ? "arrow-up" : "arrow-down"} size={24} />
        </button>
      )}
      {expandDropdown && (
        <div
          className={Styles.optionsWrapper}
          style={customDropdownPositioning}
        >
          {!isLoadingDropdown ? (
            options?.length ? (
              options?.map((option, i) => (
                <div
                  key={i}
                  className={option === value ? Styles.active : ""}
                  onClick={(e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    setExpandDropdown(false);
                    setValue(option);
                  }}
                >
                  <span>{option}</span>
                </div>
              ))
            ) : (
              <div>no option</div>
            )
          ) : (
            <div className={Styles.loadingText}>
              <span>loading options...</span>
            </div>
          )}
        </div>
      )}
      {withDeleteOption && (
        <button className={Styles.deleteButton} onClick={onDelete}>
          <Icon icon={"indeterminate-circle"} size={20} color={"#D1421A"} />
        </button>
      )}
      {showPinpointModal && (
        <PinMaps
          isOpen={showPinpointModal}
          setOpen={setShowPinpointModal}
          setCoords={setValue}
        />
      )}
    </div>
  );
}

let interval = undefined;

const FileInput = ({
  file,
  setFile,
  placeholder,
  speed = 50,
  readOnly,
  disabled,
  isImageOnly = false,
  isPDFOnly = false,
  isVideoOnly = false,
  forceConvertFile,
}) => {
  const ref = useRef();
  const [completed, setCompleted] = useState(100);
  const [running, setRunning] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (running && file instanceof File) {
      interval = setInterval(() => {
        setCompleted((prev) => prev + 1);
      }, speed);
    } else {
      clearInterval(interval);
    }
  }, [running, file, speed]);

  useEffect(() => {
    if (completed < 100 && file && file instanceof File) {
      setRunning(true);
    } else {
      clearInterval(interval);
      setRunning(false);
    }
  }, [completed, file]);

  const rerun = useCallback(() => {
    setCompleted(0);
    setRunning(file ? true : false);
    setDuration("");
  }, [file]);

  const icon = useMemo(() => {
    if (file?.type) {
      switch (file?.type?.split("/")[0]) {
        case "image":
          return "image";
        case "video":
          return "slow-motion-video";
        case "application":
          switch (file?.type?.split("/")[1]) {
            case "pdf":
              return "file-pdf-box";
            default:
              return "cloud-upload";
          }
        default:
          return "cloud-upload";
      }
    }
    return "cloud-upload";
  }, [file?.type]);

  const [duration, setDuration] = useState("");

  const setFileInfo = useCallback(() => {
    var video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = function () {
      console.log("onloadedmetadata");
      window.URL.revokeObjectURL(video.src);
      setDuration((i) => fancyTimeFormat(video?.duration));
    };
    video.src = URL.createObjectURL(file);
  }, [file]);

  useEffect(() => {
    if (file && file?.type?.split("/")[0] === "video") {
      setFileInfo();
    }
  }, [file, setFileInfo]);

  const accept = useMemo(() => {
    if (isImageOnly) {
      return "image/*";
    }
    if (isPDFOnly) {
      return "application/pdf";
    }
    if (isVideoOnly) {
      return "video/mp4,video/x-m4v,video/*";
    }
    return "*";
  }, [isImageOnly, isPDFOnly, isVideoOnly]);

  useEffect(() => {
    if (file && typeof file === "string" && forceConvertFile) {
      interval = undefined;
      setFile(dataURLtoFile(file, "file"), "file");
    }
  }, [completed, file, forceConvertFile, rerun, running, setFile, speed]);

  const [showFile, setShowFile] = useState(false);

  const handleChange = (newVal) => {
    if (isImageOnly) {
      if (newVal.type.includes("image")) {
        setFile(newVal);
        setError(false);
      }
    } else if (newVal.type === accept) {
      setFile(newVal);
      setError(false);
    } else {
      setError(true);
    }
    // if (newVal.type === accept) {
    //   setFile(newVal);
    // }
  };

  return (
    <div className={Styles.wrapper}>
      <div className={`${Styles.fileInput} ${disabled ? Styles.disabled : ""} ${error ? Styles.errorInput : ""}`}>
        <Icon
          icon={isVisible && file ? "eye-visible" : icon}
          size={20}
          color={disabled ? "#E0E0E0" : "#9360A8"}
          onMouseOver={() => setIsVisible(true)}
          onMouseLeave={() => setIsVisible(false)}
          onClick={() => {
            if (isVisible && file) {
              setShowFile(true);
            }
          }}
          style={{
            cursor: "pointer",
          }}
        />
        <div className={Styles.fileContent}>
          {file ? (
            <div className={Styles.fileDesc}>
              <p>{file?.name}</p>
              {running && completed !== 100 ? (
                <ProgressBar completed={completed} />
              ) : (
                <span>
                  {duration
                    ? `${duration}`?.replace(".", ":")
                    : formatBytes(file?.size || 0)}
                </span>
              )}
            </div>
          ) : (
            <span>{placeholder}</span>
          )}
        </div>
        {!readOnly &&
          (!file ? (
            <Button
              disabled={disabled}
              className={Styles.chooseButton}
              onClick={() => ref?.current?.click()}
              variant="outlined"
              text={"Choose File"}
            />
          ) : (
            <div className={Styles.actions}>
              <button onClick={() => setFile(null)}>
                <Icon icon={"delete-outline"} size={22} color={"#D1421A"} />
              </button>
              <button onClick={() => ref?.current?.click()}>
                <Icon icon={"edit"} size={22} color={"#A87EB9"} />
              </button>
            </div>
          ))}
        <input
          ref={ref}
          type="file"
          accept={accept}
          hidden
          onChange={(e) => {
            if (e?.target?.files?.length) {
              handleChange(e?.target?.files[0]);
              rerun();
            }
          }}
        />
        <ViewFileModal
          show={showFile}
          file={file}
          onClose={() => setShowFile(false)}
        />
      </div>
      {error && (
        <div className={Styles.errorWrapper}>
          <p>
            Format file tidak sesuai, mohon pastikan file Anda memasukan file
            dengan format yang telah ditentukan{" "}
          </p>
        </div>
      )}
    </div>
  );
};
