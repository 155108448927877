import React from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import Lottie from "lottie-react";
import success from "@Assets/animation/success-send.json";

export default function SuccessNotifModal({
  to = "Seluruh Murid",
  handleClose,
}) {
  return (
    <div className={Styles.container}>
      <p>Reminder Berhasil Dikirim</p>
      <div className={Styles.modalBody}>
        <Lottie animationData={success} loop={false} />
        <p>
          <span>Reminder</span> telah berhasil dikirim kepada {to}
        </p>
      </div>
      <div className={Styles.modalAction}></div>
      <Button text={"Tutup"} onClick={handleClose} />
    </div>
  );
}
