import React, { useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import ClassesDesc from "@Molecule/ClassesDesc";
import Modal from "@Atom/Modal";
import CertificateValidateModal from "./CertificateValidate";
import { useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";
import NextPrevButton from "@Molecule/NextPrevButton";
import { decryptStorageData } from "@Helpers/encryptDecrypt";

export default function LearnCertificate({ data, userSheet }) {
  const [openModalValidate, setOpenModalValidate] = useState(false);
  const navigate = useNavigate();
  const query = useQuery();
  const title = query.get("t");
  const user = decryptStorageData("user", localStorage);
  const downloadCertificateOpen = useMemo(() => {
    const finishedAllSession = () => {
      const arr = [];
      if (user?.isAssesor) {
        return true;
      }
      for (let i = 0; i < userSheet.sessions.length; i++) {
        const session = userSheet.sessions[i].subjects;
        for (let j = 0; j < session.length; j++) {
          const subject = session[j];
          if (subject.type !== "TASK") {
            arr.push(subject);
          }
          if (subject.type === "TASK") {
            arr.push({
              ...subject,
              isComplete:
                user?.isAssesor || data.platform === "WORKSHOP"
                  ? true
                  : subject.score.overall > 0,
            });
          }
        }
      }
      return arr.every((el) => el.isComplete === true);
    };
    const withNoPostTest =
      data.configurations.find((el) => el.label === "postTest")?.value ===
      "false";
    const withNoSkillTest =
      data.configurations.find((el) => el.label === "skillTest")?.value ===
      "false";

    if (data.type === "GENERAL") {
      if (withNoPostTest && withNoSkillTest) {
        return finishedAllSession();
      } else if (withNoSkillTest) {
        return userSheet.score.posttest > 59;
      }
      return userSheet.score.skillTest > 0;
    } else {
      return userSheet.score.skillTest > 0;
    }
  }, [userSheet, data, user]);

  return (
    <div className={Styles.container}>
      <p className={Styles.title}>Sertifikat</p>
      <div className={Styles.contentHeader}>
        <p className={Styles.contentTitle}>Unduh Sertifikat</p>
        <p className={Styles.contentDesc}>
          {downloadCertificateOpen
            ? "Silahkan klik tombol download setifikat dibawah untuk mendapatkan sertifikat Anda. "
            : "Sertifikat belum tersedia, silahkan selesaikan seluruh rangkaian materi yang ada terlebih dahulu."}
        </p>
        <Button
          text={"Download Sertifikat"}
          onClick={() => setOpenModalValidate(true)}
          disabled={!downloadCertificateOpen}
        />
      </div>
      {/* <NextPrevButton
        prevText="Sebelumnya"
        // nextText="Lihat Sertifikat"
        // disabledNext={userSheet.score.preTest === -1}
        // handleNext={() =>
        //   navigate(`/classes/learn/${data._id}/certificate?t=${title}`)
        // }
        noNext
        handlePrev={() =>
          navigate(`/classes/learn/${data._id}/journal?t=${title}`)
        }
      /> */}
      <div>
        <Button
          variant="text"
          text={"Sebelumnya"}
          startIcon={"chevron-left"}
          onClick={() =>
            data.type === "GENERAL"
              ? navigate(-1)
              : navigate(`/classes/learn/${data._id}/journal?t=${title}`)
          }
        />
      </div>

      <ClassesDesc data={data} />
      <Modal isOpen={openModalValidate}>
        <CertificateValidateModal
          setIsOpen={setOpenModalValidate}
          data={userSheet}
        />
      </Modal>
    </div>
  );
}
