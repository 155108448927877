import React from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import Button from "@Atom/Button";
import { useNavigate } from "react-router-dom";

export default function CallbackPrakerja() {
  const callback = localStorage.getItem("path") || "/";
  const navigate = useNavigate();
  return (
    <div className={Styles.body}>
      <div className={Styles.container}>
        <div className={Styles.topSection}>
          <div className={Styles.logo}>
            <img src={Images.LOGO} alt="" />
            <span>Skills.id</span>
          </div>
          <img
            src={Images.LOGO_PRAKERJA_NEW}
            alt=""
            className={Styles.prakerjaLogo}
          />
        </div>
        <div className={Styles.mainSection}>
          <img src={Images.CALLBACK_VECTOR} alt="" />
          <p>Reedem Code Berhasil di Input</p>
        </div>
        <div className={Styles.buttonSection}>
          <Button
            text={"Kembali Ke Skills.id"}
            onClick={() => navigate(callback)}
          />
        </div>
      </div>
    </div>
  );
}
