import moment from "moment";
import React, { useRef } from "react";
import QRCode from "react-qr-code";
import Styles from "./style.module.scss";
import generatePDF from "react-to-pdf";
import { useMemo } from "react";
import Images from "@Theme/Images";
import certificateAbadi from "@Assets/certificate/kelulusan_abadi.jpeg";
import certificateAbadiPredicate from "@Assets/certificate/kelulusan_abadi_baik.jpeg";
import certificatePhri from "@Assets/certificate/certificate_phri.jpeg";
import certificatePhriPredicate from "@Assets/certificate/certificate_phri_baik.jpeg";
import certificatePivotPredicate from "@Assets/certificate/certificate_pivot2.JPG";
import certificatePivot from "@Assets/certificate/certificate_pivot1.JPG";
import certificateInfografikPredicate from "@Assets/certificate/certificate_infografik2.JPG";
import certificateInfografik from "@Assets/certificate/certificate_infografik1.JPG";
import { fileBaseUrl } from "@Config/index";
import Button from "@Atom/Button";

export default function CertificateLayout({ courseId, data }) {
  const ref1 = useRef();
  const ref2 = useRef();

  //   TOKOPEDIA = 'TOKOPEDIA',
  //   BUKALAPAK = 'BUKALAPAK',
  //   PINTAR = 'PINTAR',
  //   TEST = 'TEST',

  const templateCertificate = useMemo(() => {
    if (
      data?.certificate?.certificateTemplates
        ?.map((template) => template.dataCertificatePartner)
        .includes("ALL")
    ) {
      return (
        fileBaseUrl +
        data.certificate.certificateTemplates.find(
          (template) => template.dataCertificatePartner === "ALL"
        ).dataCertificateFileURL
      );
    } else if (
      data?.certificate?.certificateTemplates
        ?.map((template) => template.dataCertificatePartner)
        .includes(data.certificate.partner)
    ) {
      return (
        fileBaseUrl +
        data.certificate.certificateTemplates.find(
          (template) =>
            template.dataCertificatePartner === data.certificate.partner
        ).dataCertificateFileURL
      );
    }

    if (!!data.certificate.predicate) {
      if (
        data.certificate.courseID === "63c8f181de772832733326e5" ||
        data.certificate.courseID === "63eaf7a246271d34db90e6ed" ||
        data.certificate.courseID === "63e07e462c155465f01d3ed4" ||
        data.certificate.courseID === "63e358c86aec666276aedf85"
      ) {
        return certificateAbadiPredicate;
      }
      if (data.certificate.courseID === "63c76c5033292a13b9b077b2") {
        return certificatePivotPredicate;
      }
      if (data.certificate.courseID === "63c7ac2fdaad538457a01be3") {
        return certificateInfografikPredicate;
      }
      if (data.certificate.courseID === "63d79318395c91b22e58fd5e") {
        return certificatePhriPredicate;
      }
      switch (data.certificate.partner) {
        case "TEST":
          return Images.KELULUSAN_TOKOPEDIA_BAIK;
        case "TOKOPEDIA":
          return Images.KELULUSAN_TOKOPEDIA_BAIK;
        case "PINTAR":
          return Images.KELULUSAN_PINTAR_BAIK;
        case "BUKALAPAK":
          return Images.KELULUSAN_BUKALAPAK_BAIK;
        default:
          return Images.KELULUSAN_TOKOPEDIA_BAIK;
      }
    } else {
      if (
        data.certificate.courseID === "63c8f181de772832733326e5" ||
        data.certificate.courseID === "63eaf7a246271d34db90e6ed" ||
        data.certificate.courseID === "63e07e462c155465f01d3ed4" ||
        data.certificate.courseID === "63e358c86aec666276aedf85"
      ) {
        return certificateAbadi;
      }
      if (data.certificate.courseID === "63c76c5033292a13b9b077b2") {
        return certificatePivot;
      }
      if (data.certificate.courseID === "63c7ac2fdaad538457a01be3") {
        return certificateInfografik;
      }
      if (data.certificate.courseID === "63d79318395c91b22e58fd5e") {
        return certificatePhri;
      }
      switch (data.certificate.partner) {
        case "TEST":
          return Images.KELULUSAN_TOKOPEDIA;
        case "TOKOPEDIA":
          return Images.KELULUSAN_TOKOPEDIA;
        case "PINTAR":
          return Images.KELULUSAN_PINTAR;
        case "BUKALAPAK":
          return Images.KELULUSAN_BUKALAPAK;
        default:
          return Images.KELULUSAN_TOKOPEDIA;
      }
    }
  }, [data]);

  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <div className={Styles.buttonContainer}>
          <div className={Styles.Header}>
            <div>
              <p className={Styles.titleHead}>Unduh Sertifikat</p>
              <p className={Styles.desc}>
                Silahkan download sertifikat yang telah tersedia dibawah ini.{" "}
              </p>
              <div>
                <Button
                  text={"Download Sertifikat"}
                  onClick={() =>
                    generatePDF(ref1, {
                      filename: "page.pdf",
                      page: { orientation: "landscape" },
                    })
                  }
                />
              </div>
            </div>
            <div>
              <img alt="" src={Images.CERTIFICATE_VECTOR} />
            </div>
          </div>
          <div ref={ref1} className={Styles.certificateWrapper}>
            <img
              src={templateCertificate}
              alt="cert"
              className={Styles.imageContainer}
            />
            <h4
              className={`${Styles.certificateText} ${Styles.courseName}`}
              predicate={!!data.certificate.predicate ? "true" : "false"}
            >
              {data.certificate.courseName}
            </h4>
            <h1
              className={`${Styles.certificateText} ${Styles.certificateName}`}
              predicate={!!data.certificate.predicate ? "true" : "false"}
            >
              {data.certificate.name}
            </h1>
            <h6
              className={`${Styles.certificateText} ${Styles.certificateDate}`}
            >
              {moment(data.certificate.createdAt).format("dddd, Do MMMM YYYY")}
            </h6>
            <div className={Styles.qrContainer}>
              <QRCode
                style={{
                  height: "150px",
                  width: "150px",
                  maxWidth: "100%",
                }}
                value={window.location.href}
                viewBox={`0 0 256 256`}
              />
              <p className={`${Styles.certificateText}`}>
                No.{data.certificate.certificateGenerateDate}
              </p>
            </div>
          </div>
        </div>
        {data.competency.length > 0 && (
          <div style={{ marginTop: "4rem" }}>
            <button
              onClick={() =>
                generatePDF(ref2, {
                  filename: "page.pdf",
                  page: { orientation: "landscape" },
                })
              }
              className={Styles.customButton}
            >
              Download
            </button>
            <div ref={ref2} className={Styles.certificateWrapper}>
              <img
                src={Images.DAFTAR_PENILAIAN_UMUM}
                alt="cert"
                className={Styles.imageContainer}
              />
              <div className={Styles.scoreTextContainer}>
                <p className={`${Styles.competenceTitle}`}>
                  {data?.competency[0]?.unitCategory}
                </p>
              </div>
              <div className={Styles.competenceUnitContainer}>
                {data?.competency?.map((el, idx) => (
                  <div key={idx} className={`${Styles.competence}`}>
                    <p>{idx + 1}.</p>
                    <p>
                      <span className={Styles.fontBold}>{el.unitCode}</span> -{" "}
                      {el.unitValue}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
