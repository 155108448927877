import React, { useRef } from "react";
import style from "./index.module.scss";
import RadioButton from "@Atom/RadioButton";
import Icon from "@Atom/Icon";

export default function RadioTextArea({
  onClick,
  isSelected,
  value,
  onChange,
  placeholder = "Placeholder",
  name,
  readOnly = false,
}) {
  const textAreaRef = useRef(null);

  const textAreaAdjust = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "1px";
      textAreaRef.current.style.height = `${0 + textAreaRef.current.scrollHeight
        }px`;
    }
  };

  return (
    <div>
      <div className={style.container}>
        <RadioButton onClick={!readOnly ? onClick : ()=>{}} isSelected={!readOnly ? isSelected : false} />
        {
          readOnly
            ?
            <div className={style.option}>
              {value || '-'}
            </div>
            :
            <textarea
              ref={textAreaRef}
              onChange={onChange}
              value={value}
              name={name}
              cols="auto"
              rows="1"
              onFocus={() => textAreaAdjust()}
              onInput={() => textAreaAdjust()}
              onBlur={() => textAreaAdjust()}
              className={style.autoHeightTextarea}
              placeholder={placeholder}
              readOnly={readOnly}
            />
        }

        {
          readOnly && isSelected
          &&
          <Icon icon={'checked'} size={20} color={'#42CAB4'} />
        }
      </div>
    </div>
  );
}
