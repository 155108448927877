import DoodleGradientCard from "@Atom/DoodleGradientCard";
import React, { useCallback, useEffect } from "react";
import Styles from "./style.module.scss";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Button from "@Atom/Button";
import { useState } from "react";
import RegisterNotif from "./RegisterNotif";
import ValidationNotif from "./ValidationNotif";
import PromoNotif from "./PromoNotif";
import LearnNotif from "./LearnNotif";
import RedeemNotif from "./RedeemNotif";
import useQuery from "@Hooks/useQuery";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "@Molecule/Footer";

const tabs = [
  "Reminder Registrasi",
  "Reminder Redeem",
  "Reminder Pelatihan",
  "Reminder Validasi Nama",
  "Promo",
];

export default function AdminNotificationLayout() {
  const user = decryptStorageData("user", localStorage);
  const [tab, setTab] = useState(0);
  const query = useQuery();
  const title = query.get("c");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const renderComponent = useCallback(() => {
    switch (tab) {
      case 0:
        return <RegisterNotif />;
      case 1:
        return <RedeemNotif />;
      case 2:
        return <LearnNotif />;
      case 3:
        return <ValidationNotif />;
      case 4:
        return <PromoNotif />;
      default:
        break;
    }
  }, [tab]);

  useEffect(() => {
    navigate(`${pathname}?c=`, {
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        {!title && (
          <DoodleGradientCard className={Styles.header}>
            <div className={Styles.title}>
              <h3>Selamat Datang {<span>{user?.name}</span>}</h3>
              <span>
                Berikut adalah daftar seluruh kursus/kelas yang kamu punya.
              </span>
            </div>
          </DoodleGradientCard>
        )}
        {!title && (
          <div className={Styles.tabWrapper}>
            {tabs.map((el, idx) => (
              <Button
                variant="tab"
                text={el}
                isActive={tab === idx}
                onClick={() => setTab(idx)}
                key={idx}
              />
            ))}
          </div>
        )}
        {renderComponent()}
      </div>
      <Footer />
    </div>
  );
}
