import React, { useState, useEffect, useMemo } from "react";
import Styles from "./style.module.scss";
import Image from "@Theme/Images";
import Icon from "@Atom/Icon";

export default function WheelLandingPage() {
  const [rotation, setRotation] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [isTextVisible, setIsTextVisible] = useState(true);

  useEffect(() => {
    setIsTextVisible(false);
    setTimeout(() => {
      setIsTextVisible(true);
    }, 200);
  }, [textIndex]);

  const handleLeftArrowClick = () => {
    setRotation(rotation - 90);
    setTextIndex((textIndex - 1 + textImage.length) % textImage.length);
  };

  const handleRightArrowClick = () => {
    setRotation(rotation + 90);
    setTextIndex((textIndex + 1) % textImage.length);
  };

  const textImage = useMemo(() => {
    return [
      "Skills.id bekerja sama dengan pengajar profesional yang berpengalaman dan kompeten di bidangnya masing-masing",
      "Skills.id menyediakan program pelatihan di berbagai bidang untuk mendukung perkembangan karier kamu",
      "Berkesempatan mendapatkan sertifikat, info loker dan program magang untuk memudahkan kamu mendapatkan pekerjaan",
      "Tersedia konsultasi bersama pengajar untuk memperoleh pemahaman mendalam pada proses pembelajaran",
    ];
  }, []);

  return (
    <div className={Styles.container} id="benefit">
      <div className={Styles.wrapperWheel}>
        <h1>
          Kenapa Memilih <span className={Styles.span}>{" Skills.id "}</span>
        </h1>
        <section className={Styles.carouselWheel}>
          <div className={Styles.leftIcon} onClick={handleRightArrowClick}>
            <Icon icon={"arrow-left"} className={Styles.iconL} />
          </div>
          <p
            className={Styles.textImage}
            style={{
              opacity: isTextVisible ? 1 : 0.8,
            }}
          >
            {textImage[textIndex]}
          </p>
          <div className={Styles.rightIcon} onClick={handleLeftArrowClick}>
            <Icon icon={"arrow-left"} className={Styles.iconR} />
          </div>
        </section>
        <div className={Styles.wrapperImage}>
          <img
            src={Image.WHEEL_LANDING_PAGE}
            alt="wheel-category-class"
            style={{ transform: `rotate(${rotation}deg)` }}
          />
        </div>
      </div>
    </div>
  );
}
